<script>
  import {useScannerStoreWithOut, ScannerCommands} from "@/store/modules/scanner";
  import { ElMessage, ElMessageBox, ElDivider } from 'element-plus'
  import { useAppStore } from "@/store/modules/app"
  import StatusDot from "../../components/utils/StatusDot.vue";
  import {useUserStoreWithOut} from "@/store/modules/user";

  export default {
    name: "ScannerView",
    components: {
      StatusDot
    },
    data(){
      return {


        exam_unit_id: null, // 学校单位id

        subjectProp: {}, // 试卷配置信息

        configVisible: false, // 配置对话框显示状态
        globalConfig: {
          // 文件保存
          file_save_path: "", // 默认是文档目录
          file_name_prefix: "", // 文件名前缀
          file_name_mode: "date_time", // 命名方式
          // 图像保存
          image_format: "jpg", // 图片格式
          image_jpeg_quality: 80, // jpg质量
          image_tiff_compression: "none", // tiff压缩方式
          image_tiff_jpeg_quality: 80, // tiff-jpeg质量
          is_cover: true, // 添加覆盖选项，默认为true
        },
       
        deviceParam: [], // 设备参数
        // 选项配置
        options: {
          nameMode: [
            { label: '时间命名', value: 'date_time' },
            { label: '随机命名', value: 'random' },
            { label: '自定义规则', value: 'sn_date_time' },
            { label: '文件夹时间序号', value: 'folder_time_img_order' }
          ],
          imageFormat: [
            { label: 'JPG', value: 'jpg' },
            { label: 'BMP', value: 'bmp' },
            { label: 'PNG', value: 'png' },
            { label: 'TIF', value: 'tif' },
            { label: 'PDF', value: 'pdf' },
            { label: 'OFD', value: 'ofd' },
            { label: 'OCR-PDF', value: 'ocr-pdf' },
            { label: 'OCR-OFD', value: 'ocr-ofd' }
          ],
          tiffCompression: [
            { label: '无压缩', value: 'none' },
            { label: 'LZW压缩', value: 'lzw' },
            { label: 'JPEG压缩', value: 'jpeg' }
          ]
        },
        activeGroup: 'global', // 当前展开的分组
        paramSearch: '', // 字
        selectedTemplate: 'exam', // 默认选择考场扫描
        scanning: false, // 是否   在扫描
        scannedImages: [], // 扫描的图片列表
        selectedImageIndex: -1, // 当前选中的图片索引
        currentImageBase64: null, // 新增：当前显示的图片base64
        uploadData: {}, // 上传参数
        uploadVisible: false, // 上传对话框显示状态
        uploadLoading: false, // 上传加载状态
        selectedUploadImages: [], // 选中要上传的图片
        scanType: "3", // 默认为全局扫描
        scanProgress: {
          scanned: 0,    // 本次扫描已扫描数量
          uploaded: 0,    // 本次扫描已上传数量
          errors: 0,      // 本次扫描错误数量


          unit_progressScan: 0, // 学校扫描当前学校已扫描上传的数量
          unit_total: 0,      // 总数

          progressScan: 0, // 已扫描上传的数量
          total: 0      // 总数
        },
        isFrontBack: false, // 是否是双面扫描
        uploadQueue: [], // 存储待上传的图片
        unrecognizedImages: [], // 存储未识别考号的图片
        scanError: false,  // 添加扫描错误状态
        unrecognizedDialogVisible: false, // 未识别考号对话框显示状态
        currentUnrecognizedIndex: 0, // 当前正在输入的未识别试卷索引
        imageRotation: 0,
        imageScale: 1,
        uploadPromises: [], // 存储所有上传请求的 Promise


        errorDetail: '', // 错误详情
        errorDetailDialogVisible: false, // 错误详情对话框显示状态

        allUploaded: false, // 是否所有图片都上传成功
        getRoomPromise: null, // 获取考场信息的Promise
        getRoomInfoError: false, // 获取考场信息错误状态

        realTimeAlign: false, // 是否实时对齐,实时对齐后可以看到是否缺考、客观题的识别答案

        autoSave: false, // 扫描是否自动保存 默认不自动保存 只有保存的才能进入阅卷流程
        roomErrInfos: [], // 根据考号获取考场的错误信息
        roomInfo: {}, // 考场信息
        roomTableData: [], // 考场扫描的：某个考场的考号以及座位号
        roomErrorDialogVisible: false, // 考场错误信息对话框显示状态
        isProgressLoading: false, // 添加进度条加载状态

      }
    },
    computed: {

      // 考场扫描当前考场的总数
      roomScanTotal() {
        return this.roomTableData.length
      },
      // 考场扫描当前考场的已扫描数量
      roomScanCount() {
        return this.roomTableData.filter(item => item.scannedImages).length
      },

      // 计算扫描进度百分比
      percentage() {
        // 确保分母不为0，并且结果是有效数字
        if (!this.scanProgress.total) return 0
        const result = (this.scanProgress.progressScan / this.scanProgress.total) * 100
        // 确保返回一个有效的数字，并且限制小数位数
        return parseFloat(result.toFixed(2))
      },
      // 单位扫描进度百分比
      unitPercentage() {
        if (!this.scanProgress.unit_total) return 0
        const result = (this.scanProgress.unit_progressScan / this.scanProgress.unit_total) * 100
        return parseFloat(result.toFixed(2))
      },
      // 过滤全局配置
      filteredGlobalConfig() {
        if (!this.paramSearch) return this.globalConfig
        
        const result = {}
        Object.entries(this.globalConfig).forEach(([key, value]) => {
          if (this.getGlobalConfigLabel(key).toLowerCase().includes(this.paramSearch.toLowerCase())) {
            result[key] = value
          }
        })
        return result
      },
      
      // 过滤设备参数
      filteredDeviceParam() {
        if (!this.paramSearch) return this.deviceParam
        
        return this.deviceParam.map(group => {
          const filteredParams = group.group_param.filter(param => 
            param.name.toLowerCase().includes(this.paramSearch.toLowerCase())
          )
          return filteredParams.length ? {
            ...group,
            group_param: filteredParams
          } : null
        }).filter(Boolean)
      },
      
      // 当前选中的图片
      currentImage() {
        return this.selectedImageIndex >= 0 ? this.scannedImages[this.selectedImageIndex] : null
      },
      // 添加扫描类型显示标签的计算属性
      getScanTypeLabel() {
        const typeMap = {
          "1": "考场扫描",
          "2": "学校扫描",
          "3": "全局扫描"
        }
        return typeMap[this.scanType] || "考场扫描"
      },
      // 将 processedImages 从 methods 移到 computed 中
      processedImages() {
        if (!this.isFrontBack) return this.scannedImages
        
        const mergedImages = []
        for (let i = 0; i < this.scannedImages.length; i += 2) {
          if (this.scannedImages[i]) {
            mergedImages.push({
              ...this.scannedImages[i],
              backImage: this.scannedImages[i + 1] || null
            })
          }
        }
        return mergedImages
      }
    },
    async mounted() {
      await this.userStore.loadUserInfo()
      const userType = this.userStore.user.type
      const unitId = this.userStore.unit.id
      // 如果type不是1,2,6，那么单位就去取unitId
      // 核心管理员与考试管理员与经销商可以任选学校
      if (userType != 1 && userType != 2 && userType != 6) {
        this.exam_unit_id = unitId
      }else{
        this.exam_unit_id = this.appStore.exam_all_unit_ids[0]
      }

      // 初始化 WebSocket
      this.scannerStore.initWebSocket()
      
      // 添加扫描事件监
      this.scannerStore.addCallBack(ScannerCommands.EVENT_SCAN_BEGIN, this.handleScanBegin)
      this.scannerStore.addCallBack(ScannerCommands.EVENT_SCAN_END, this.handleScanEnd)
      this.scannerStore.addCallBack(ScannerCommands.EVENT_SCAN_INFO, this.handleScanInfo)
      this.scannerStore.addCallBack(ScannerCommands.EVENT_SCAN_IMAGE, this.handleScanImage)
      
      // 初始化上传参
      this.uploadData = {
        exam_id: this.appStore.exam_id,
        subject_id: this.appStore.subject_id
      }
      
      await this.getScanParams()
      this.fetchScanProgress()
      
      // 添加键盘事件监听
      document.addEventListener('keyup', this.handleKeyup)

      
    
    },
    setup(){
      const appStore = useAppStore()
      const scannerStore = useScannerStoreWithOut()
      
      // 全局扫描的行样式
      const tableRowClassNameForScanType3 = ({
        row,
        rowIndex,
      }) => {
        if (!row.studentId) {
          return 'info-row'
        }else if(row.error){
          return 'danger-row'
        }else if(row.isAbsent === '是'){
          return 'warning-row'
        }else {
          return 'success-row'
        }
      }

      // 考场扫描的行样式
      const tableRowClassNameForScanType1 = ({
        row,
        rowIndex,
      }) => {
        if (row.isAbsent === '是') {
          return 'warning-row'
        }else if(row.isAbsent){
          return 'success-row'
        }else if(row.error){
          return 'danger-row'
        }else {
          return 'info-row'
        }
      }

      const userStore = useUserStoreWithOut()
      

      return {appStore,scannerStore,tableRowClassNameForScanType1,tableRowClassNameForScanType3,userStore}
    },  
    beforeUnmount() {
      // 移除事件监听
      this.scannerStore.removeCallBack(ScannerCommands.EVENT_SCAN_BEGIN)
      this.scannerStore.removeCallBack(ScannerCommands.EVENT_SCAN_END)
      this.scannerStore.removeCallBack(ScannerCommands.EVENT_SCAN_INFO)
      this.scannerStore.removeCallBack(ScannerCommands.EVENT_SCAN_IMAGE)
      
      // 移除键盘事件监听
      document.removeEventListener('keyup', this.handleKeyup)
    },
    methods: {
      // 添加获取进度的方法
      async fetchScanProgress() {
        if (this.isProgressLoading) return; // 如果正在加载，直接返回
        
        this.isProgressLoading = true; // 设置加载状态{
        try {
          const params = { type: 'web',exam_id: this.appStore.exam_id,subject_id: this.appStore.subject_id }
          if (this.scanType == 2) {
            // 学校扫描
            params.exam_unit_id = this.exam_unit_id
          }

          const {data} = await this.axios.post('/api/scannerApi/getsubjectinfo', params,
          {showLoading: false}
        )
          if (data.success && data.result.scan_count) {

            if (this.scanType == 2) {
              // 学校扫描
              const scanCount = data.result.scan_count.find(item => item.school == this.exam_unit_id)
              this.scanProgress.unit_progressScan = scanCount?.unit_upload_count || 0
              this.scanProgress.unit_total = scanCount?.unit_total || 0

              // 计算总数
              this.scanProgress.total = data.result.scan_count.reduce((sum, item) => sum + item.unit_total, 0)
              // 计算已扫描上传的数量
              this.scanProgress.progressScan = data.result.scan_count.reduce((sum, item) => sum + item.unit_upload_count, 0)

            }else{
              this.scanProgress.progressScan = data.result.scan_count[0].upload_count || 0
              this.scanProgress.total = data.result.scan_count[0].total || 0
            }
        


            
          }
        } catch (error) {
          console.error('获取扫描进度失败:', error)
        } finally {
          this.isProgressLoading = false; // 无论成功失败都重置加载状态
        }
      },

      // 获取扫描参数
      async getScanParams() {
        try {
          const response = await this.axios.post("/api/exam/connectScanner", {exam_id: this.appStore.exam_id,subject_id: this.appStore.subject_id})
          if (response.data.success) {
            const exam_id = this.appStore.exam_id
            const subject_id = this.appStore.subject_id
            const filter = response.data.result.scanPerm.filter(item => 
              item.exam_id === exam_id && item.subject_id === subject_id
            )


            this.subjectProp = response.data.result.subjectProp
            
            if (filter.length === 0) {
              this.$message({message: '暂无权限，请您刷新页面！', type: "warning"})
              return
            }
            const defaultParam = [ 
              {
                name: "文稿方向",
                value: "-90°"
              }
            ]
            // 根据扫描模式设置不同的默认参数
            if (this.selectedTemplate === 'exam') {
              defaultParam.push(
                {
                  name: "灰度或黑白图像 - 除色与增强",
                  value: "除红色"
                },
                {
                  name: "24位彩色图像 - 多流输出除红",
                  value: true
                },
                {
                  name: "24位彩色图像 - 答题卡除红",
                  value: true
                }
              )
            }
            // 创建参数数组
            const params = [
              ...defaultParam
            ]
            const scanProp = filter[0].scan_prop
            if (scanProp) {
              
              
              // 设置分辨率
              params.push({
                name: "分辨率",
                value: scanProp.scan_dpi ? parseInt(scanProp.scan_dpi) : 150
              })
              
              // 设置扫描页面（单面/双面）
              params.push({
                name: "扫描页面",
                value: scanProp.scan_way === "1" ? "单面" : "双面"
              })
              
              // 设置颜色模式
              let colorMode
              switch(scanProp.scan_color) {
                case "1":
                  colorMode = "黑白"
                  break
                case "2":
                  colorMode = "256级灰度"
                  break
                case "3":
                  colorMode = "24位彩色"
                  break
                default:
                  colorMode = "黑白"
              }
              params.push({
                name: "颜色模式",
                value: colorMode
              })
              
              // 设置纸张尺寸
              let paperSize
              switch(scanProp.scan_paper_size) {
                case "1":
                  paperSize = "A4"
                  break
                case "2":
                  paperSize = "A3"
                  break
                case "3":
                  paperSize = "匹配原始尺寸"
                  break
                default:
                  paperSize = "A4"
              }
              params.push({
                name: "纸张尺寸",
                value: paperSize
              })

              // 设置扫描类型
              this.scanType = scanProp.scan_type || "3" // 默认全局扫描
              
              // 设置是否双面扫描
              this.isFrontBack = scanProp.scan_way === "2"
              
            }else{
              ElMessage.warning('暂无预设扫描仪参数,将使用默认扫描参数！')
            }
            // 调用扫描仪设置参数的方法
            try {
                await this.scannerStore.command_setDeviceParam(params)
                ElMessage.success('预设扫描仪参数设置成功')
              } catch (error) {
                console.error('预设扫描仪参数设置失败:', error)
                console.log(filter[0])
                this.$message.error('预设扫描仪参数设置失败')
              }
          }
        } catch (error) {
          console.error('获取扫描参数失败:', error)
          this.$message.error('获取扫描数失败')
        }
      },

      async toConfig(){
        // 获取全局配置
        const globalRes = await this.scannerStore.command_getGlobalConfig()
        const preIsCover = this.globalConfig.is_cover
        this.globalConfig = globalRes
        this.globalConfig.is_cover = (preIsCover === undefined || preIsCover === null) ? true : preIsCover
        
        // 获取设备参数
        const deviceRes = await this.scannerStore.command_getDeviceParam()
        console.log('设备参数响应:', deviceRes)
        this.deviceParam = deviceRes
        
        // 默认展开全局配置
        this.activeGroup = 'global'
        
        this.configVisible = true
      },
      async saveConfig() {
        // 保存全局配置
        await this.scannerStore.command_setGlobalConfig(this.globalConfig)
        
        // 保存设备参数前格式化数值
        const params = []
        this.deviceParam.forEach(group => {
          group.group_param.forEach(param => {
            let value = param.value
            if (param.value_type === 'double') {
              value = Number(parseFloat(value).toFixed(2))
            }
            params.push({
              name: param.name,
              value
            })
          })
        })
        await this.scannerStore.command_setDeviceParam(params)
        
        this.configVisible = false
        ElMessage.success('配置保存成功')
      },
      // 根据参数类型获取步长
      getStepByType(param) {
        if (param.value_type === 'double') {
          return 0.01
        }
        return 1
      },
      
      // 格式化数字显示
      formatNumber(value, type) {
        if (type === 'double') {
          return Number(parseFloat(value).toFixed(2))
        }
        return value
      },
      // 获取全局配置的显示标签
      getGlobalConfigLabel(key) {
        const labels = {
          file_save_path: '保存路径',
          file_name_prefix: '文件名前缀',
          file_name_mode: '命名方式',
          image_format: '图片格式',
          image_jpeg_quality: 'JPG质量',
          image_tiff_compression: 'TIFF压缩方式',
          image_tiff_jpeg_quality: 'TIFF-JPEG质量'
        }
        return labels[key] || key
      },
      // 处理重置设备参数
      async handleResetParams() {
        try {
          await ElMessageBox.confirm(
            '重置设备参数将恢复所有参数到默认值，是否继续？',
            '警告',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
          
          await this.scannerStore.command_resetDeviceParam()
          // 重新获取设备参数
          const deviceRes = await this.scannerStore.command_getDeviceParam()
          this.deviceParam = deviceRes
          
          ElMessage.success('设备参数已重置')
        } catch (error) {
          if (error !== 'cancel') {
            ElMessage.error('重置设备参数失败')
          }
        }
      },
      // 处理模板切换
      async handleTemplateChange(template) {
        try {
          // 如果有未识别考号的对话框打开，先关闭它
          this.unrecognizedDialogVisible = false;
          // 清空相关数据
          this.unrecognizedImages = [];
          this.currentUnrecognizedIndex = 0;

          // 根据模板类型设置不同的参数
          const params = [
            {
              name: "文稿方向",
              value: "-90°"
            }
          ];
          
          if (template === 'exam') {
            // 试卷扫描模式的参数
            params.push(
              {
                name: "灰度或黑白图像 - 除色与增强",
                value: "除红色"
              },
              {
                name: "24位彩色图像 - 多流输出除红",
                value: true
              },
              {
                name: "24位彩色图像 - 答题卡除红",
                value: true
              }
            );
          } else {
            // 模板扫描模式的参数
            params.push(
              {
                name: "灰度或黑白图像 - 除色与增强",
                value: "不除色"
              },
              {
                name: "24位彩色图像 - 多流输出除红",
                value: false
              },
              {
                name: "24位彩色图像 - 答题卡除红",
                value: false
              }
            );
          }
          
          // 设置扫描仪参数
          await this.scannerStore.command_setDeviceParam(params);
          
          // 清空所有相关数据
          this.scannedImages = [];
          this.uploadQueue = [];
          this.selectedImageIndex = -1;
          this.scanProgress.scanned = 0
        this.scanProgress.uploaded = 0
        this.scanProgress.errors = 0
          
          ElMessage.success(`已切换到${template === 'normal' ? '模板扫描' : '试卷扫描'}模式`)
          if(template === 'exam'){
            await this.fetchScanProgress()
          }
        } catch (error) {
          // 如果用户取消，恢复之前的选择
          this.selectedTemplate = template === 'normal' ? 'exam' : 'normal'
        }
      },
      // 处理扫描
      async handleScan() {
        if (!this.scannerStore.getIsDeviceOpen) {
          ElMessage.warning('请等待扫描仪连接完成')
          return
        }
        
        if (this.scanning) {
          await this.handleStopScan()
          return
        }

         // 获取全局配置
        const globalRes = await this.scannerStore.command_getGlobalConfig()
        const preIsCover = this.globalConfig.is_cover
        this.globalConfig = globalRes
        this.globalConfig.is_cover = (preIsCover === undefined || preIsCover === null) ? true : preIsCover
        


        this.getRoomPromise = null
        this.getRoomInfoError = false


        // 清空图片列表
        this.scannedImages = [];
        this.uploadQueue = [];
        this.selectedImageIndex = -1;
        this.scanProgress.scanned = 0
        this.scanProgress.uploaded = 0
        this.scanProgress.errors = 0

        this.roomTableData = []
        this.roomInfo = {}
        this.roomErrInfos = []
        
        this.scanning = true
        try {
          await this.scannerStore.command_startScan()
        } catch (error) {
          console.error('开始扫描失败', error)
          ElMessage.error('开始扫描失败')
          this.scanning = false
        }
      },
      
      // 处理扫描开始事件
      handleScanBegin() {
        ElMessage.success('开始扫描')
        this.allUploaded = false
      },
      
      // 处理扫描结束事件
      async handleScanEnd() {
        this.scanning = false;
        
        if (this.scanError) {
          ElMessage.error('扫描异常结束');
          this.scanError = false;
          this.allUploaded = false
          return;
        }


        try {
            // 试卷扫描模式下的错误处理
            if (this.selectedTemplate === 'exam') {
              try {
                // 检查时间戳顺序
                const isOrdered = this.scannedImages.every((img, index) => {
                    if (index === 0) return true;
                    return img.timeStamp >= this.scannedImages[index - 1].timeStamp;
                  });

                  if (!isOrdered) {
                    await ElMessageBox.alert(
                      '扫描的图片顺序异常，请重新扫描',
                      '警告',
                      { type: 'warning' }
                    );
                    // 清空图片列表
                    this.scannedImages = [];
                    this.scanProgress.scanned = 0;
                    this.scanProgress.uploaded = 0;
                    this.scanProgress.errors = 0;
                    return;
                  }
              } catch (error) {
                console.error('检查图片顺序失败:', error);
                ElMessage.error('检查图片顺序失败');
              }

              // 等待所有上传请求完成
              await Promise.all(this.uploadPromises);
              this.allUploaded = true

              if (this.unrecognizedImages.length > 0) {
                // 有未识别的考号，显示未识别对话框
                this.unrecognizedDialogVisible = true;
                this.currentUnrecognizedIndex = 0;
              }else{
                // 没有未识别的考号
                if (this.roomErrInfos.length > 0) {
                // 显示错误信息对话框
                  this.roomErrorDialogVisible = true;
                }else{
                  if(this.autoSave){
                    // 自动保存
                    await this.save()
                  }
                }
              }
            }

          } catch (error) {
            console.error('部分上传请求失败:', error);
          }
          // 清空上传请求数组
          this.uploadPromises = [];
        
      },
      
      // 处理扫描信息事件
      handleScanInfo(info) {
        if (info.is_error) {
          ElMessage.error(info.info);
          this.scanError = true;  // 添加错误状态标记
        } else {
          console.log(info.info);
        }
      },
      
      // 处理扫描图像事件
      async handleScanImage(info) {
        if (info.image_path && info.image_base64) {
          // 从文件路径中提取文件名
          const fileName = info.image_path.split('\\').pop();
          
          // 检查文件名是否符合格式：前缀-时间戳.格式
          const pattern = new RegExp(
            `^${this.globalConfig.file_name_prefix || 'Huago'}-?(\\d{17})\\.${this.globalConfig.image_format || 'jpg'}$`
          );
          const match = fileName.match(pattern);
          
          if (!match) {
            console.error('无法从文件名中提取时间戳:', fileName);
            ElMessageBox.alert('无法从文件名中提取时间戳,请检查文件名是否符合格式：前缀-时间戳.格式')
            // 停止扫描 
            await this.handleStopScan()
            return;
          }
          
          const timeStamp = match[1];

          // 创建新图片对象
          const newImage = {
            path: info.image_path,
            thumbnail: info.image_base64,
            base64: info.image_base64,
            timeStamp: timeStamp,
            studentId: '',
            uploaded: false,
            loading: false  // 初始化 loading 属性
          };

          // 直接添加到列表末尾
          this.scannedImages.push(newImage);
          this.selectedImageIndex = this.scannedImages.length - 1;
          
          // 已扫描数量（双面模式下每两张算一份）
          if (!this.isFrontBack || this.scannedImages.length % 2 === 1) {
            this.scanProgress.scanned++;
          }

          // 滚动到底部
          this.scrollTableToBottom();

          // 根据扫描模式决定是否上传
          if (this.selectedTemplate === 'exam') {
            const shouldUpload = this.isFrontBack ? 
              this.scannedImages.length % 2 === 0 : 
              true;
            
            if (shouldUpload) {
              const uploadImages = this.isFrontBack ? 
                this.scannedImages.slice(-2) : 
                [this.scannedImages[this.scannedImages.length - 1]];
              // 将上传请求添加到 Promise 数组中
              const uploadPromise = this.uploadImages(uploadImages);
              this.uploadPromises.push(uploadPromise);
            }
          }
        }
      },

      // 处理清空列表
      async handleClearImages() {
        try {
          await ElMessageBox.confirm(
            '确定要清空所有扫描图片吗？此操作不可恢复。',
            '警告',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
          
          await this.scannerStore.command_clearImageList()
          
          // 清空本地图片列表
          this.scannedImages = []
          this.selectedImageIndex = -1
          
          ElMessage.success('图片列表已清空')
        } catch (error) {
          if (error !== 'cancel') {
            console.error('清空列表失败:', error)
            ElMessage.error('清空列表失败')
          }
        }
      },
      // 处理图片选择
      handleImageSelect(index) {
        const idx = this.selectedUploadImages.indexOf(index)
        if (idx > -1) {
          this.selectedUploadImages.splice(idx, 1)
        } else {
          if (this.selectedUploadImages.length >= 2) {
            ElMessage.warning('最多只能选择两张图片')
            return
          }
          this.selectedUploadImages.push(index)
        }
      },
      
      // 打开上传对话框
      openUploadDialog() {
        if (this.scannedImages.length === 0) {
          ElMessage.warning('请先扫描图片')
          return
        }
        this.selectedUploadImages = []
        this.uploadVisible = true
      },
      
      // 处理图片上传
      async handleUpload() {
        if (this.selectedUploadImages.length === 0) {
          ElMessage.warning('请选择要上传的图片')
          return
        }
        
        this.uploadLoading = true
        try {
          let count = 1
          for (const index of this.selectedUploadImages) {
            const image = this.scannedImages[index]
            
            // 创建文件对象
            const base64Data = image.base64.split(',')[1]
            const blob = this.base64ToBlob(base64Data, 'image/jpeg')
            const file = new File([blob], `scan_${index + 1}.jpg`, { type: 'image/jpeg' })
            
            // 创建 FormData
            const formData = new FormData()
            formData.append('imgfile', file)
            
            // 添加考试和科目ID
            formData.append('examid', this.uploadData.exam_id)
            formData.append('subjectid', this.uploadData.subject_id)
            
            // 发送上传请求
            const {data} =await this.axios.post('/api/exam/mgeexamsubjectpapers/uploadexamsubjectpaper', formData)
            if(data.success){ 
              ElMessage.success(`传第 ${count} 张图片成功`)
            }
            count++
          }
          
          this.uploadVisible = false
        } catch (error) {
          console.error('上传失败:', error)
          ElMessage.error('上传失败')
        } finally {
          this.uploadLoading = false
        }
      },
      
      // base64 转 Blob
      base64ToBlob(base64, type) {
        const binaryString = window.atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }
        return new Blob([bytes], { type })
      },
      
      // 添加停止描方法
      async handleStopScan() {
        try {
            await this.scannerStore.command_stopScan()
            this.fetchScanProgress()
            this.scanning = false
            ElMessage.success('已停止扫描')
        } catch (error) {
            console.error('停止扫描失败', error)
            ElMessage.error('停止扫描失败')
        }
      },
      // 格式化时间戳
      formatTimeStamp(timeStamp) {
        if(!timeStamp) return ''
        const year = timeStamp.substring(0, 4)
        const month = timeStamp.substring(4, 6)
        const day = timeStamp.substring(6, 8)
        const hour = timeStamp.substring(8, 10)
        const minute = timeStamp.substring(10, 12)
        const second = timeStamp.substring(12, 14)
        const ms = timeStamp.substring(14)
        return `${year}-${month}-${day} ${hour}:${minute}:${second}.${ms}`
      },
      
      // 根据考号获取到考场信息
      async getRoomInfo(studentId){
        if(this.getRoomPromise) await Promise.all([this.getRoomPromise])
        if(this.getRoomInfoError) return
        // 如果考场信息已存在，则不重复获取
        if(this.roomTableData.length !== 0) return 
        try{
          this.getRoomPromise = this.axios.post('/api/scannerApi/getroominfo', {
            exam_id: this.appStore.exam_id,
            subject_id: this.appStore.subject_id,
            student_id: studentId
            })
            const {data} = await this.getRoomPromise
            if(data.success){
              this.roomTableData = data.result.roomStudent
              this.roomInfo = data.result.room
              this.getRoomInfoError = false
            }else{
              this.getRoomInfoError = true
              this.roomTableData = []
              this.roomInfo = {}
              // this.roomErrInfos.push((data.result || '获取考场信息失败') + '，考号：' + studentId)
              // 停止扫描
              this.handleStopScan()
            }
            this.getRoomPromise = null
          
        }catch(error){
          this.getRoomInfoError = true
          console.error('获取考场信息失败:', error)
          ElMessage.error('获取考场信息失败')
        }finally{
          this.getRoomInfoLoading = false
        }
      },

      // 上传图片并识别考号
      async uploadImages(images) {
        try {
          if (!images || images.length === 0) {
            console.warn('上传图片列为空');
            return;
          }

          this.allUploaded = false
          // 设置对应图片的加载状态
          images.forEach(image => {
            const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);
            if (index !== -1) {
              this.scannedImages[index].loading = true;
            }
          });

          const formData = new FormData();
          formData.append('type', 'web');
          formData.append('exam_id', this.appStore.exam_id);
          formData.append('subject_id', this.appStore.subject_id);
          formData.append('is_cover', this.globalConfig.is_cover ? '1' : '0');
          // 如果有手动输入的考号，添加到请求中
          if (images[0].manualStudentId) {
            formData.append('student_id', images[0].manualStudentId);
          }
          
          // 添加图片
          if (this.isFrontBack) {
            if (images.length !== 2) {
              console.warn('双面模式下上传队列长度异常');
              return;
            }
            formData.append('page1', this.base64ToFile(images[0].base64, 'page1.jpg'));
            formData.append('page2', this.base64ToFile(images[1].base64, 'page2.jpg'));
          } else {
            formData.append('page1', this.base64ToFile(images[0].base64, 'page1.jpg'));
          }

          if(this.realTimeAlign){
            formData.append('realTimeAlign', '1')
          }

          if(this.scanType == 2){
            // 学校扫描
            formData.append('exam_unit_id', this.exam_unit_id)
          }

          
          const {data} = await this.axios.post('/api/scannerApi/putpic', formData, {showLoading: false});
          if (data.success) {
            const result = data.result;
            const studentId = result.student_id;
            const preStatus = result.preStatus;
            const roomInfo = result.roomInfo;
            const dealInfo = result.dealInfo;
            const error = result.error;
            const page1Res = result.page1Res;
            const page2Res = result.page2Res;
            let isAbsent = '--' // 是否缺考
            let page1Answer = '--' // 第一页答案
            let page2Answer = '--' // 第二页答案

            if(page1Res && page1Res.success === true){
              const questionList = page1Res.result
              if(questionList && questionList.length > 0){
                // 先根据subnum从小到大排序
                questionList.sort((a, b) => a.subnum - b.subnum)

                // 如果第一条数据subnum=-1，则认为缺考
                if(questionList[0].subnum === -1){
                  isAbsent = '是'
                  // 去除第一条数据
                  questionList.shift()
                }else{
                  isAbsent = '否'
                }
                // 将答案拼接成字符串
                if(questionList && questionList.length > 0){
                  page1Answer = questionList.map(item => item.answers).join(',')
                }else{
                  page1Answer = '选项为空'
                }
              }else{
                page1Answer = '选项为空'
                isAbsent = '否'
              } 
            }
            if(page2Res && page2Res.success === true){
              const questionList = page2Res.result
              // 将答案拼接成字符串
              if(questionList && questionList.length > 0){
                page2Answer = questionList.map(item => item.answers).join(',')
              }else{
                page2Answer = '选项为空'
              }
            }


            // 是否手动输入考号
            const isManualInput = images[0].manualStudentId ? true : false

            if(this.scanType == 1){ 
              // 考场扫描
              if(this.roomTableData.length === 0 && studentId){ 
                // 如果考场信息为空，则根据考号获取考场信息
                await this.getRoomInfo(studentId);
              }

              // 如果获取考场信息的Promise存在，则等待其完成
              if(this.getRoomPromise) await Promise.all([this.getRoomPromise])

              if(this.roomTableData.length !== 0 && studentId && roomInfo && this.roomInfo.room_id !== roomInfo.room_id){
                const arr = []
               // 有错误信息，归为错误数
               images.forEach(image => {
                  const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);
                  if (index !== -1) {
                    this.scannedImages[index].error = "考号:" + studentId + "所属的考场为" + roomInfo.room_name + "，而当前考场为" + this.roomInfo.name;
                    this.scannedImages[index].preStatus = preStatus;
                    this.scannedImages[index].roomInfo = roomInfo;
                    this.scannedImages[index].dealInfo = dealInfo;
                    this.scannedImages[index].studentId = studentId; // 有时候虽然有错误，但是考号还是识别出来了
                    arr.push(this.scannedImages[index])
                  }
                });
                // 合并错误信息
                const img1 = arr[0]
                const img2 = arr[1] ? arr[1] : {}
                const combine = {
                  frontImage: img1,
                  backImage: img2,
                  studentId: img1.studentId,
                  timeStamp: img1.timeStamp,
                  error: img1.error
                }
                this.roomErrInfos = [...this.roomErrInfos, combine]

                this.scanProgress.errors++;
              }else{
                if (error || 
                  (page1Res && page1Res.success === false) ||
                  (page2Res && page2Res.success === false)
                ) {
                  const arr = []
                  // 有错误信息，归为错误数
                  images.forEach(image => {
                    const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);

                    if (index !== -1) {
                      this.scannedImages[index].error = error;
                      this.scannedImages[index].preStatus = preStatus;
                      this.scannedImages[index].roomInfo = roomInfo;
                      this.scannedImages[index].dealInfo = dealInfo;
                      this.scannedImages[index].studentId = studentId; // 有时候虽然有错误，但是考号还是识别出来了
                      this.scannedImages[index].page1Res = page1Res;
                      this.scannedImages[index].page2Res = page2Res;
                      arr.push(this.scannedImages[index])
                    }
                  });
                  const img1 = arr[0]
                  const img2 = arr[1] ? arr[1] : {}
                  const combine = {
                    frontImage: img1,
                    backImage: img2,
                    studentId: img1.studentId,
                    timeStamp: img1.timeStamp,
                    error: img1.error
                  }
                  this.roomErrInfos = [...this.roomErrInfos, combine]

                  this.scanProgress.errors++;
                } else {
                  if (studentId) {
                    // 成功识别考号

                    images.forEach(image => {
                      const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);
                      if (index !== -1) {
                        this.scannedImages[index].studentId = studentId;
                        this.scannedImages[index].uploaded = true;
                        this.scannedImages[index].preStatus = preStatus;
                        this.scannedImages[index].roomInfo = roomInfo;
                        this.scannedImages[index].dealInfo = dealInfo;
                        this.scannedImages[index].page1Res = page1Res;
                        this.scannedImages[index].page2Res = page2Res;
                        this.scannedImages[index].isAbsent = isAbsent;
                        this.scannedImages[index].page1Answer = page1Answer;
                        this.scannedImages[index].page2Answer = page2Answer;
                        if (image.manualStudentId) {
                          this.scannedImages[index].isManualInput = true;
                        }
                        // 在roomTableData找到对应studentId的行，并绑定图片信息
                        const roomRow = this.roomTableData.find(row => row.student_id === studentId);
                        if(roomRow){
                          roomRow.scannedImages =  this.scannedImages[index]
                          roomRow.scannedImages.frontImage = images[0]
                          roomRow.page1Answer = page1Answer
                          roomRow.page2Answer = page2Answer
                          roomRow.isAbsent = isAbsent
                          if(this.isFrontBack && images[1]){
                            roomRow.scannedImages.backImage = images[1]
                          }
                        }
                      }
                    });
                    this.scanProgress.uploaded++;
                  } else {
                    // 未识别考号，将正反面作为一组添加到未识别列表
                    const unrecognizedItem = {
                      frontImage: images[0],
                      timeStamp: images[0].timeStamp,
                      manualStudentId: '',
                      preStatus,
                      roomInfo,
                      dealInfo
                    };
                    if (this.isFrontBack && images[1]) {
                      unrecognizedItem.backImage = images[1];
                    }
                    this.unrecognizedImages.push(unrecognizedItem);
                    console.log(this.unrecognizedImages)
                    this.scanProgress.errors++;
                  }
              }

              }

              
            }


          
            if(this.scanType == 3 || this.scanType == 2){
                // 全局扫描 或 学校扫描
                // 更新图片信息
              if (error
                || (page1Res && page1Res.success === false) ||
                (page2Res && page2Res.success === false)
              ) {
                // 有错误信息，归为错误数
                images.forEach(image => {
                  const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);
                  if (index !== -1) {
                    this.scannedImages[index].error = error;
                    this.scannedImages[index].preStatus = preStatus;
                    this.scannedImages[index].roomInfo = roomInfo;
                    this.scannedImages[index].dealInfo = dealInfo;
                    this.scannedImages[index].studentId = studentId; // 有时候虽然有错误，但是考号还是识别出来了
                    this.scannedImages[index].page1Res = page1Res;
                    this.scannedImages[index].page2Res = page2Res;
                  }
                });
                this.scanProgress.errors++;
              } else {
                if (studentId) {
                  // 成功识别考号
                  images.forEach(image => {
                    const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);
                    if (index !== -1) {
                      this.scannedImages[index].studentId = studentId;
                      this.scannedImages[index].uploaded = true;
                      this.scannedImages[index].preStatus = preStatus;
                      this.scannedImages[index].roomInfo = roomInfo;
                      this.scannedImages[index].dealInfo = dealInfo;
                      this.scannedImages[index].page1Res = page1Res;
                      this.scannedImages[index].page2Res = page2Res;
                      this.scannedImages[index].isAbsent = isAbsent;
                      this.scannedImages[index].page1Answer = page1Answer;
                      this.scannedImages[index].page2Answer = page2Answer;
                      if (image.manualStudentId) {
                        this.scannedImages[index].isManualInput = true;
                      }
                    }
                  });
                  this.scanProgress.uploaded++;

                  // 如果不是实时对齐且手动输入的考号且上传成功，调用 fixAndDealPaper 接口
                  if (!this.realTimeAlign && images[0].manualStudentId) {
                    try {
                      await this.axios.post('/api/scannerApi/fixAndDealPaper', {
                        exam_id: this.appStore.exam_id,
                        subject_id: this.appStore.subject_id,
                        studentIds: [studentId],
                        exam_unit_id: this.scanType == 2 ? this.exam_unit_id : null
                      });
                    } catch (error) {
                      console.error('调用 fixAndDealPaper 接口失败:', error);
                    }
                  }
                } else {
                  // 未识别考号，将正反面作为一组添加到未识别列表
                  const unrecognizedItem = {
                    frontImage: images[0],
                    timeStamp: images[0].timeStamp,
                    manualStudentId: '',
                    preStatus,
                    roomInfo,
                    dealInfo
                  };
                  if (this.isFrontBack && images[1]) {
                    unrecognizedItem.backImage = images[1];
                  }
                  this.unrecognizedImages.push(unrecognizedItem);
                  console.log(this.unrecognizedImages)
                  this.scanProgress.errors++;
                }
              }
            }


            // 如果手动输入的考号问题未解决，则错误数减一
            // 因为说明出现了其他错误，在上面的逻辑中又给加了一次
            if(isManualInput){
              this.scanProgress.errors--; 
            }
            
          } else {
            this.scanProgress.errors++;
            ElMessage.error(data.msg || '上传失败');
          }
        } catch (error) {
          console.error('上传失败:', error);
          this.scanProgress.errors++;
          ElMessage.error('上传失败');
        } finally {
          // 清除加载状态
          images.forEach(image => {
            const index = this.scannedImages.findIndex(img => img.timeStamp === image.timeStamp);
            if (index !== -1) {
              this.scannedImages[index].loading = false;
            }
          });
        }

        // 在所有图片信息更新后滚动到底部
        this.scrollTableToBottom();
      },

      // base64 转 File
      base64ToFile(base64, filename) {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      },

      // 显示未识别考号对话框
      showUnrecognizedDialog() {
        console.log('显示未识别考号对话框，数量:', this.unrecognizedImages.length);
        if (!this.unrecognizedImages.length) return;
        this.unrecognizedDialogVisible = true;
        this.currentUnrecognizedIndex = 0;
      },
      async handleUnrecognizedConfirm() {
        // 如果当前索引超出范围，重置为0
        if (this.currentUnrecognizedIndex >= this.unrecognizedImages.length) {
          this.currentUnrecognizedIndex = 0;
        }

        const currentImage = this.unrecognizedImages[this.currentUnrecognizedIndex];
        if (!currentImage.manualStudentId) {
          ElMessage.warning('请输入考号');
          return;
        }

        try {
          // 准备上传的图片
          const uploadImages = [
            { ...currentImage.frontImage, manualStudentId: currentImage.manualStudentId }
          ];
          if (currentImage.backImage) {
            uploadImages.push({ ...currentImage.backImage, manualStudentId: currentImage.manualStudentId });
          }
          
          // 判断输入的考号是否在刚才成功putpic的列表中
          const judgeStudentIdExistInScannedImages = this.scannedImages.find(img => img.studentId === currentImage.manualStudentId)
          

          if(this.scanType == 1){
            // 考场扫描
            if(judgeStudentIdExistInScannedImages){
              ElMessage.warning('考号已存在此次扫描中，请重新输入');
              return
            }
            // 考场扫描还要判断是否在roomTableData中
            const judgeStudentIdExistInRoomTableData = this.roomTableData.find(row => row.student_id === currentImage.manualStudentId)
            if(!judgeStudentIdExistInRoomTableData){
              ElMessage.warning('考号不存在此考场，请重新输入');
              return
            }

            
           
          }else if(this.scanType == 3 || this.scanType == 2){
            // 全局扫描
            if(judgeStudentIdExistInScannedImages){
              ElMessage.warning('考号已存在，请重新输入');
              return
            }
          }
          // await this.uploadImages(uploadImages);
          //this.fetchScanProgress()
          this.uploadImages(uploadImages);
          
          // 从未识别列表中移除
          this.unrecognizedImages.splice(this.currentUnrecognizedIndex, 1);
          
          
          // 如果已经处理完所有图片，显示成功消息
          if (this.unrecognizedImages.length === 0) {
            ElMessage.success('所有考号处理完成');
            // 关闭对话框
            this.unrecognizedDialogVisible = false
            
            // 如果是考场扫描，并且有错误信息，则显示考场错误对话框
            if(this.selectedTemplate == 'exam' && this.scanType == 1 && this.roomErrInfos.length > 0){
              this.roomErrorDialogVisible = true
            }

            await Promise.all(this.uploadPromises);
            this.allUploaded = true
            this.fetchScanProgress();
          }
        } catch (error) {
          console.error('重新上传失败:', error);
          ElMessage.error('重新上传失败，请重试');
        }
      },
      // 旋转图片
      rotateImage(angle) {
        this.imageRotation = (this.imageRotation + angle) % 360;
        const img = document.querySelector('.el-image-viewer__img');
        if (img) {
          img.style.transform = `rotate(${this.imageRotation}deg) scale(${this.imageScale})`;
        }
      },
      // 缩放图片
      zoomImage(factor) {
        this.imageScale *= factor;
        const img = document.querySelector('.el-image-viewer__img');
        if (img) {
          img.style.transform = `rotate(${this.imageRotation}deg) scale(${this.imageScale})`;
        }
      },
      getStatusLabel(status) {
        const statusMap = {
          '0': '未上传',
          '1': '已上传但未处理',
          '2': '已处理',
          '3': '处理异常'
        };
        return statusMap[status] || '未知状态';
      },
      getUploadResultLabel(result,preStatus) {
        if(preStatus == 0){
          // 之前是未上传
          if(result == 1){
            return {type:'success',label:'上传成功'}
          }else{
            return {type:'danger',label:'未上传'}
          }
        }else if(preStatus == 1 || preStatus == 2){
          // 之前是已上传 / 已处理
          if(result == 1){
            return {type:'success',label:'已覆盖'}
          }else{
            return {type:'warning',label:'未覆盖'}
          }
        }else if(preStatus == 3){
          // 之前是处理异常
          if(result == 1){
            return {type:'success',label:'重传成功'}
          }else{
            return {type:'danger',label:'重传失败'}
          }
        }
      },
      // 添加显示完整错误信息的方法
      showErrorDetail(error) {
        this.errorDetail = error
        this.errorDetailDialogVisible = true
      },

      // 滚动表格到底部
      scrollTableToBottom() {
        this.$nextTick(() => {
          // 获取表格实例
          const tableRef = this.$refs.scanTable;
          if (tableRef) {
            // 使用 Element Plus 提供的 setScrollTop 方法
            const scrollHeight = tableRef.scrollHeight;
            console.log("scrollHeight",scrollHeight)
            tableRef.setScrollTop(9999999999999999);
          }
        });
      },

      // 处理未识别考号对话框的取消
      handleUnrecognizedCancel() {
        // 如果当前有未处理的图片,从未识别列表中移除
        if (this.currentUnrecognizedIndex < this.unrecognizedImages.length) {
          this.unrecognizedImages.splice(this.currentUnrecognizedIndex, 1);
        }
        
        // 如果还有其他未识别的图片
        if (this.unrecognizedImages.length > 0) {
          // 如果当前索引超出范围,重置为0
          if (this.currentUnrecognizedIndex >= this.unrecognizedImages.length) {
            this.currentUnrecognizedIndex = 0;
          }
        } else {
          // 没有未识别的图片了,关闭对话框
          this.unrecognizedDialogVisible = false;
          if (this.roomErrInfos.length > 0) {
            this.roomErrorDialogVisible = true;
          } else {
            ElMessage.success('所有图片处理完成');
          }
        }
      },

      // 扫描保存
      async save(){
        const validStudentIds = this.scannedImages
              .filter(img => img.studentId && !img.error)
              .map(img => img.studentId);
            // 去重
        const uniqueStudentIds = [...new Set(validStudentIds)]

        if(this.scanType == 1){
          // 考场扫描
          if(this.subjectProp.strict_mode == 1){
            // 严格模式，座位号必须严格递增
            console.log("严格模式",this.scannedImages)
            if(this.isFrontBack){
              // 双面
              for(let i = 0; i < this.scannedImages.length; i++){
                const seat_num = this.scannedImages[i].roomInfo.seat_num
                if(seat_num != (i/2 + 1)){
                  ElMessage.warning('无法保存，严格模式下，座位号必须严格递增！出错座位号：' + seat_num)
                  return
                }
                i+=1
              }
            }else{
              // 单面
              for(let i = 0; i < this.scannedImages.length; i++){
                const seat_num = this.scannedImages[i].roomInfo.seat_num
                if(seat_num != i + 1){
                  ElMessage.warning('无法保存，严格模式下，座位号必须严格递增！出错座位号：' + seat_num)
                  return
                }
              }
            }
          }
          if(uniqueStudentIds.length == this.roomTableData.length){
          }else{
            ElMessage.warning('无法保存，存在未扫描的试卷！')
            return
          }
          
        }

        const saveReq = async () => {
            let success = false
            if(!this.realTimeAlign){
              // 这种情况下目前调用fixAndDealPaper去处理，然后会将submit置为1,但是这里有个问题，就是当前无法知道是否处理成功

              // 如果去重后的考号数量等于考场人数，则保存调用fixAndDealPaper接口
              const {data} = await this.axios.post('/api/scannerApi/fixAndDealPaper', {
                exam_id: this.appStore.exam_id,
                subject_id: this.appStore.subject_id,
                studentIds: uniqueStudentIds,
                exam_unit_id: this.scanType == 2 ? this.exam_unit_id : null
              })
              success = data.success
            }else{
              // 已经实时对齐了
              // 那么调用submitPaper接口
              const {data} = await this.axios.post('/api/scannerApi/submitPaper', {
                exam_id: this.appStore.exam_id,
                subject_id: this.appStore.subject_id,
                studentIds: uniqueStudentIds,
                exam_unit_id: this.scanType == 2 ? this.exam_unit_id : null
              })
              success = data.success
            }


            if(success){
                this.scannedImages = []
                if(this.scanType == 1){
                  // 考场扫描
                  this.roomTableData = []
                  this.roomErrInfos = []
                }else if(this.scanType == 3 || this.scanType == 2){
                  // 全局扫描
                  this.allUploaded = false
                }
              }
              


            if(success){
              ElMessage.success('保存成功')
              this.allUploaded = false
            }
            this.fetchScanProgress()

          }

        // 如果当前有错误，则提示用户是否确认保存
        if(this.scanProgress.errors > 0){
          this.$confirm('当前有错误，是否确认保存？').then(saveReq).catch(() => {
            return
          })
        }else{
          saveReq()
        }
        this.fetchScanProgress();

      },

      // 添加键盘事件处理方法
      handleKeyup(event) {
        // 判断是否按下S键，并且当前是考场扫描模式
        if (event.key.toLowerCase() === 's' && this.scanType === '1' && !this.roomTableData.length == 0) {
          this.save()
        }
        // 判断是否按下O键，开始扫描
        else if (event.key.toLowerCase() === 'o' && !this.scanning && this.scannerStore.getIsDeviceOpen) {
          this.handleScan()
        }
        // 判断是否按下P键，停止扫描
        else if (event.key.toLowerCase() === 'p' && this.scanning) {
          this.handleStopScan()
        }
      },
    }
  }
</script>

<template>
  <div class="scanner-view">
    <div class="scanner-header">
      <div class="scanner-container">
        <el-space class="scanner-content content-text">
          <!-- 模板选择 -->
          <el-radio-group v-model="selectedTemplate" @change="handleTemplateChange" size="small">
            <el-radio-button :value="'normal'">
              <template #default>
                <i-page-template theme="outline" size="16"/>
                <span class="button-text">模板扫描</span>
              </template>
            </el-radio-button>
            <el-radio-button :value="'exam'">
              <template #default>
                <i-scan-code theme="outline" size="16"/>
                <span class="button-text">试卷扫描</span>
              </template>
            </el-radio-button>
          </el-radio-group>
          
        
          
          <!-- 扫描按钮 -->
          <el-button 
            type="success" 
            :loading="scanning"
            size="small"
            @click="handleScan"
            :disabled="!scannerStore.getIsDeviceOpen"
          >
            <template #default>
              <i-play-one theme="outline" size="16" fill="#fff" v-if="!scanning"/>
              <span class="button-text">{{ scanning ? '扫描中...' : '开始扫描[O键]' }}</span>
            </template>
          </el-button>
          
          <!-- 新增停止扫描按钮 -->
          <el-button 
            type="danger" 
            size="small"
            :disabled="!scanning"
            @click="handleStopScan"
          >
            <template #default>
              <i-pause-one theme="outline" size="16" fill="#fff"/>
              <span class="button-text">停止扫描[P键]</span>
            </template>
          </el-button>
          
          <!-- 添加清空按钮 -->
          <el-button 
            type="danger" 
            plain
            size="small"
            v-if="selectedTemplate === 'normal'"
            :disabled="scannedImages.length === 0"
            @click="handleClearImages"
          >
            <template #default>
              <i-delete theme="outline" size="16"/>
              <span class="button-text">清空列表</span>
            </template>
          </el-button>
          
          <!-- 添加上传按钮 -->
          <el-button 
            type="primary" 
            plain
            v-if="selectedTemplate === 'normal'"
            size="small"
            :disabled="scannedImages.length === 0"
            @click="openUploadDialog"
          >
            <template #default>
              <i-upload theme="outline" size="16"/>
              <span class="button-text">上传图片</span>
            </template>
          </el-button>

           <!-- 扫描仪参数调整 -->
           <el-button type="info" plain size="small" @click="toConfig">
            <template #default>
              <i-config theme="outline" size="16"/>
              <span class="button-text">参数配置</span>
            </template>
          </el-button>
        </el-space>
        <el-space class="scanner-status-wrapper" style="margin-right: 10px;">
          <div :class="{'active':scannerStore.getScannerName !== '','inactive':scannerStore.getScannerName === ''}" class="scanner-status">
            <div class="status-dot"></div>
            <div class="status-text">
              <span>{{ scannerStore.getScannerName }}</span>
              <span v-if="scannerStore.getScannerName !== ''" class="status-label success">[已连接]</span>
              <span v-else class="status-label danger">[未连接]</span>
            </div>
          </div>
        </el-space>
      </div>
      <!-- 扫描类型行 -->
      <div class="scan-type-container" v-if="selectedTemplate === 'exam'">
        <div class="scan-type-wrapper">
         <el-space>
           <el-text type="primary">{{ getScanTypeLabel }}</el-text>
           <!-- 全局扫描和考场扫描的进度条 -->
           <div class="progress-wrapper"
            v-if="(scanType == 1 || scanType == 3) && selectedTemplate == 'exam'"
           >
             <el-progress 
              @click="fetchScanProgress"
              style="cursor: pointer;"
              :text-inside="true"
              :percentage="percentage"
              v-loading="isProgressLoading"
              :stroke-width="32"
                :format="() => `${scanProgress.progressScan}/${scanProgress.total}`"
              />
            </div>

            <!-- 学校扫描的进度条1 -->
            <div class="progress-wrapper-width-150"
              v-if="scanType == 2 && selectedTemplate == 'exam'"
            >
              <el-progress 
                @click="fetchScanProgress"
                style="cursor: pointer;"
                :text-inside="true"
                :percentage="percentage"
                v-loading="isProgressLoading"
                :stroke-width="32"
                  :format="() => `${scanProgress.progressScan}/${scanProgress.total}(全局)`"
                />
            </div>
            <!-- 学校扫描的进度条2 -->
            <div class="progress-wrapper-width-150"
              v-if="scanType == 2 && selectedTemplate == 'exam'"
            >
              <el-progress 
                @click="fetchScanProgress"
                style="cursor: pointer;"
                :text-inside="true"
                :percentage="unitPercentage"
                v-loading="isProgressLoading"
                :stroke-width="32"
                  :format="() => `${scanProgress.unit_progressScan}/${scanProgress.unit_total}(学校)`"
                />
            </div>


            <div v-if="scanType == 1 && selectedTemplate == 'exam'">
              <el-text :type="roomInfo.name ? 'primary' : 'info'" size="large">
                {{'< 当前考场:'}} {{ roomInfo.name ? roomInfo.name : '请开始扫描' }} {{'>'}} [{{roomScanCount}} / {{roomScanTotal}}]
              </el-text>
            </div>
            <!-- 学校扫描的学校选择框 -->
            <div v-if="scanType == 2 && selectedTemplate == 'exam'">
              <el-select v-model="exam_unit_id" 
                placeholder="请选择学校" 
                style="width: 200px;" 
                size="small" 
                @change="fetchScanProgress"
                :disabled="userStore.user.type != 1 && userStore.user.type != 2 && userStore.user.type != 6"
              >
                <el-option v-for="(item,index) in appStore.exam_all_unit_names" :key="index" :label="item" :value="appStore.exam_all_unit_ids[index]"/>
              </el-select>
            </div>
         </el-space> 
        

        </div>
        <div class="scan-type-extra">
          <el-space class="progress-container">
            <el-button 
              type="success" 
              size="small"
              :disabled="(scanType == 1 && roomTableData.length == 0) || ((scanType == 3 || scanType == 2) && !allUploaded)"
              @click="save"
              >
              保存[S键]
            </el-button>
            <el-switch  
              v-model="autoSave"
              active-text="自动保存"
              inactive-text="手动保存"
              inline-prompt
            />
            <el-switch
              v-model="globalConfig.is_cover"
              active-text="覆盖上传"
              inactive-text="不覆盖"
              inline-prompt
            />
            <el-switch
              v-model="realTimeAlign"
              active-text="实时处理"
              inactive-text="保存处理"
              inline-prompt
            />
            
            <div class="scan-stats">
              <el-text class="stat-item">
                已扫描：<el-text type="primary">{{ scanProgress.scanned }}</el-text>
              </el-text>
              <!-- 这个暂时先不显示，因为上传后需要提交，提交成功了才算真的上传才能进入阅卷流程。这个上传是只上传到服务器了，但是没有保存提交 -->
             <!--  <el-text class="stat-item">
                已上传：<el-text type="success">{{ scanProgress.uploaded }}</el-text>
              </el-text> -->
              <el-text class="stat-item">
                错误数：<el-text type="danger">{{ scanProgress.errors }}</el-text>
              </el-text>
            </div>
          </el-space>
        </div>
      </div>
    </div>

    <!-- 扫描预览区域 -->
    <div v-if="selectedTemplate === 'normal'" class="scanner-preview">
      <div class="preview-sidebar">
        <!-- 缩略图列表 -->
        <div class="thumbnail-list">
          <div 
            v-for="(image, index) in scannedImages" 
            :key="index"
            :class="['thumbnail-item', { active: selectedImageIndex === index }]"
            @click="selectedImageIndex = index"
          >
            <div class="thumbnail-index">{{ index + 1 }}</div>
            <el-image 
              :src="image.thumbnail"
              fit="contain"
              :preview-src-list="[]"
            >
              <template #error>
                <div class="image-slot">加载失败</div>
              </template>
            </el-image>
          </div>
        </div>
      </div>
      
      <div class="preview-main">
        <!-- 大图预览 -->
        <div class="preview-image">
          <el-image
            v-if="currentImage"
            :src="currentImage.base64"
            fit="contain"
            :preview-src-list="[]"
          >
            <template #error>
              <div class="image-slot">
                <el-icon><picture-filled /></el-icon>
                <span>图片载失败</span>
              </div>
            </template>
          </el-image>
        </div>
      </div>
    </div>

    <!-- 配置对话框 -->
    <el-dialog
        v-model="configVisible"
        title="扫描仪参数配置"
        width="1300px"
        class="scanner-config-dialog"
    >
      <div class="dialog-content">
        <div class="content-wrapper">
          <!-- 模板选择和搜索栏 -->
          <div class="toolbar">

            <div class="search-bar">
              <div class="search-container">
                <el-input
                  v-model="paramSearch"
                  placeholder="搜索参数..."
                  prefix-icon="Search"
                  clearable
                />
              </div>
              <el-button 
                type="warning" 
                @click="handleResetParams"
                :disabled="activeGroup === 'global'"
              >
                重置设备参数
              </el-button>
            </div>
          </div>
          
          <div class="tabs-container">
            <el-tabs type="border-card" tab-position="left" v-model="activeGroup">
              <el-tab-pane label="全局配置" name="global">
                <div class="tab-content">
                  <el-form :model="globalConfig" label-width="120px">
                    <template v-for="(value, key) in filteredGlobalConfig" :key="key">
                      <!-- 文件保存路径 -->
                      <el-form-item v-if="key === 'file_save_path'" label="保存路径">
                        <el-input v-model="globalConfig[key]" placeholder="请输入文件保存路径"/>
                      </el-form-item>
                      
                      <!-- 文件名前缀 -->
                      <el-form-item v-else-if="key === 'file_name_prefix'" label="文件名前缀">
                        <el-input v-model="globalConfig[key]" placeholder="请输入文件名前缀"/>
                      </el-form-item>
                      
                      <!-- 命名方式 -->
                      <el-form-item v-else-if="key === 'file_name_mode'" label="命名方式">
                        <!-- 不能修改，因为区分正反面需要根据时间戳 -->
                        <el-select v-model="globalConfig[key]" style="width: 100%" disabled>
                          <el-option
                              v-for="item in options.nameMode"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                      
                      <!-- 片格式 -->
                      <el-form-item v-else-if="key === 'image_format'" label="图片格式">
                        <el-select v-model="globalConfig[key]" style="width: 100%">
                          <el-option
                              v-for="item in options.imageFormat"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                      
                      <!-- JPG质量 -->
                      <el-form-item v-else-if="key === 'image_jpeg_quality'" label="JPG质量">
                        <el-slider
                            v-model="globalConfig[key]"
                            :min="0"
                            :max="100"
                            :step="1"
                            show-input
                        />
                      </el-form-item>
                      
                      <!-- TIFF压缩方式 -->
                      <el-form-item v-else-if="key === 'image_tiff_compression'" label="TIFF压缩方式">
                        <el-select v-model="globalConfig[key]" style="width: 100%">
                          <el-option
                              v-for="item in options.tiffCompression"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                      
                      <!-- TIFF-JPEG质量 -->
                      <el-form-item v-else-if="key === 'image_tiff_jpeg_quality'" label="TIFF-JPEG质量">
                        <el-slider
                            v-model="globalConfig[key]"
                            :min="0"
                            :max="100"
                            :step="1"
                            show-input
                        />
                      </el-form-item>
                    </template>
                    
                  </el-form>
                </div>
              </el-tab-pane>
              
              <el-tab-pane 
                v-for="group in filteredDeviceParam"
                :key="group.group_name"
                :label="'设备配置:  '+group.group_name"
                :name="group.group_name"
              >
                <div class="tab-content">
              
                  <el-form label-width="200px" label-position="left">
                    <el-form-item 
                        v-for="param in group.group_param" 
                        :key="param.name"
                        :label="param.name"
                    >
                      <!-- 布尔类型 -->
                      <el-switch 
                          v-if="param.value_type === 'bool'"
                          v-model="param.value"
                      />
                      
                      <!-- 字符串类型且有选项列表 -->
                      <el-select
                          v-else-if="param.value_type === 'string' && param.range_type === 'list'"
                          v-model="param.value"
                          style="width: 100%"
                      >
                        <el-option
                            v-for="item in param.value_list"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                      </el-select>
                      
                      <!-- 数值类型且有范围 -->
                      <template v-else-if="(param.value_type === 'int' || param.value_type === 'double') && param.range_type === 'min_max'">
                        <el-input-number
                            v-model="param.value"
                            :min="param.value_min"
                            :max="param.value_max"
                            :step="getStepByType(param)"
                            :precision="param.value_type === 'double' ? 2 : 0"
                            style="width: 180px"
                        />
                        <el-slider
                            v-model="param.value"
                            :min="param.value_min"
                            :max="param.value_max"
                            :step="getStepByType(param)"
                            style="margin-left: 20px; width: calc(100% - 200px);"
                        />
                      </template>
                      
                      <!-- 其他类型 -->
                      <el-input 
                          v-else
                          v-model="param.value"
                      />
                    </el-form-item>
                  </el-form>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="configVisible = false">取消</el-button>
          <el-button type="primary"  @click="saveConfig">保存</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 错误详情对话框 -->
    <el-dialog
      v-model="errorDetailDialogVisible"
      title="错误详情"
      width="80vw"
    >
      <div class="error-detail-content">{{ errorDetail }}</div>
    </el-dialog>
    
    <!-- 上传对话 -->
    <el-dialog
      v-model="uploadVisible"
      title="选择要上传的图片"
      width="800px"
    >
      <div class="upload-image-list">
        <div 
          v-for="(image, index) in scannedImages"
          :key="index"
          :class="['upload-image-item', { active: selectedUploadImages.includes(index) }]"
          @click="handleImageSelect(index)"
        >
          <div class="upload-image-index" v-if="selectedUploadImages.includes(index)">
            {{ selectedUploadImages.indexOf(index) + 1 }}
          </div>
          <el-image 
            :src="image.thumbnail"
            fit="contain"
          >
            <template #error>
              <div class="image-slot">加载失败</div>
            </template>
          </el-image>
        </div>
      </div>
      
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="uploadVisible = false">取消</el-button>
          <el-button 
            type="primary" 
            :loading="uploadLoading"
            @click="handleUpload"
          >
            上传
          </el-button>
        </div>
      </template>
    </el-dialog>
    
    <!-- 试卷扫描的表格展示 -->
    <div v-if="selectedTemplate === 'exam'" class="scan-table-container">
    <!-- @row-click="handleRowClick" -->
     <!-- 全局扫描的表格 -->
      <el-table
        ref="scanTable"
        :data="processedImages"
        height="100%"
        style="width: 100%"
        v-bind="$attrs"
        :row-class-name="tableRowClassNameForScanType3"
        v-if="scanType == 3 || scanType == 2" 
      >
        <el-table-column type="index" label="序号"  width="100"/>
        <!-- <el-table-column prop="fileName" label="文件名" min-width="200">
          <template #default="{ row }">
            <div>
              <div>正面: {{ row.path.split('\\').pop() }}</div>
              <div v-if="isFrontBack && row.backImage">
                反面: {{ row.backImage.path.split('\\').pop() }}
              </div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="studentId" label="考号" >
          <template #default="{ row }">
            <template v-if="row.loading">
              <el-icon class="is-loading"><Loading /></el-icon>
              <span style="margin-left: 5px">识别中...</span>
            </template>
            <template v-else>
              <template v-if="row.studentId">
                <el-text 
                  :type="row.error ? 'danger' : row.isManualInput ? 'warning' : 'success'"
                  style="font-size: 16px; font-weight: bold;"
                >
                  {{ row.studentId }}
                </el-text>
                <el-tooltip 
                  v-if="row.isManualInput" 
                  content="手动输入的考号" 
                  placement="top"
                >
                  <el-icon class="manual-icon" style="color: var(--el-color-warning)">
                    <EditPen />
                  </el-icon>
                </el-tooltip>
              </template>
              <el-text v-else type="info">未识别</el-text>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="缺考" width="100">
          <template #default="{ row }">
            {{ row.isAbsent }}
          </template>
        </el-table-column>

        <el-table-column label="考场信息" width="150">
          <template #default="{ row }">
            <template v-if="row.roomInfo">
              <template v-if="row.roomInfo.room_id">
                <div>考场: {{ row.roomInfo.room_id }}</div>
                <div>座位: {{ row.roomInfo.seat_num }}</div>
              </template>
              <template v-else>
                <el-text type="info">未编排</el-text>
              </template>
            </template>
          </template>
        </el-table-column>
       
        <el-table-column label="第一页答案" width="200">
          <template #default="{ row }">
            {{ row.page1Answer }}
          </template>
        </el-table-column>
        <el-table-column label="第二页答案" width="200">
          <template #default="{ row }">
            {{ row.page2Answer }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="上传前状态" width="350">
          <template #default="{ row }">
            <template v-if="row.preStatus">
              正面：
              <el-tag :type='row.preStatus.page1 == 0 ? "info" : row.preStatus.page1 == 1 ? "warning" : row.preStatus.page1 ==2 ? "success": row.preStatus.page1 == 3 ? "danger" : "danger"'>
                {{row.preStatus.page1 == 0 ? "未上传" : row.preStatus.page1 == 1 ? "已上传但未处理" : row.preStatus.page1 ==2 ? '已处理': row.preStatus.page1 == 3 ? '处理异常' : "未知"}}
              </el-tag>
              <el-divider direction="vertical"/>
              背面：
              <el-tag :type='row.preStatus.page2 == 0 ? "info" : row.preStatus.page2 == 1 ? "warning" : row.preStatus.page2 ==2 ? "success": row.preStatus.page2 == 3 ? "danger" : "danger"'>
                {{row.preStatus.page2 == 0 ? "未上传" : row.preStatus.page2 == 1 ? "已上传但未处理" : row.preStatus.page2 ==2 ? '已处理': row.preStatus.page2 == 3 ? '处理异常' : "未知"}}
              </el-tag>
            </template>
          </template>
        </el-table-column> -->
  
        <el-table-column label="上传结果" width="400">
          <template #default="{ row }">
            <template v-if="row.error">
              <el-text 
                class="error-text" 
                type="danger" 
                style="font-weight: bold; cursor: pointer;"
                @click="showErrorDetail(row.error)"
              >
                {{ row.error.length > 20 ? row.error.substring(0, 20) + '...' : row.error }}
              </el-text>
            </template>
            <template v-else-if="row.page1Res && row.page1Res.success === false && row.page1Res.error">
              <el-text 
                class="error-text" 
                type="danger" 
                style="font-weight: bold; cursor: pointer;"
                @click="showErrorDetail(row.page1Res)"
              >
                {{ row.page1Res.error.length > 20 ? row.page1Res.error.substring(0, 20) + '...' : row.page1Res.error }}
              </el-text>
            </template>
            <template v-else-if="row.page2Res && row.page2Res.success === false && row.page2Res.error"> 
              <el-text 
                class="error-text" 
                type="danger" 
                style="font-weight: bold; cursor: pointer;"
                @click="showErrorDetail(row.page2Res)"
              >
                {{ row.page2Res.error.length > 20 ? row.page2Res.error.substring(0, 20) + '...' : row.page2Res.error }}
              </el-text>
            </template>
            <template v-else-if="row.dealInfo">
              <div class="upload-result-container">
                <div class="result-item">
                  <span class="result-label">正面：</span>
                  <el-text 
                    :type="getUploadResultLabel(row.dealInfo.page1,row.preStatus.page1).type"
                    size="small"
                  >
                    {{getUploadResultLabel(row.dealInfo.page1,row.preStatus.page1).label}}
                  </el-text>
                </div>
                <template v-if="isFrontBack">
                  <el-divider direction="vertical" />
                  <div class="result-item">
                    <span class="result-label">反面：</span>
                    <el-text 
                      :type="getUploadResultLabel(row.dealInfo.page2,row.preStatus.page2).type"
                      size="small"
                    >
                      {{getUploadResultLabel(row.dealInfo.page2,row.preStatus.page2).label}}
                    </el-text>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </el-table-column>
      <!--   <el-table-column prop="timeStamp" label="扫描时间" width="200">
          <template #default="{ row }">
            {{ formatTimeStamp(row.timeStamp) }}
          </template>
        </el-table-column> -->
        <el-table-column label="图片" fixed="right" width="200">
          <template #default="{ row }">
            <el-image 
              style="width: 30px; height: 30px; margin-right: 10px;"
              :src="row.base64"
              :preview-src-list="[row.base64]"
              preview-teleported
              fit="cover"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
            <el-image
              v-if="isFrontBack && row.backImage"
              style="width: 30px; height: 30px;"
              :src="row.backImage.base64"
              :preview-src-list="[row.backImage.base64]"
              preview-teleported
              fit="cover"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
      </el-table>
      <!-- 考场扫描的表格 -->
      <el-table
        ref="roomScanTable"
        :data="roomTableData"
        height="100%"
        style="width: 100%"
        v-if="scanType == 1" 
        :row-class-name="tableRowClassNameForScanType1"
      >
        <el-table-column prop="room_name" label="考场"  />
        <el-table-column prop="seat_num" label="座位号" width="80" />
        <el-table-column prop="student_id" label="考号" width="220"/>
        <el-table-column label="缺考" prop="isAbsent" width="100"/>
        <el-table-column label="上传结果" width="400">
          <template #default="{ row }">
            <template v-if="!row.scannedImages">
              本次未扫描到
            </template>
            <template v-else>
              <div class="upload-result-container">
                <div class="result-item">
                  <span class="result-label">正面：</span>
                  <el-text 
                    :type="getUploadResultLabel(row.scannedImages.dealInfo.page1,row.scannedImages.preStatus.page1).type"
                  >
                    {{getUploadResultLabel(row.scannedImages.dealInfo.page1,row.scannedImages.preStatus.page1).label}}
                  </el-text>
                </div>
                <template v-if="isFrontBack">
                  <el-divider direction="vertical" />
                  <div class="result-item">
                    <span class="result-label">反面：</span>
                    <el-text 
                      :type="getUploadResultLabel(row.scannedImages.dealInfo.page2,row.scannedImages.preStatus.page2).type"
                    >
                      {{getUploadResultLabel(row.scannedImages.dealInfo.page2,row.scannedImages.preStatus.page2).label}}
                    </el-text>
                  </div>
                </template>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="第一页答案" width="200">
          <template #default="{ row }">
            {{ row.page1Answer }}
          </template>
        </el-table-column>
        <el-table-column label="第二页答案" width="200">
          <template #default="{ row }">
            {{ row.page2Answer }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="timeStamp" label="扫描时间" width="200">
          <template #default="{ row }">
            {{ formatTimeStamp(row.scannedImages?.timeStamp) }}
          </template>
        </el-table-column> -->
        <el-table-column label="图片" fixed="right" width="200">
          <template #default="{ row }">
            <el-image
              style="width: 30px; height: 30px; margin-right: 10px;"
              :src="row.scannedImages?.frontImage.base64"
              :preview-src-list="[row.scannedImages?.frontImage.base64]"
              preview-teleported
              fit="cover"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
            <el-image
              v-if="isFrontBack && row.scannedImages?.backImage"
              style="width: 30px; height: 30px;"
              :src="row.scannedImages?.backImage.base64"
              :preview-src-list="[row.scannedImages?.backImage.base64]"
              preview-teleported
              fit="cover"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <!-- 添加未识别考号对话框模板 -->
    <el-dialog
      v-model="unrecognizedDialogVisible"
      title="未识别考号处理"
      width="90%"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="unrecognized-dialog"
    >
      <div class="unrecognized-content">
        <template v-if="unrecognizedImages.length > 0">
          <p class="warning-text">
            未能识别考号的试卷 ({{ currentUnrecognizedIndex + 1 }}/{{ unrecognizedImages.length }})，请手动输入：
          </p>
          <div class="unrecognized-item">
            <div class="input-container">
              <div class="input-label">请输入考号：</div>
              <el-input
                v-model="unrecognizedImages[currentUnrecognizedIndex].manualStudentId"
                placeholder="请输入考号"
                size="large"
                clearable
                @keyup.enter="handleUnrecognizedConfirm"
              />
            </div>
            <div class="file-info">
              <div>正面文件名: {{ unrecognizedImages[currentUnrecognizedIndex].frontImage.path.split('\\').pop() }}</div>
              <div v-if="unrecognizedImages[currentUnrecognizedIndex].backImage">
                反面文件名: {{ unrecognizedImages[currentUnrecognizedIndex].backImage.path.split('\\').pop() }}
              </div>
            </div>
            <div class="image-preview-container">
              <div class="image-side">
                <div class="side-label">正面</div>
                <el-image 
                  :src="unrecognizedImages[currentUnrecognizedIndex].frontImage.base64"
                  :preview-src-list="[unrecognizedImages[currentUnrecognizedIndex].frontImage.base64]"
                  fit="contain"
                  :preview-teleported="true"
                  class="preview-img"
                >
                  <template #viewer>
                    <div class="image-viewer-controls">
                      <el-button-group>
                        <el-button @click="rotateImage(-90)" icon="Refresh-left">向左旋转</el-button>
                        <el-button @click="rotateImage(90)" icon="Refresh-right">向右旋转</el-button>
                        <el-button @click="zoomImage(1.2)" icon="ZoomIn">放大</el-button>
                        <el-button @click="zoomImage(0.8)" icon="ZoomOut">缩小</el-button>
                      </el-button-group>
                    </div>
                  </template>
                </el-image>
              </div>
              <div v-if="unrecognizedImages[currentUnrecognizedIndex].backImage" class="image-side">
                <div class="side-label">反面</div>
                <el-image 
                  :src="unrecognizedImages[currentUnrecognizedIndex].backImage.base64"
                  :preview-src-list="[unrecognizedImages[currentUnrecognizedIndex].backImage.base64]"
                  fit="contain"
                  :preview-teleported="true"
                  class="preview-img"
                >
                  <template #viewer>
                    <div class="image-viewer-controls">
                      <el-button-group>
                        <el-button @click="rotateImage(-90)" icon="Refresh-left">向左旋转</el-button>
                        <el-button @click="rotateImage(90)" icon="Refresh-right">向右旋转</el-button>
                        <el-button @click="zoomImage(1.2)" icon="ZoomIn">放大</el-button>
                        <el-button @click="zoomImage(0.8)" icon="ZoomOut">缩小</el-button>
                      </el-button-group>
                    </div>
                  </template>
                </el-image>
              </div>
            </div>
          </div>
        </template>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleUnrecognizedCancel">取消</el-button>
          <el-button type="primary" @click="handleUnrecognizedConfirm">
            {{ unrecognizedImages.length > 0 ? '确认' : '关闭' }}
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 考场错误信息对话框 -->
    <el-dialog
      v-model="roomErrorDialogVisible"
      title="考场扫描错误信息"
      width="80%"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-table :data="roomErrInfos" style="width: 100%" border>
        <el-table-column prop="studentId" label="考号" width="180" />
        <el-table-column prop="error" label="错误原因" min-width="300">
          <template #default="{ row }">
            <span style="color: var(--el-color-danger);">{{ row.error }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片预览" width="400">
          <template #default="{ row }">
            <el-image 
              style="width: 100px; height: 100px; margin-right: 10px;"
              :src="row.frontImage.base64"
              :preview-src-list="[row.frontImage.base64]"
              preview-teleported
              fit="cover"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
            <el-image
              v-if="isFrontBack && row.backImage"
              style="width: 100px; height: 100px;"
              :src="row.backImage.base64"
              :preview-src-list="[row.backImage.base64]"
              preview-teleported
              fit="cover"
            >
              <template #error>
                <el-icon><Picture /></el-icon>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="timeStamp" label="扫描时间" width="200">
          <template #default="{ row }">
            {{ formatTimeStamp(row.timeStamp) }}
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="roomErrorDialogVisible = false">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!--状态-->
    <el-space style="margin-left: 10px;height: 25px">
      <StatusDot type="danger">未上传/错误</StatusDot>
      <StatusDot type="success">上传成功</StatusDot>
      <StatusDot type="warning">缺考</StatusDot>
      <StatusDot type="info">未识别</StatusDot>
    </el-space> 
  </div>
</template>

<style scoped>

/* 去掉表格行hover时的背景色 */
:deep(.el-table__body tr:hover > td) {
    background-color: inherit !important;
  }

 /* 将这些样式从 scoped 中移出，或改用 :deep() 选择器 */
   :deep(.success-row) {
    --el-table-tr-bg-color: var(--el-color-success-light-9) !important;
  }

  :deep(.warning-row) {
    --el-table-tr-bg-color: var(--el-color-warning-light-9) !important;
  }

  :deep(.danger-row) {
    --el-table-tr-bg-color: var(--el-color-danger-light-9) !important;
  }

  :deep(.info-row) {
    --el-table-tr-bg-color: var(--el-color-info-light-9) !important;
  }

  .scanner-view {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    overflow: hidden;  /* 防止出现滚动条 */
  }

  .scanner-header {
    flex-shrink: 0;
    background-color: var(--el-bg-color);
    border-bottom: 1px solid var(--el-border-color-light);
  }

  .scanner-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .scanner-preview {
    flex: 1;
    display: flex;
    overflow: hidden;
    padding: 16px;
    gap: 16px;
  }

  .preview-sidebar {
    width: 300px;
    flex-shrink: 0;
    border: 1px solid var(--el-border-color-light);
    border-radius: 4px;
    overflow: hidden;  /* 改为 hidden，让内部动 */
    background-color: var(--el-bg-color);
    display: flex;  /* 添加 flex 布局 */
    flex-direction: column;  /* 垂直方向 */
  }

  .preview-main {
    flex: 1;
    border: 1px solid var(--el-border-color-light);
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--el-bg-color);
  }

  .thumbnail-list {
    flex: 1;  /* 占满剩余空间 */
    overflow-y: auto;  /* 允许垂滚动 */
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 0;  /* 重要：允许内容超出时滚动 */
  }

  .thumbnail-item {
    flex-shrink: 0;  /* 防止被压缩 */
    position: relative;
    border: 2px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
  }

  .thumbnail-item .el-image {
    width: 100%;
    height: 150px;
    flex-shrink: 0;  /* 防止图片被压缩 */
  }

  /* 美化滚动条 */
  .thumbnail-list::-webkit-scrollbar {
    width: 6px;
  }

  .thumbnail-list::-webkit-scrollbar-thumb {
    background-color: var(--el-border-color);
    border-radius: 3px;
  }

  .thumbnail-list::-webkit-scrollbar-track {
    background-color: var(--el-fill-color-lighter);
  }

  .preview-image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .scanner-status-wrapper {
    min-width: 430px;
    max-width: 500px;  /* 增加最宽度，止占用太多空 */
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
  }

  .scanner-status {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    width: 100%;  /* 确保状态容器占满宽度 */
  }

  .status-text {
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 1;
    flex: 1;  /* 文本容器占据剩余空间 */
    white-space: nowrap;  /* 防止文本换行 */
    overflow: hidden;  /* 隐藏溢出内容 */
    text-overflow: ellipsis;  /* 显示省略号 */
  }

  .status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }

  .status-label {
    font-size: 14px;
  }

  .status-label.success {
    color: var(--el-color-success);
  }

  .status-label.danger {
    color: var(--el-color-danger);
  }

  .active .status-dot {
    background-color: var(--el-color-success);
  }

  .inactive .status-dot {
    background-color: var(--el-color-danger);
  }

  .content-text {
    line-height: 1;
  }

  .el-collapse {
    border: none;
  }

  .el-form-item {
    margin-bottom: 18px;
  }

  .el-collapse-item :deep(.el-collapse-item__header) {
    font-weight: bold;
    font-size: 16px;
  }

  .el-form-item :deep(.el-form-item__label) {
    font-weight: normal;
    color: var(--el-text-color-regular);
  }


  .scanner-config-dialog :deep(.el-dialog) {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    height: 800px;
    display: flex;
    flex-direction: column;
  }

  .dialog-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--el-bg-color);
    padding: 20px;  /* 添加内边距 */
  }

  .content-wrapper {
    flex: 1;
    border: 1px solid var(--el-border-color-light);  /* 添加整体边框 */
    border-radius: 4px;
    overflow: hidden;  /* 确保内部元素不会超出边框 */
    display: flex;
    flex-direction: column;
  }

  .search-bar {
    padding: 16px 20px;
    border-bottom: 1px solid var(--el-border-color-light);
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: var(--el-bg-color);
  }

  .search-container {
    flex: 1;
  }

  .search-bar .el-button {
    width: 120px;  /* 固按钮宽��� */
    flex-shrink: 0;
  }

  .dialog-content :deep(.el-tabs__content) {
    flex: 1;
    padding: 0;  /* 移除内边距 */
    overflow-y: auto;
    background-color: var(--el-bg-color);
  }

  .tabs-container {
    flex: 1;
    display: flex;
  }

  .dialog-content :deep(.el-tabs) {
    flex: 1;
    display: flex;
  }

  .dialog-content :deep(.el-tabs.el-tabs--border-card) {
    border: none;  /* 移除 tabs 的边框 */
    box-shadow: none;  /* 移除 tabs 的影 */
  }

  .dialog-content :deep(.el-tabs__header) {
    margin: 0;
    background-color: var(--el-bg-color);
    height: 100%;
  }

  .dialog-content :deep(.el-tabs__nav-wrap) {
    height: 100%;
  }

  .dialog-content :deep(.el-tabs__nav) {
    height: 100%;
    min-width: 180px;
    border: none;
    background-color: var(--el-bg-color);
  }

  .dialog-content :deep(.el-tabs__content) {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: var(--el-bg-color);
  }

  .tab-content {
    max-height: calc(800px - 180px); /* 对话框高度减其他元素高度 */
    min-height: calc(800px - 180px); /* 对话框高度去其他元素度 */
    overflow-y: auto;
    padding-right: 16px;
  }

  .scanner-config-dialog :deep(.el-dialog__footer) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 20px;
    background-color: var(--el-bg-color);
    border-top: 1px solid var(--el-border-color-lighter);
    z-index: 1;
  }

  /* 调整容区域的下边距，为固定的底部按钮留出空间 */
  .tab-content {
    padding-bottom: 60px;
  }

  /* 添加参数组标题样式 */
  .param-group-header {
    margin-bottom: 20px;
  }

  .param-group-title {
    font-size: 16px;
    font-weight: bold;
    color: var(--el-color-primary);
    margin-bottom: 8px;
  }

  .param-group-divider {
    height: 2px;
    background: linear-gradient(to right, var(--el-color-primary), transparent);
    margin-bottom: 16px;
  }

  /* 修改标签页样式 */
  .dialog-content :deep(.el-tabs__item) {
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: 14px;
    position: relative;
    transition: all 0.3s;
  }

  .dialog-content :deep(.el-tabs__item.is-active) {
    background-color: var(--el-color-primary-light-9);
    color: var(--el-color-primary);
    font-weight: bold;
  }

  .dialog-content :deep(.el-tabs__item.is-active)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: var(--el-color-primary);
  }

  /* 优化表单布局 */
  .dialog-content :deep(.el-form-item) {
    margin-bottom: 22px;
  }

  .dialog-content :deep(.el-form-item__label) {
    font-weight: normal;
    padding-right: 12px;
  }

  /* 优化滑块和数输入框的布局 */
  .dialog-content :deep(.el-input-number) {
    width: 160px;
  }

  .dialog-content :deep(.el-slider) {
    margin-left: 16px;
    width: calc(100% - 180px);
  }

  /* 优化选择框宽度 */
  .dialog-content :deep(.el-select) {
    width: 100%;
  }

  /* 调整内容区域的内边距 */
  .tab-content {
    padding: 20px 24px 60px;
  }

  .toolbar {
    border-bottom: 1px solid var(--el-border-color-light);
    background-color: var(--el-bg-color);
  }

  .template-select {
    padding: 16px 20px;
    border-bottom: 1px solid var(--el-border-color-light);
  }

  .template-select :deep(.el-radio-group) {
    display: flex;
    gap: 16px;
  }

  .template-select :deep(.el-radio-button__inner) {
    padding: 8px 24px;
  }

  .scanner-content :deep(.el-radio-group) {
    display: flex;
    gap: 8px;
  }

  .scanner-content :deep(.el-radio-button__inner) {
    padding: 8px 16px;
  }

  .scanner-content :deep(.el-radio-button__inner),
  .scanner-content .el-button {
    height: 32px;
    line-height: 30px;
    padding: 0 16px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  .scanner-content :deep(.el-radio-button__inner:hover),
  .scanner-content .el-button:hover {
    opacity: 0.9;
  }

  .button-text {
    margin-left: 2px;
  }

  /* 图标垂直对齐 */
  .scanner-content :deep(svg) {
    vertical-align: middle;
    margin-top: -2px;
  }

  /* 统一按钮字体大小 */
  .scanner-content :deep(.el-radio-button__inner),
  .scanner-content .el-button {
    font-size: 14px;
  }

  /* 优化按钮组样式 */
  .scanner-content :deep(.el-radio-group) {
    display: inline-flex;
    align-items: center;
  }

  /* 添加过渡效果 */
  .scanner-content :deep(.el-radio-button__inner),
  .scanner-content .el-button {
    transition: all 0.3s;
  }

  .scan-actions {
    padding: 0 0 16px;
  }

  .thumbnail-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .thumbnail-item {
    position: relative;
    border: 2px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
  }

  .thumbnail-item:hover {
    border-color: var(--el-color-primary-light-5);
  }

  .thumbnail-item.active {
    border-color: var(--el-color-primary);
  }

  .thumbnail-item .el-image {
    width: 100%;
    height: 150px;
  }

  .preview-image .el-image {
    width: 100%;
    height: 100%;
  }

  .image-slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--el-fill-color-light);
    color: var(--el-text-color-secondary);
    font-size: 14px;
  }

  .image-slot .el-icon {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .thumbnail-placeholder {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--el-fill-color-light);
    color: var(--el-text-color-regular);
    font-size: 14px;
  }

  .thumbnail-index {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1;
  }

  .upload-image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    padding: 16px;
  }

  .upload-image-item {
    position: relative;
    border: 2px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;
  }

  .upload-image-item:hover {
    border-color: var(--el-color-primary-light-5);
  }

  .upload-image-item.active {
    border-color: var(--el-color-primary);
  }

  .upload-image-item .el-image {
    width: 100%;
    height: 150px;
  }

  .upload-image-index {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: rgba(255, 0, 0, 0.8); /* 红色背景 */
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 1;
  }

  /* 添加停止按钮标样式 */
  .scanner-content :deep(.el-button.el-button--danger) {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  /* 添加新的样式 */
  .scanner-content {
    width: 100%;
  }

  .scanner-toolbar {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .scan-type-row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
  }

  .scan-type-label {
    width: 200px;
    flex-shrink: 0;
    color: var(--el-text-color-regular);
    font-size: 14px;
  }

  .scan-type-content {
    flex: 1;
  }

  /* 调整单选按钮组样式 */
  .scan-type-content :deep(.el-radio-group) {
    display: flex;
    gap: 16px;
  }

  .scan-type-content :deep(.el-radio-button__inner) {
    padding: 0 20px;
  }

  /* 确保内容垂直居中 */
  .scanner-container {
    padding: 16px 16px 0;  /* 减小底部内边距 */
  }

  /* 调整间距 */
  .scanner-content > * + * {
    margin-top: 8px;
  }

  /* 添加扫描类型文本样式 */
  .scan-type-text {
    font-size: 14px;
    color: var(--el-text-color-regular);
    margin: 0 16px;
  }

  /* 修改 scanner-content 样式确保按钮对齐 */
  .scanner-content {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  /* 扫描类型行样式 */
  .scan-type-container {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-top: 4px;
    height: 40px;
    width: 100%;
    gap: 8px;
  }

  .scan-type-wrapper {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  .progress-wrapper {
    width: 300px;
    margin: 0 16px 0 0;
    flex-shrink: 0;
  }
  .progress-wrapper-width-150 {
    width: 150px;
    margin: 0 16px 0 0;
    flex-shrink: 0;
  }

  .progress-wrapper :deep(.el-progress) {
    width: 100%;
  }

  /* 调整进度条文字颜色 */
  .progress-wrapper :deep(.el-progress__text) {
    color: var(--el-color-primary);
  }

  .scan-type-extra {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  .progress-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
    margin-right: 16px;
  }

  .scan-stats {
    display: flex;
    gap: 32px;
    flex-shrink: 0;
    width: 200px;
    justify-content: flex-end;
  }

  .scan-table-container {
    flex: 1;
    padding: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .preview-dialog-content {
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--el-fill-color-light);
  }
  
  /* 调整表格样式 */
  .scan-table-container :deep(.el-table) {
    --el-table-border-color: var(--el-border-color-lighter);
    border: 1px solid var(--el-table-border-color);
    border-radius: 4px;
  }
  
  /* 调整表格行hover效果 */
  /* .scan-table-container :deep(.el-table__row:hover) {
    cursor: pointer;
  } */

  /* 未识别考号对话框样式 */
  :deep(.unrecognized-dialog) {
    .el-dialog__body {
      max-height: 80vh;
      overflow-y: auto;
      padding: 20px;
    }

    .el-input {
      .el-input__inner {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
      }
    }

    img {
      border: 2px solid #ddd;
      border-radius: 4px;
      background-color: #f5f7fa;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .el-dialog__footer {
    border-top: 1px solid #ddd;
    padding: 20px;
  }


  .unrecognized-content {
    max-height: 70vh;
    overflow-y: auto;
    padding: 20px;
  }

  .warning-text {
    margin-bottom: 20px;
    font-size: 16px;
    color: #f56c6c;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .unrecognized-item {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .image-preview-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  .image-side {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .side-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--el-text-color-primary);
  }

  .preview-img {
    width: 100%;
    height: 400px;
    border: 2px solid #ddd;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  :deep(.el-image-viewer__wrapper) {
    width: 100vw;
    height: 100vh;
  }

  :deep(.el-image-viewer__img) {
    max-width: 100%;
    max-height: 100%;
  }

  .image-viewer-toolbar {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    z-index: 2100;
  }

  .image-viewer-controls {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 8px;
    border-radius: 4px;
    z-index: 2100;
  }

  .image-viewer-controls .el-button {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.3);
    color: white;
  }

  .image-viewer-controls .el-button:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  :deep(.el-image-viewer__img) {
    transition: transform 0.3s ease;
  }

  .manual-icon {
    margin-left: 4px;
    font-size: 12px;
    vertical-align: middle;
  }

  /* 手动输入的考号标签样式 */
  :deep(.el-tag--warning) {
    display: inline-flex;
    align-items: center;
    padding-right: 8px;
  }

  /* 添加状态样式 */
  .el-table :deep(.cell) {
    white-space: nowrap;
  }

  /* 错误详情对话框样式 */
  .error-detail-dialog {
    .el-dialog__body {
      max-height: 80vh;
      overflow-y: auto;
      padding: 20px;
    }

    .el-input {
      .el-input__inner {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
      }
    }

    img {
      border: 2px solid #ddd;
      border-radius: 4px;
      background-color: #f5f7fa;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .error-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* 错误信息相关样式 */
  :deep(.error-detail-dialog) {
    .el-message-box__content {
      max-height: 400px;
      overflow-y: auto;
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 14px;
      line-height: 1.5;
      padding: 16px;
    }
  }

  .error-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .upload-result-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .result-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  
  :deep(.el-tag) {
    min-width: 80px;
    text-align: center;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
  }

  /* 考场错误信息对话框样式 */
  .error-dialog {
    .el-dialog__body {
      max-height: 80vh;
      overflow-y: auto;
      padding: 20px;
    }

    .error-content {
      max-height: 70vh;
      overflow-y: auto;
      padding: 20px;
    }

    .error-text {
      margin-bottom: 20px;
      font-size: 16px;
      color: #f56c6c;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .success-text {
      margin-bottom: 20px;
      font-size: 16px;
      color: #67c23a;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      margin-bottom: 10px;
    }
  }
</style>