import { defineStore } from 'pinia'
import { store } from '../index'
import Config from "@/utils/Config";
import {ElMessage, ElMessageBox } from 'element-plus';
import {Loading} from "element-plus/es/components/loading/src/service";
import { router } from '../../main';

// 扫描仪命令集
export const ScannerCommands = {
    // 设备初始化相关
    COMMAND_IS_DEVICE_INIT: "is_device_init", // 判断设备是否初始化
    COMMAND_INIT_DEVICE: "init_device", // 初始化设备
    COMMAND_GET_DEVICE_NAME_LIST: "get_device_name_list", // 获取设备名称列表

    // 配置
    COMMAND_GET_GLOBAL_CONFIG: "get_global_config", // 获取全局配置
    COMMAND_SET_GLOBAL_CONFIG: "set_global_config", // 设置全局配置
    COMMAND_GET_DEVICE_PARAM: "get_device_param", // 获取设备参数
    COMMAND_SET_DEVICE_PARAM: "set_device_param", // 设置设备参数
    COMMAND_RESET_DEVICE_PARAM: "reset_device_param", // 重置设备参数

    // 扫描相关
    COMMAND_START_SCAN: "start_scan", // 开始扫描
    COMMAND_STOP_SCAN: "stop_scan", // 停止扫描
    
    // 扫描事件
    EVENT_SCAN_BEGIN: "scan_begin", // 扫描开始
    EVENT_SCAN_END: "scan_end", // 扫描结束
    EVENT_SCAN_INFO: "scan_info", // 扫描信息
    EVENT_SCAN_IMAGE: "scan_image", // 扫描图像
    EVENT_DEVICE_ARRIVE: "device_arrive", // 设备到达
    EVENT_DEVICE_REMOVE: "device_remove", // 设备移除

    // 加载图像
    COMMAND_LOAD_IMAGE: "load_image", // 加载图像
    COMMAND_GET_THUMBNAIL_LIST: "get_image_thumbnail_list", // 获取图像缩略图列表
    COMMAND_CLEAR_IMAGE_LIST: "clear_image_list", // 清理图像列表

    // 打开设备
    COMMAND_OPEN_DEVICE: "open_device", // 打开设备
    COMMAND_CLEAR_GLOBAL_PATH: "clear_global_file_save_path", // 清理全局文件保存目录
}
// 当相关接口响应ret不为0时，没有错误信息时的默认错误信息
const CommandErrorInfo = {
    [ScannerCommands.COMMAND_INIT_DEVICE]: "设备未连接，请检查扫描仪是否打开！",
}

// 构建命令的工具函数
function buildCommand(command, params = {}) {
    return {
        ...params,
        ...{func: command},
    }
}

export const useScannerStore = defineStore('scanner', {
    state: () => ({
        websocket: null, // WebSocket 连接
        callBackList: {}, // 回调函数列表
        scannerName: "", // 扫描仪名称
        globalConfig: {}, // 全局配置
        isDeviceOpen: false, // 新增状态

        lastSetGlobalConfig: {}, // 上一次设置的全局配置, 有时候会没设置成功
        lastDeviceParam: {}, // 上一次设置的设备参数, 有时候会没设置成功
    }),
    
    getters: {
        getScannerName: (state) => state.scannerName ? state.scannerName : "",
        getIsDeviceOpen: (state) => state.isDeviceOpen // 新增 getter
    },
    
    actions: {
        // 初始化设备
        command_initDevice(){
            const command = buildCommand(ScannerCommands.COMMAND_INIT_DEVICE)
            return new Promise((resolve,reject)=>{
                this.sendCommand(command,()=>{
                    resolve()
                })
            })
        },
        // 判断设备是否初始化
        command_isInit() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_IS_DEVICE_INIT),
                    (info) => resolve(info.ret === 0)
                )
            })
        },
        // 获取扫描仪名称列表
        async command_getScannerNameList() {
            const isInit = await this.command_isInit()
            if (!isInit) {
                await this.command_initDevice()
            }
            
            this.sendCommand(
                buildCommand(ScannerCommands.COMMAND_GET_DEVICE_NAME_LIST),
                async (info) => {
                    if (info.device_name_list.length === 0) {
                        ElMessage.warning("未检测到扫描仪，请检查扫描仪是否打开！")
                    } else if (info.device_name_list.length !== 1) {
                        ElMessage.warning("检测到多个扫描仪，请只连接一个扫描仪！")
                    } else {
                        this.scannerName = info.device_name_list[0]
                        // 获取到设备名称后，先清理文件再打开设备
                        try {
                            await this.command_clearGlobalPath()
                            await this.command_clearImageList()
                            await this.command_openDevice(this.scannerName)
                            ElMessage.success("扫描仪连接成功！")
                        } catch (error) {
                            console.error('打开设备失败:', error)
                            ElMessage.error('打开设备失败，请重试！')
                        }
                    }
                }
            )
        },
        // 获取全局配置
        command_getGlobalConfig(){
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_GET_GLOBAL_CONFIG),
                    (info) => {
                        this.globalConfig = info
                        resolve(info)
                    }
                )
            })
        },
        // 设置全局配置
        command_setGlobalConfig(config) {
            console.log("设置全局配置:", config)
            this.lastSetGlobalConfig = config
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_SET_GLOBAL_CONFIG, config),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        // 获取设备参数
        command_getDeviceParam() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_GET_DEVICE_PARAM),
                    (info) => {
                        resolve(info.device_param || [])
                    }
                )
            })
        },
        // 设置设备参数
        command_setDeviceParam(params) {
            this.lastDeviceParam = params
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_SET_DEVICE_PARAM, {
                        device_param: params
                    }),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        // 重置设备参数
        command_resetDeviceParam() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_RESET_DEVICE_PARAM),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        // 以上都是封装扫描仪的接口


        // WebSocket 回调处理
        addCallBack(key, listener) {
            this.callBackList[key] = listener;
        },
        removeCallBack(key) {
            delete this.callBackList[key];
        },
        getCallBack(key) {
            return this.callBackList[key];
        },
        
        // 发送命令
        sendCommand(command, callBackListener) {
            if (command.func && callBackListener) {
                this.addCallBack(command.func, callBackListener)
            }
            console.log("发送命令:", command)
            this.websocket.send(JSON.stringify(command))
        },
        
        setWebSocket(socket) {
            this.websocket = socket
        },
        reset() {
            // 重置状态
            this.scannerName = ""
            this.isDeviceOpen = false // 重置设备状态
        },

        // WebSocket 连接初始化
        initWebSocket() {
            const loading = Loading({
                lock: true,
                text: "正在检测软件是否启动...",
                background: "rgba(0, 0, 0, 0.7)",
            })
            if (!this.websocket) {
                const socket = new WebSocket(Config.scannerWS)
                
                socket.onopen = () => {
                    console.log("扫描仪websocket建立成功！")
                    this.setWebSocket(socket)
                    loading.close()
                    this.command_getScannerNameList()
                    this.callBackList['loadHistoryImages']?.()
                }
                
                socket.onerror = (err) => {
                    console.error("扫描仪websocket连接错误！", err)
                    ElMessage.warning("扫描仪连接失败,请检查软件是否打开！")
                    loading.close()
                }
                
                socket.onmessage = (msg) => {
                    const jsonData = JSON.parse(msg.data)
                    console.log("收到消息:", jsonData)
                    
                    const callback = this.getCallBack(jsonData.func)
                    
                    // 特殊处理 open_device 命令
                    if (jsonData.func === ScannerCommands.COMMAND_OPEN_DEVICE) {
                        // 当设备成功打开或已经打开时，都设置 isDeviceOpen 为 true
                        if (jsonData.ret === 0 || (jsonData.ret === -1 && jsonData.err_info === '已打开设备')) {
                            this.isDeviceOpen = true
                        }
                        if (callback) callback(jsonData)
                        return
                    }
                    
                    // 扫描相关的事件和设备事件不需要检查 ret
                    if(jsonData.func === ScannerCommands.EVENT_SCAN_BEGIN 
                        || jsonData.func === ScannerCommands.EVENT_SCAN_END
                        || jsonData.func === ScannerCommands.EVENT_SCAN_INFO
                        || jsonData.func === ScannerCommands.EVENT_SCAN_IMAGE
                        || jsonData.func === ScannerCommands.EVENT_DEVICE_ARRIVE
                        || jsonData.func === ScannerCommands.EVENT_DEVICE_REMOVE
                        || jsonData.func === ScannerCommands.COMMAND_IS_DEVICE_INIT
                    ){
                        if (callback) callback(jsonData)
                        return
                    }

                    // 其他命令，ret不为0则表示错误
                    if (jsonData.ret !== 0) { 
                        if(jsonData.err_info){
                            ElMessage.error(jsonData.err_info)
                        } else {
                            ElMessage.error(CommandErrorInfo[jsonData.func] || '操作失败')
                        }
                    } else {
                        if (callback) callback(jsonData)
                    }
                }
                
                socket.onclose = (e) => {
                    console.error("扫描仪websocket连接关闭！", e)
                    this.reset()
                    loading.close()
                }
                socket.onerror = (e) => {
                    console.error("扫描仪websocket连接错误！", e)
                    const htmlTemplate = `
                    <p>扫描连接失败,请检查软件是否打开！还未下载软件？</p>
                    <a href="/api/common/download/4" style="color: var(--el-color-primary)" target="_blank">点击下载</a>
                    `
                    ElMessageBox.confirm(htmlTemplate, "提示", {
                        confirmButtonText: "再次尝试",
                        cancelButtonText: "退出扫描",
                        dangerouslyUseHTMLString: true,
                        showClose: false,
                        closeOnClickModal: false,
                    }).then(() => {
                        this.initWebSocket()
                    }).catch((action) => {
                        if(action === "cancel"){    
                            router.replace({name:"exam"})
                        }
                    })
                    .finally(() => {
                        loading.close()
                    })
                }
            } else {
                this.command_getScannerNameList()
                loading.close()
            }
        },
        
        // 开始扫描
        command_startScan(params = {}) {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_START_SCAN, {
                        blank_check: false,
                        local_save: true,
                        get_base64: true,
                        ...params
                    }),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        
        // 停止扫描
        command_stopScan() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_STOP_SCAN),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        
        // 加载图像
        command_loadImage(imageIndex) {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_LOAD_IMAGE, {
                        image_index: imageIndex,
                        iden: imageIndex+""
                    }),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        
        // 获取图像缩略图列表
        command_getImageThumbnailList() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_GET_THUMBNAIL_LIST),
                    (info) => {
                        resolve(info.image_thumbnail_list || [])
                    }
                )
            })
        },
        
        // 清理图像列表
        command_clearImageList() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_CLEAR_IMAGE_LIST),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
        
        // 打开设备
        command_openDevice(deviceName = '') {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_OPEN_DEVICE, {
                        device_name: deviceName
                    }),
                    (info) => {
                        // 设备成功打开或已经打开时，都设置为 true
                        if (info.ret === 0 || (info.ret === -1 && info.err_info === '已打开设备')) {
                            this.isDeviceOpen = true
                            // 设置上一次设置的全局配置
                            if(this.lastSetGlobalConfig && Object.keys(this.lastSetGlobalConfig).length > 0){
                                this.command_setGlobalConfig(this.lastSetGlobalConfig)
                            }
                            // 设置上一次设置的设备参数
                            if(this.lastDeviceParam && Object.keys(this.lastDeviceParam).length > 0){
                                this.command_setDeviceParam(this.lastDeviceParam)
                            }
                        }else if (info.ret === -1 && info.err_info === '设备未找到') {
                            this.isDeviceOpen = false
                            this.scannerName = ""
                        }
                        
                        resolve(info)
                    }
                )
            })
        },
        
        // 清理全局文件保存目录
        command_clearGlobalPath() {
            return new Promise((resolve, reject) => {
                this.sendCommand(
                    buildCommand(ScannerCommands.COMMAND_CLEAR_GLOBAL_PATH),
                    (info) => {
                        resolve(info)
                    }
                )
            })
        },
    },
    
    persist: false
})

export const useScannerStoreWithOut = () => {
    const scannerStore = useScannerStore(store)
    // 添加设备移除事件
    scannerStore.addCallBack(ScannerCommands.EVENT_DEVICE_REMOVE, (info) => {
        ElMessage.warning("扫描仪连接断开！")
        scannerStore.reset()
    })
    // 添加设备到达事件
    scannerStore.addCallBack(ScannerCommands.EVENT_DEVICE_ARRIVE, (info) => {
        // 打开设备
        scannerStore.command_openDevice(scannerStore.scannerName).then(()=>{
            scannerStore.scannerName = info.device_name
            scannerStore.isDeviceOpen = true
            ElMessage.success("扫描仪连接成功！")
        })
    })
    return scannerStore
}
