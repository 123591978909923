/**
 权限管理界面
 最后编辑人：郑志强
 最后编辑时间：2023-11-21
 最后编辑细节：增加消息提示框样式，当消息内容过长时进行滑动。。
 **/
import {createApp,} from 'vue'
import {createRouter, createWebHistory} from "vue-router"
import axios from 'axios'
import ElementPlus, {ElMessageBox} from 'element-plus'
import "element-plus/dist/index.css"
import "@/assets/css/common.css"
import App from './App.vue'
import {Loading} from "element-plus/es/components/loading/src/service";
import VueAxios from 'vue-axios'
import * as ElIcon from '@element-plus/icons-vue'
import locale from "element-plus/es/locale/lang/zh-cn"
/*
路由导入
 */
import Main from "@/components/Main";
import EducationUnit from "@/components/info/EducationUnit";
import ManageUnit from "@/components/info/ManageUnit";
import ManageUser from "@/components/info/ManageUser";
import TeacherUser from "@/components/info/TeacherUser";
import StudentMge from "@/components/info/StudentMge";
import ManageExam from "@/views/exam/ManageExam.vue";
import ManageExamSubjectPaper from "@/components/exam/ManageExamSubjectPaper";
import SubquestionAnswer from "@/components/exam/SubquestionAnswer";
import ManageExamTeacher from "@/components/exam/ManageExamTeacher";
import SubQuestionConfig from "@/components/exam/SubQuestionConfig";
import ManageExamStudent from "@/components/exam/ManageExamStudent";
import ExamUpload from "@/components/exam/ExamUpload";
import Permission from "@/components/sys/Permission";
import UserTypeAssign from "@/components/sys/UserTypeAssign";
import UserTagAssign from "@/components/sys/UserTagAssign";
import Tag from "@/components/sys/Tag";
import LoginLog from "@/components/sys/LoginLog";
import SysLog from "@/components/sys/SysLog";
import Login from "@/components/login/Login";
import NewManageExamSubjectPaper from "@/views/exam/NewManageExamSubjectPaper.vue";
import ManageExamScan from "@/components/exam/ManageExamScan.vue";
import ExamRoomMge from "@/components/exam/ExamRoomMge.vue";
import PageMain from "@/layout/PageMain.vue";
import M_LOGIN from "@/components/login/M_LOGIN.vue"
// 图标库
import '@icon-park/vue-next/styles/index.css';
import {install} from '@icon-park/vue-next/es/all';

// 状态管理
import {useUserStoreWithOut} from "@/store/modules/user";
import CardMenu from "@/layout/CardMenu.vue";
import ExamPermission from "@/views/exam/ExamPermission.vue";
import {setupStore} from "@/store";
import {useAppStoreWithOut} from "@/store/modules/app";


import {fabric} from "fabric";
import CommonUtils from "@/utils/CommonUtils";
import Config from "@/utils/Config";
import TestPage from "@/components/sys/TestPage.vue";
import viewDataAnalyseAndCheck from "@/components/exam/ViewDataAnalyseAndCheck.vue";
import RedisManager from "@/components/sys/RedisManager.vue";
import DisbindStudentAccount from "@/components/login/DisbindStudentAccount.vue";
import ScannerView from "@/views/exam/ScannerView.vue";


const isMoblile = CommonUtils.checkIfMobile()


// 重写Textbox,主要是防止手机端点击了之后聚焦导致自动弹出了输入法
fabric.NotFocusTextBox = fabric.util.createClass(fabric.Textbox, {
    enterEditing: function (e) {
        if (this.isEditing || !this.editable) {
            return;
        }

        if (this.canvas) {
            this.canvas.calcOffset();
            this.exitEditingOnOthers(this.canvas);
        }

        this.isEditing = true;

        this.initHiddenTextarea(e);
        // 这句话是原来的，现在注释掉了，其他地方没有改变。
        // this.hiddenTextarea.focus();
        // 只有对手机端禁用掉这句话
        if (!isMoblile) {
            this.hiddenTextarea.focus();
        }
        this.hiddenTextarea.value = this.text;
        this._updateTextarea();
        this._saveEditingProps();
        this._setEditingProps();
        this._textBeforeEdit = this.text;

        this._tick();
        this.fire('editing:entered');
        this._fireSelectionChanged();
        if (!this.canvas) {
            return this;
        }
        this.canvas.fire('text:editing:entered', {target: this});
        this.initMouseMoveHandler();
        this.canvas.requestRenderAll();
        return this;
    },

    // 你可以重写其他方法或添加新的方法
    // ...
});
/**
 * 页面路由定义
 最后编辑人：zzq
 最后编辑时间：2022-5-25
 * 最后编辑细节：增加菜单及权限
 */


const routes = [
    {
        path: '/',
        component: App,
        redirect: '/main/cardMenu'
    },
    {
        path: '/netview/marking',
        name: 'netview-marking',
        component: () => import('@/components/marking/MarkingView.vue')
    },
    {
        path: '/main',
        name: 'main',
        component: PageMain,
        children: [
            {
                path: "cardMenu",
                name: "cardMenu",
                meta: {title: "首页", level: 1},
                component: CardMenu
            },
            {
                path: 'exam',
                name: 'exam',
                meta: {title: "考试管理", level: 2},
                component: ManageExam
            },
            {
                path: 'marking',
                name: 'marking',
                component: () => import('@/components/marking/Marking.vue'),
                meta: {title: "教师评卷", level: 2},
            },
            {
                path: 'analysis',
                name: 'analysis',
                component: () => import('@/components/analysis/Analysis.vue'),
                meta: {title: "成绩分析", level: 2},
            },
            {
                path: 'exam/scanView',
                name: 'exam-scanView',
                component: ScannerView,
                meta: {title: "试卷扫描", level: 3},
            },
            {
                path: 'exam/newmgeexamsubjectpapers',
                name: 'exam-newmgeexamsubjectpapers',
                component: NewManageExamSubjectPaper,
                meta: {title: "科目模板配置", level: 3},
            },
            {
                path: 'exam/subquestionanswer',
                name: 'exam-subquestionanswer',
                component: SubquestionAnswer,
                meta: {title: "客观题答案设置", level: 3},
            },
            {
                path: 'exam/exampermission',
                name: 'exam-exampermission',
                component: ExamPermission,
                meta: {title: "考试分工", level: 3},
            },
            {
                path: 'exam/viewDataAnalyseAndCheck',
                name: 'exam-viewDataAnalyseAndCheck',
                component: viewDataAnalyseAndCheck,
                meta: {title: "阅卷数据校核与分析", level: 3},
            },
            {
                path: 'exam/mgeexamstudent',
                name: 'exam-mgeexamstudent',
                component: ManageExamStudent,
                meta: {title: "参考学生管理", level: 3},
            },
            {
                path: 'exam/examroommge',
                name: 'exam-examroommge',
                component: ExamRoomMge,
                meta: {title: "考场管理", level: 3},
            },
            {
                path: 'exam/examupload',
                name: 'exam-examupload',
                component: ExamUpload,
                meta: {title: "试卷上传", level: 3},
            },
            {
                path: 'exam/absentstudentmge',
                name: 'exam-absentstudentmge',
                component: () => import('@/components/exam/AbsentStudentMge.vue'),
                meta: {title: "学生参考情况校核", level: 3},
            },
            {
                path: 'netview/bufferManage',
                name: 'exam-mgeexam-netview-bufferManage',
                component: () => import('@/components/netview/BufferManage.vue'),
                meta: {title: "缓冲区管理", level: 3},
            },
            {
                path: 'netview/datamge',
                name: 'exam-mgeexam-netview-datamge',
                component: () => import('@/components/netview/DataMge.vue'),
                meta: {title: "数据管理", level: 3},
            },
            {
                path: 'netview/inspectFinished',
                name: 'exam-mgeexam-netview-inspectFinished',
                component: () => import('@/components/netview/InspectFinished.vue'),
                meta: {title: "成品卷抽查", level: 3},
            },
            {
                path: 'netview/exceptionPaperManage',
                name: 'exam-mgeexam-netview-exceptionPaper',
                component: () => import('@/components/netview/ExceptionPaperManage.vue'),
                meta: {title: "异常卷管理", level: 3},
            },
            {
                path: 'netview/exceptionPaperInit',
                name: 'exam-mgeexam-netview-exceptionPaperInit',
                component: () => import('@/components/netview/ExceptionPaperInit.vue'),
                meta: {title: "异常卷初始化", level: 3},
            },
            {
                path: 'netview/standardPaperManage',
                name: 'exam-mgeexam-netview-standardPaper',
                component: () => import('@/components/netview/StandardPaperManage.vue'),
                meta: {title: "标准卷管理", level: 3},
            },
            {
                path: 'netview/samplePaperManage',
                name: 'exam-mgeexam-netview-samplePaper',
                component: () => import('@/components/netview/SamplePaperManage.vue'),
                meta: {title: "样卷管理", level: 3},
            },
            {
                path: 'netview/studentQuestionManage',
                name: 'exam-mgeexam-netview-studentQuestionManage',
                component: () => import('@/components/netview/StudentQuestionManage.vue'),
                meta: {title: "学生试题管理", level: 3},
            },
            {
                path: 'markingStatistics/markingProgress',
                name: 'exam-mgeexam-markingStatistics-markingProgress',
                component: () => import('@/components/markingstatistics/MarkingProgress.vue'),
                meta: {title: "评卷进度", level: 3},
            },
            {
                path: 'markingStatistics/questionGroupWorkload',
                name: 'exam-mgeexam-markingStatistics-questionGroupWorkload',
                component: () => import('@/components/markingstatistics/QuestionGroupWorkload.vue'),
                meta: {title: "题组工作量", level: 3},
            },
            {
                path: 'markingStatistics/userWorkload',
                name: 'exam-mgeexam-markingStatistics-userWorkload',
                component: () => import('@/components/markingstatistics/UserWorkload.vue'),
                meta: {title: "用户工作量", level: 3},
            },
            {
                path: 'markingStatistics/userAllocateAdvice',
                name: 'exam-mgeexam-markingStatistics-userAllocateAdvice',
                component: () => import('@/components/markingstatistics/UserAllocateAdvice.vue'),
                meta: {title: "用户分配建议", level: 3},
            },
            {
                path: 'quality/qualityStatistics',
                name: 'exam-mgeexam-quality-qualityStatistics',
                component: () => import('@/components/quality/QualityStatistics.vue'),
                meta: {title: "质量监控统计", level: 3},
            },
            {
                path: 'quality/reviewRate',
                name: 'exam-mgeexam-quality-reviewRate',
                component: () => import('@/components/quality/ReviewRate.vue'),
                meta: {title: "复评率统计", level: 3},
            },
            {
                path: 'quality/markDistribution',
                name: 'exam-mgeexam-quality-markDistribution',
                component: () => import('@/components/quality/MarkDistribution.vue'),
                meta: {title: "分数分布统计", level: 3},
            },
            {
                path: 'quality/selfGrade',
                name: 'exam-mgeexam-quality-selfGrade',
                component: () => import('@/components/quality/SelfGrade.vue'),
                meta: {title: "自评卷查看", level: 3},
            },
            {
                path: 'quality/standardGrade',
                name: 'exam-mgeexam-quality-standardGrade',
                component: () => import('@/components/quality/StandardGrade.vue'),
                meta: {title: "标准卷查看", level: 3},
            },
            {
                path: 'quality/userPaperAssessment',
                name: 'exam-mgeexam-quality-userPaperAssessment',
                component: () => import('@/components/quality/UserPaperAssessment.vue'),
                meta: {title: "用户考核统计", level: 3},
            },
            {
                path: "score/scoreCheck",
                name: "exam-mgeexam-score-scoreCheck",
                component: () => import('@/components/score/ScoreCheck.vue'),
                meta: {title: "成绩校核", level: 3},
            },
            {
                path: "score/scoreManage",
                name: "exam-mgeexam-score-score",
                component: () => import('@/components/score/ScoreManage.vue'),
                meta: {title: "成绩管理", level: 3},
            },
            {
                path: "score/objectiveCheck",
                name: "exam-mgeexam-score-objectiveCheck",
                component: () => import('@/components/score/ObjectiveCheck.vue'),
                meta: {title: "客观题校核", level: 3},
            },
            {
                path: '/sys/permission',
                name: 'sys-permission',
                meta: {title: "权限菜单管理", level: 2},
                component: Permission
            },
            {
                path: '/sys/usertypeassgin',
                name: 'sys-user-type-assign',
                meta: {title: "角色权限分配", level: 2},
                component: UserTypeAssign
            },
            {
                path: '/sys/usertagassgin',
                name: 'sys-user-tag-assign',
                meta: {title: "用户标签分配", level: 2},
                component: UserTagAssign
            },
            {
                path: '/sys/tag',
                name: 'sys-tag',
                meta: {title: "标签设置", level: 2},
                component: Tag
            },
            {
                path: '/sys/redismanager',
                name: 'sys-redis-manager',
                meta: {title: "Redis监控信息", level: 2},
                component: RedisManager
            },
            {
                path: '/sys/loginlog',
                name: 'sys-loginlog',
                meta: {title: "登录日志", level: 2},
                component: LoginLog
            },
            {
                path: '/sys/TestPage',
                name: 'sys-TestPage',
                meta: {title: "测试页面", level: 2},
                component: TestPage
            },
            {
                path: '/sys/syslog',
                name: 'sys-log',
                meta: {title: "系统日志", level: 2},
                component: SysLog
            },
            {
                path: '/info/teauser',
                name: 'info-tea-user',
                meta: {title: "用户管理", level: 2},
                component: TeacherUser

            },

            {
                path: '/info/eduunit',
                name: 'info-edu-unit',
                meta: {title: "经销商管理", level: 2},
                component: EducationUnit
            },
            {
                path: '/info/mgeunit',
                name: 'info-mge-unit',
                meta: {title: "经销商及学校管理", level: 2},

                component: ManageUnit
            },
            {
                path: '/info/mgeuser',
                name: 'info-mge-user',
                component: ManageUser
            },

            {
                path: '/info/student',
                name: 'info-mge-stu',
                component: StudentMge,
                meta: {title: "学生管理", level: 2},
            },

            {
                path: '/exam/mgeexamsubjectpapers',
                name: 'exam-mge-exam-subject-papers',
                component: ManageExamSubjectPaper
            },
            {
                path: '/exam/mgeexamteacher',
                name: 'exam-mge-exam-teacher',
                component: ManageExamTeacher
            },
            {
                path: '/exam/mgeexamscan',
                name: 'exam-mge-exam-scan',
                component: ManageExamScan
            },
            {
                path: '/exam/subquestionconfig',
                name: 'exam-mge-subquestion-configr',
                component: SubQuestionConfig
            },
            {
                path: 'analysis/analysisdetail',
                name: 'analysis-detail',
                component: () => import('@/components/analysis/AnalysisDetail.vue'),
                meta: {title: "成绩分析详情", level: 3, module: "analysis"},
            },
            {
                path: 'analysis/commonReport',
                name: 'analysis-commonReport',
                component: () => import('@/components/analysis/components/AnalysisMenu.vue'),
                meta: {title: "常用数据报表", level: 4, module: "analysis"},
                children: [
                    {
                        path: 'student-mark',
                        name: 'analysis-commonReport-student-mark',
                        component: () => import('@/components/analysis/commonReport/StudentMark.vue'),
                    },
                    {
                        path: 'question-mark',
                        name: 'analysis-commonReport-question-mark',
                        component: () => import('@/components/analysis/commonReport/QuestionMark.vue'),
                    },
                    {
                        path: 'subject-comparison',
                        name: 'analysis-commonReport-subject-comparison',
                        component: () => import('@/components/analysis/commonReport/SubjectComparison.vue'),
                    },
                    {
                        path: 'class-comparison',
                        name: 'analysis-commonReport-class-comparison',
                        component: () => import('@/components/analysis/commonReport/ClassComparison.vue'),
                    },
                    {
                        path: 'question-analysis',
                        name: 'analysis-commonReport-question-analysis',
                        component: () => import('@/components/analysis/commonReport/QuestionAnalysis.vue'),
                    }
                ]
            },
            {
                path: 'analysis/paperexplain',
                name: 'analysis-paper-explain',
                component: () => import('@/components/analysis/paperExplain/PaperExplain.vue'),
                meta: {title: "试卷讲评", level: 4, module: "analysis"},
            },
            {
                path: 'analysis/entiretyreport',
                name: 'analysis-entirety-report',
                component: () => import('@/components/analysis/entiretyReport/EntiretyReport.vue'),
                meta: {title: "统考报告", level: 4, module: "analysis"},
            },
            {
                path: 'analysis/schoolreport',
                name: 'analysis-school-report',
                component: () => import('@/components/analysis/schoolReport/SchoolReport.vue'),
                meta: {title: "学校报告", level: 4, module: "analysis"},
            },
            {
                path: 'analysis/researchreport',
                name: 'analysis-research-report',
                component: () => import('@/components/analysis/researchReport/ResearchReport.vue'),
                meta: {title: "教研报告", level: 4, module: "analysis"},
            },
            {
                path: 'analysis/headteacherreport',
                name: 'analysis-headTeacher-report',
                component: () => import('@/components/analysis/headTeacherReport/HeadTeacherReport.vue'),
                meta: {title: "班主任报告", level: 4, module: "analysis"},
            },
            {
                path: 'analysis/teacherreport',
                name: 'analysis-teacher-report',
                component: () => import('@/components/analysis/teacherReport/TeacherReport.vue'),
                meta: {title: "任课教师报告", level: 4, module: "analysis"},
            },
            {
                path: 'analysis/studentreport',
                name: 'analysis-student-report',
                component: () => import('@/components/analysis/studentReport/StudentReport.vue'),
                meta: {title: "学生报告", level: 3, module: "analysis"},
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/login/disbindStudentAccount',
        name: 'disbindStudentAccount',
        component: DisbindStudentAccount
    },
    {
        path: '/m_login',
        name: 'm_login',
        component: M_LOGIN
    },
]


export const router = createRouter({
    history: createWebHistory(),
    routes,
})


const app = createApp(App).use(VueAxios, axios).use(router).use(ElementPlus, {locale})
app.provide('$axios', axios)

setupStore(app)
install(app, "i"); // use default prefix 'icon', eg: icon is People, name is icon-people.


const userStore = useUserStoreWithOut()
// 检查权限指令，没有权限则不显示该元素
app.directive('check', {
    mounted: (el, binding) => {
        let exist = false
        var pages = userStore.getPages;
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].permission_url === binding.value) {
                exist = true
                break
            }
        }
        if (!exist) {
            // 隐藏
            el.style.display = "none"
        }
    }
})

const appStore = useAppStoreWithOut()

// 路由守卫
router.beforeEach((to, from, next) => {
    if (to.name === "cardMenu") {
        userStore.setPathFromRouter(to)
    } else if (to.meta?.level) {
        if (to.meta.level === 2) {
            userStore.clearPath()
            userStore.addPath("/main/cardMenu", "首页")
            userStore.addPathFromRouter(to)
        } else if (to.meta.level === 3 && to.meta.module === undefined) {
            userStore.clearPath()
            userStore.addPath("/main/cardMenu", "首页")
            userStore.addPath("/main/exam", "考试管理")
            userStore.addPathFromRouter(to, {
                examName: appStore.exam_name,
                subjectName: appStore.subject_name
            })
        } else if (to.meta.level === 3 && to.meta.module === "analysis") {
            userStore.clearPath()
            userStore.addPath("/main/cardMenu", "首页")
            userStore.addPath("/main/analysis", "成绩分析")
            userStore.addPathFromRouter(to)
        } else if (to.meta.level === 4 && to.meta.module === "analysis") {
            userStore.clearPath()
            userStore.addPath("/main/cardMenu", "首页")
            userStore.addPath("/main/analysis", "成绩分析")
            userStore.addPath("/main/analysis/analysisdetail", "成绩分析详情")
            userStore.addPathFromRouter(to)
        }
    }

    next()
})


Object.keys(ElIcon).forEach((key) => {
    app.component(key, ElIcon[key])
})
// Object.keys(icons).forEach(key => {
//     app.component(key, icons[key])
// })

let sendflag = false
let loading
axios.interceptors.request.use(request => {
    if (request.showLoading !== false) {
        loading = Loading({
            lock: true,
            spinner: "loading",
            background: "rgba(0, 0, 0, 0.2)",
            customClass: "api-loading"
        })
        userStore.setLoading(true)
    }
    if (request.establishWS === true) {
        userStore.initWebSocket()
    }
    return request
}, error => {
    loading.close()
    userStore.setLoading(false)
    return Promise.reject(error)
})
axios.interceptors.response.use(response => {
    const result = response.data

    // 如果data是blob直接返回
    if (response.data instanceof Blob) {
        loading.close()
        userStore.setLoading(false)
        return response
    }

    loading.close()
    userStore.setLoading(false)
    if (result.success) {
        sendflag = false
        return response
    } else {
        if (result.result.toString().indexOf("登录失效") > -1) {
            if (sendflag === false) {
                sendflag = true
                ElMessageBox.alert('<div style="width: 100%;height: 100%;max-height: 500px;overflow:auto">' + result.result + '</div>', "错误信息提示", {
                    dangerouslyUseHTMLString: true,
                    showClose: true
                })
            }
            router.replace({name: "login"})
            return response
        } else {
            ElMessageBox.alert('<div style="width: 100%;height: 100%;max-height: 500px;overflow:auto">' + result.result + '</div>', "错误信息提示", {
                dangerouslyUseHTMLString: true,
                showClose: true
            })
            return response
        }
    }
}, error => {
    loading.close()
    userStore.setLoading(false)
    // 忽略扫描仪接口的错误
    if (error.config && error.config.baseURL !== Config.localScannerUrl) {
        ElMessageBox.alert('<div style="width: 100%;height: 100%;max-height: 500px;overflow:auto">' + error + '</div>', "错误信息提示", {
            dangerouslyUseHTMLString: true,
            showClose: true
        })
    }
    return Promise.reject(error)
})


app.mount('#app')