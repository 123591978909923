<template>
  <el-container class="base-container">

    <!--    <el-header style="height: 32px; padding-right: 0;">
          <div style="display: flex; justify-content: space-between;">
            <div class="exam-title">{{ exam.exam_name }}  {{subjectName }}试卷</div>
          </div>
        </el-header>-->
    <el-container class="main-container" ref="mainSectorSubject" id="mainSectorSubject">
      <el-main ref="mainscroll" id="mainscroll" style="overflow: auto;padding: 10px 0 0 0;width: calc(100% - 350px);min-width: 900px">
        <div class="zoom-container">
          <div v-show="zoom!== -1"><span>{{ zoom }}</span></div>
          <el-icon size="20" class="zoom-class" @click="canvasZoom('zoomin')">
            <zoom-in/>
          </el-icon>
          <el-icon size="20" class="zoom-class" @click="canvasZoom('zoomout')">
            <zoom-out/>
          </el-icon>
        </div>
        <canvas ref="canvas" style="width: 100%;height: 100%;"></canvas>

      </el-main>
      <el-aside style="height: 100%;width: 350px; padding-top: 10px; position: relative;">
        <el-tabs type="border-card" v-model="activeTab" style="min-height: calc(100% - 10px);">
          <el-tab-pane label="试卷图片" name="paperTab">
            <el-upload
                ref="paperUpload"
                action="/api/exam/mgeexamsubjectpapers/uploadexamsubjectpaper"
                name="imgfile"
                list-type="text"
                accept="image/png,image/jpeg,image/gif"
                :show-file-list="false"
                :data="search"
                :file-list="uploadFileList"
                :on-success="uploadResult"
                :on-error="uploadError">
              <el-button siz="default" type="primary" icon="Plus">试卷图片</el-button>
              <div class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
            <el-divider></el-divider>
            <el-tree
                :data="subjectPapers"
                :props="paperTreeProps"
                node-key="paper_id"
                default-expand-all

                draggable
                @node-drop="paperDrop"
                :expand-on-click-node="false"
                ref="paperTree">
              <template v-slot="{ node, data }">
                <div class="paper-row">
                      <span>
                    <i class="picture-outline"></i>
                    {{ node.label }}
                  </span>
                  <span style="padding-left:10px">
                    <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => toPaperDel(data)"></el-button>
                    </el-tooltip>
                  </span>
                </div>

              </template>
            </el-tree>
          </el-tab-pane>
          <el-tab-pane label="试卷配置" name="propTab">
            <el-form :model="propData" :rules="propRule" ref="subForm" size="default" label-width="100px">
              <el-form-item label="试卷名称" prop="name">
                <el-input v-model="propData.name" style="width: 210px;"></el-input>
              </el-form-item>
              <el-form-item label="试卷总分" prop="total_score">
                <el-input v-model="propData.total_score" style="width: 210px;" readonly></el-input>
              </el-form-item>
              <el-form-item label="信息遮盖区域">
                <el-button size="default" :type="maskAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('mask-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.maskarea"
                    default-expand-all
                    empty-text=""
                    ref="maskAreaTree">
                  <template v-slot="
/* eslint-disable vue/no-unused-vars */ { node, data }">
                    <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除遮盖区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeMaskArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                    </div>

                  </template>
                </el-tree>
              </el-form-item>
              <el-form-item label="缺考标志区域">
                <el-button size="default" :type="absentAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('absent-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.absentarea"
                    default-expand-all
                    empty-text=""
                    ref="maskAreaTree">
                  <template v-slot="
/* eslint-disable vue/no-unused-vars */ { node, data }">
                    <div class="paper-row">
                         <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除遮盖区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeAbsentArea(data)"></el-button>
                        </el-tooltip>
                      </span>
                    </div>

                  </template>
                </el-tree>
              </el-form-item>
              <el-form-item label="缺考覆盖率" v-if="propData.absentarea.length!==0">
                <el-input-number :min="0" :max="100" v-model="propData.coverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="缺考未覆盖率" v-if="propData.absentarea.length!==0">
                <el-input-number :min="0" :max="100" v-model="propData.uncoverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="缺考选项类型" v-if="propData.absentarea.length!==0">
                <el-select v-model="propData.absent_omrfigure">
                  <el-option
                      v-for="item in [
                    {label: '圆', value: '1'},
                    {label: '方', value: '2'},
                    {label: '开方', value: '3'},
                ]"
                      :key="item"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="缺考识别色深" v-if="propData.absentarea.length!==0">
                <el-input-number :min="0" :max="100" v-model="propData.depth"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="条码类型">
                <el-radio-group v-model="propData.codeareatype">
                  <el-radio label="1">条形码</el-radio>
                  <el-radio label="2">ORM码</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="填写方向" v-show="propData.codeareatype==='2'">
                <el-radio-group v-model="propData.codeareadirection">
                  <el-radio label="1">横向</el-radio>
                  <el-radio label="2">纵向</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="识别区域">
                <el-button size="default" :type="codeAreaAddButton" round icon="Plus"
                           @click="toAreaAdd('code-area-add')"
                           :disabled="subjectPapers.length === 0">区域
                </el-button>
                <el-tree
                    :data="propData.codearea"
                    default-expand-all
                    empty-text=""
                    ref="maskAreaTree">
                  <template v-slot="{ node, data }">
                    <div class="paper-row">  <span :class="{'area-select': activeArea===data}"
                                                   @click="() => selectArea(data)">
                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                      </span>
                      <span style="padding-left:5px">
                        <el-tooltip content="删除条码区域" placement="top" effect="light" transition=""
                                    :enterable="false">
                          <el-button type="danger" class="tree-button" icon="delete"
                                     @click="() => removeCodeArea(data)"></el-button>
                        </el-tooltip>
                      </span></div>

                  </template>
                </el-tree>
              </el-form-item>

              <el-form-item label="欧码选项类型" v-if="propData.codeareatype==='2'">
                <el-select v-model="propData.orm_omrfigure">
                  <el-option
                      v-for="item in [
                    {label: '圆', value: '1'},
                    {label: '方', value: '2'},
                    {label: '开方', value: '3'},
                ]"
                      :key="item"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="欧码覆盖率" v-if="propData.codeareatype==='2'">
                <el-input-number :min="0" :max="100" v-model="propData.orm_coverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>
              <el-form-item label="欧码未覆盖率" v-if="propData.codeareatype==='2'">
                <el-input-number :min="0" :max="100" v-model="propData.orm_uncoverage"
                                 style="width: 100px;"></el-input-number>
              </el-form-item>

              <el-form-item label="" v-show="propData.codeareatype==='2'">
                <el-button size="default" type="primary" round icon="Plus" @click="toOrmAreaBatchAdd">ORM区域识别
                </el-button>
              </el-form-item>
              <el-form-item label="ORM坐标识别区域" v-show="propData.codeareatype==='2'">
                <el-button type="danger" icon="delete" @click="deleteOrmArea">删除ORM坐标区域</el-button>
<!--                <el-tree-->
<!--                    :data="propData.ormarea"-->
<!--                    default-expand-all-->
<!--                    ref="maskAreaTree">-->
<!--                  <template v-slot="{ node, data }">-->
<!--                    <div class="paper-row">  <span :class="{'area-select': activeArea===data}"-->
<!--                                                   @click="() => selectArea(data)">-->
<!--                        {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </el-tree>-->
              </el-form-item>

              <el-form-item label="考号长度">
                <el-input v-model.number="propData.scan_prop.student_id_length" @change="(id)=>{
                  propData.scan_prop.cut_end = id.toString().length - 2
                }">
                </el-input>
              </el-form-item>
              <el-form-item label="分区范围">
                <el-input-number v-model="propData.scan_prop.cut_start" :min="0"
                                 :max="propData.scan_prop.student_id_length" :controls="false" size="small"
                                 style="width: 50px"/>
                -
                <el-input-number v-model="propData.scan_prop.cut_end" :min="0"
                                 :max="propData.scan_prop.student_id_length" :controls="false" size="small"
                                 style="width: 50px"/>
              </el-form-item>

              <el-form-item >
                <template #label>
                  <el-tooltip
                      content="仅对联考生效，混合阅卷即所有老师可以拿到任意一个学校的试卷，独立阅卷即本校老师提本校的试卷"
                      placement="left-start"
                  >
                    <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']" style="margin-top: 8px"/>
                  </el-tooltip>
                  阅卷模式
                </template>
                <el-radio-group v-model="propData.markingType">
                  <el-radio label="1">混合阅卷</el-radio>
                  <el-radio label="2">独立阅卷</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 新增严格模式配置-->
              <el-form-item>
                <template #label>
                  <el-tooltip
                      content="严格模式下，考场扫描的座位号在扫描时必须从小至大依次递增，否则无法提交"
                      placement="left-start"
                  >
                    <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']" style="margin-top: 8px"/>
                  </el-tooltip>
                  严格模式
                </template>
                <el-radio-group v-model="propData.strict_mode">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

<!--              <el-form-item label="识别方式">-->
<!--                <el-radio-group v-model="propData.scan_prop.recognize_type">-->
<!--                  <el-radio label="1">服务端识别</el-radio>-->
<!--                  <el-radio label="2">扫描端识别</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
              <el-form-item label="模板类型">
                <el-radio-group v-model="propData.scan_prop.template_type">
                  <el-radio label="1">标准</el-radio>
                  <el-radio label="2">非标</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="扫描类型">
                <el-radio-group v-model="propData.scan_prop.scan_type">
                  <el-radio label="1">按考场扫描</el-radio>
                  <el-radio label="2">按学校扫描</el-radio>
                  <el-radio label="3">全局扫描</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="扫描分辨率">
                <el-radio-group v-model="propData.scan_prop.scan_dpi">
                  <el-radio label="100">100dpi</el-radio>
                  <el-radio label="150">150dpi</el-radio>
                  <el-radio label="200">200dpi</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="扫描方式">
                <el-radio-group v-model="propData.scan_prop.scan_way">
                  <el-radio label="1">单面</el-radio>
                  <el-radio label="2">双面</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="扫描颜色">
                <el-radio-group v-model="propData.scan_prop.scan_color">
                  <el-radio label="1">黑白</el-radio>
                  <el-radio label="2">灰度</el-radio>
                  <el-radio label="3">真彩</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="扫描纸张大小">
                <el-radio-group v-model="propData.scan_prop.scan_paper_size">
                  <el-radio label="1">A4</el-radio>
                  <el-radio label="2">A3</el-radio>
                  <el-radio label="3">其他</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="是否有子科目">
                <el-radio-group v-model="propData.has_sub">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="子科目配置" v-show="propData.has_sub === '1'">
                <template #label>
                  <div style="margin-top: 20px">子科目配置</div>
                </template>
                <div style="margin-top: 20px">
                  <div>
                    <el-select v-model="propData.sub_prop_select" style="width: 150px;margin-right: 5px"
                               placeholder="请新增一个科目">
                      <el-option v-for="(item,index) in propData.sub_prop" :key="item.index" :label="item.name"
                                 :value="index"/>
                    </el-select>
                    <el-button siz="default" type="primary" icon="Plus" style="width: 10%" @click="() => {
                  propData.sub_prop.push({name:'子科目'+(propData.sub_prop.length+1)})
                  if(propData.sub_prop.length === 1){
                    propData.sub_prop_select = 0
                  }
                }"></el-button>
                  </div>

                  <div v-if="propData.sub_prop_select !== null">
                    <el-input v-model="propData.sub_prop[propData.sub_prop_select].name" style="width: 100%;">
                      <template #prefix>子科目名称：</template>
                    </el-input>

                    <el-select
                        v-model="propData.sub_prop[propData.sub_prop_select].questionIds"
                        multiple
                        placeholder="请选择题组"
                        style="width: 100%"
                    >
                      <el-option
                          v-for="(question,index) in questionList"
                          :key="question.question_id"
                          :label="question.question_name"
                          :value="question.question_id">
                      </el-option>
                    </el-select>
                    <el-button type="danger" icon="Delete" style="width: 100%" @click="() =>{
                      propData.sub_prop.splice(propData.sub_prop_select,1)
                      if(propData.sub_prop_select === 0){
                        propData.sub_prop_select = null
                      }else {
                        propData.sub_prop_select = propData.sub_prop_select - 1
                      }
                    }">移除此子科目
                    </el-button>
                  </div>

                </div>
              </el-form-item>
            </el-form>
            <div style="text-align: center;">
              <el-button type="primary" @click="toPropSave">保 存</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="题组配置" name="questionTab">
            <el-button siz="default" type="primary" icon="Plus" @click="toQuestionAdd"
                       :disabled="this.subjectPapers.length === 0" style="margin-bottom: 10px;">题组
            </el-button>
            <el-tree
                :data="questionList"
                :props="questionTreeProps"
                node-key="uid"
                default-expand-all
                draggable
                @node-drop="questionDrop"
                :allowDrop="questionAllowDrop"
                :expand-on-click-node="false"
                ref="quesTree">
              <template v-slot="{ node, data }">
                <div v-if="data.level3Flag">
                  {{ data.name }}
                  <span style="color: green"> 【{{ data.min }} - {{ data.max }}】</span>
                </div>
                <div class="paper-row" v-else>
                  <span @click="() => toQuestionEdit(data)">
                    <span
                          style="font-weight: bold;margin-right: 3px"
                          :style="{color: data.content.full_score == 0 ? 'red' : 'var(--el-color-success-light-3)'}"
                    >
                          {{data.content.full_score}}
                        </span>
                      <el-icon>
                    <document/>
                  </el-icon>
                      <span v-if="typeof data.subquestion_name === 'undefined'">
                        {{ data.question_name }}</span>
                      <span v-else>{{
                          data.content != null && data.content.is_multi ? data.subquestion_name + "(多选)" : data.subquestion_name
                        }}</span>
                      <el-tooltip content="区域缺失" placement="top" effect="light" transition="" :enterable="false"
                                  v-if="(typeof data.subquestion_name === 'undefined' && data.question_area.length === 0) || (typeof data.subquestion_name !== 'undefined' && data.subquestion_area.length === 0)">
                      </el-tooltip>

                    </span>
                  <span style="padding-left:10px">
                      <el-tooltip content="新增子题" placement="top" effect="light" transition="" :enterable="false"
                                  v-if="typeof data.subquestion_name === 'undefined' && data.question_type === '2'">
                        <el-button type="primary" size="small" class="tree-button" icon="Plus"
                                   @click="() => toSubquestionAdd(data)"></el-button>
                      </el-tooltip>
                      <el-tooltip content="删除" placement="top" effect="light" transition="" :enterable="false">
                        <el-button type="danger" size="small" class="tree-button" icon="delete"
                                   @click="() => toQuestionDel(data)"></el-button>
                      </el-tooltip>
                    </span>
                </div>
              </template>
            </el-tree>
          </el-tab-pane>
        </el-tabs>

      </el-aside>

    </el-container>

    <el-drawer
        style="position: fixed"
        ref="quesDrawer"
        :title="quesTitle"
        :append-to-body="true"
        direction="rtl"
        v-model="quesDrawerVisi"
        :size="360"
        :modal="false"
        modal-class="drawer"
        @close="drawerClose"
        @opened="() => $refs.quesForm.clearValidate()">
      <el-form :model="quesData" :rules="quesRule" ref="quesForm" size="small" label-width="100px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="quesData.name" style="width: 210px;"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="quesData.type">
            <el-radio label="1">客观题</el-radio>
            <el-radio label="2">主观题</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="视图类型" prop="type" v-if="quesData.type === '2'">
          <el-radio-group v-model="quesData.content.viewType">
            <el-radio label="1">普通视图</el-radio>
            <el-radio label="2">作文视图</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="区域">
          <el-button size="default" :type="quesAreaAddButton" round icon="Plus" @click="toQuestionAreaAdd">区域
          </el-button>
          <span class="el-upload__tip" style="margin-left: 10px;"
                v-if="optMode === 'ques-area-add'">请框选新区域</span>
          <el-tree
              :data="quesData.area"
              default-expand-all
              empty-text=""
              draggable
              :allowDrop="areaAllowDrop"
              @node-drop="areaDrop"
              ref="quesAreaTree">
            <template v-slot="{ node, data }">
                  <span :class="{'area-select': activeArea===data}" @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                  </span>
              <span style="padding-left:5px">
                    <el-tooltip content="删除题组区域" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeQuestionArea(data)"></el-button>
                    </el-tooltip>
                  </span>
            </template>
          </el-tree>
        </el-form-item>
        <el-form-item label="" v-if="quesData.type === '1' && quesData.area.length !== 0">
          <el-button size="default" type="primary" round icon="Plus" @click="toSubquestionBatchAdd">批量添加子题
          </el-button>
        </el-form-item>

        <el-form-item label="识别类型" v-if="quesData.type === '1'">
          <el-select v-model="quesData.content.marktype">
            <el-option
                v-for="item in [
                    {label: '真假', value: '0'},
                    {label: 'ABCDEFG', value: '1'},
                    {label: '0123456', value: '2'},
                ]"
                :key="item"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选项类型" v-if="quesData.type === '1'">
          <el-select v-model="quesData.content.omrfigure">
            <el-option
                v-for="item in [
                    {label: '圆', value: '1'},
                    {label: '方', value: '2'},
                    {label: '开方', value: '3'},
                ]"
                :key="item"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评卷模式" v-if="quesData.type === '2'">
          <el-select v-model="quesData.content.mark_mode" placeholder="请选择">
            <el-option
                v-for="item in markModeList"
                :key="item"
                :label="item.label"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="限制时间" v-if="quesData.type === '2'">
          <el-input v-model.number="quesData.content.limit_time" style="width: 100px;"></el-input>
        </el-form-item>
        <el-form-item label="满分">
          <el-input-number :min="0" v-model="quesData.content.full_score" style="width: 100px;" @change="generateValidScore"></el-input-number>
        </el-form-item>
        <el-form-item label="误差分数"
                      v-if="quesData.type === '2' && (quesData.content.mark_mode.value === '3' || quesData.content.mark_mode.value === '4' || quesData.content.mark_mode.value === '5')">
          <el-input-number :min="0" v-model="quesData.content.score_diff" style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="分数间隔" v-if="quesData.type === '2'">
          <el-input-number :min="0" v-model="quesData.content.score_interval"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="有效分数" prop="validScoreStr" v-if="quesData.type === '2'">
          <el-select
              v-model="quesData.validScoreStr"
              multiple
              filterable
              allow-create
              default-first-option
              :reserve-keyword="false"
              placeholder="请输入分数"
              style="width: 240px"
          >
          </el-select>
        </el-form-item>
        <el-form-item label="快捷键" v-if="quesData.type === '2'">
          <el-popover
              placement="left"
              width="290"
              trigger="click"
          >
            <div style="max-height: 400px; overflow-y: auto;text-align: center;">
              <el-row type="flex" v-for="(item, idx) in quesData.content.shortcuts" :key="idx"
                      style="align-items: baseline; margin-bottom: 5px;">
                <el-col :span="5" style="text-align: right; padding-right: 8px;">快捷键</el-col>
                <el-col :span="4">
                  <el-input v-model="item.key" @blur="() => {
                    if(item.key.length === 1 && item.key.match(/[a-zA-Z]/) === null){
                      item.key = ''
                      $message.warning('请输入a-z的单个字母!')
                    }else if(item.key.length > 1){
                      item.key = ''
                      $message.warning('请输入a-z的单个字母!')
                    }
                  }"></el-input>
                </el-col>
                <el-col :span="4" style="text-align: right; padding-right: 8px;">分值</el-col>
                <el-col :span="8">
                  <el-input-number controls-position="right" :min="0" :max="quesData.content.full_score"
                                   v-model="item.score" style="width: 80px;"></el-input-number>
                </el-col>
                <el-col :span="3" style="padding-left: 8px;">
                  <el-button type="danger" class="tree-button" icon="delete"
                             @click="removeShortcut(idx)"></el-button>
                </el-col>
              </el-row>
              <el-button size="default" type="primary" round icon="Plus" @click="addShortcut">添加快捷键
              </el-button>
            </div>
            <template #reference>
              <el-button size="default" type="primary" round icon="edit">编辑快捷键</el-button>

            </template>

          </el-popover>
        </el-form-item>
        <el-form-item label="自评?" v-if="quesData.type === '2'">
          <el-radio-group v-model="quesData.content.remark_flag" @change="val => {
            if(val){
              quesData.content.remark_interval = 10
            }else {
              quesData.content.remark_interval = null
            }
          }">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="自评卷分差" v-if="quesData.content.remark_flag">
          <el-input-number v-model="quesData.content.self_score_diff"/>
        </el-form-item>

        <el-form-item label="标准卷分差" v-if="quesData.type === '2'">
          <el-input-number v-model="quesData.content.standard_score_diff"/>
        </el-form-item>

        <el-form-item label="自评间隔" v-if="quesData.type === '2' && quesData.content.remark_flag">
          <el-input-number :min="10" v-model="quesData.content.remark_interval"
                           style="width: 100px;"></el-input-number>
          份
        </el-form-item>
        <el-form-item label="选做题?" v-if="quesData.type === '2'">
          <el-radio-group v-model="quesData.content.choose_flag">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：25"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            单选覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.coverage"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：55"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            多选覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.coverage2"
                           style="width: 100px;"></el-input-number>
        </el-form-item>

        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：9"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            单选未覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.uncoverage"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item v-if="quesData.type === '1'">
          <template #label>
            <el-tooltip
                content="推荐：15"
                placement="top-start"
            >
              <i-help theme="multi-color" size="16" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
            </el-tooltip>
            多选未覆盖率
          </template>
          <el-input-number :min="0" :max="100" v-model="quesData.content.uncoverage2"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="识别色深" v-if="quesData.type === '1'">
          <el-input-number :min="0" :max="100" v-model="quesData.content.depth"
                           style="width: 100px;"></el-input-number>
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <el-button @click="$refs.quesDrawer.handleClose()">取 消</el-button>
        <el-button type="primary" @click="toQuestionSave">保 存</el-button>
      </div>
    </el-drawer>
    <el-drawer
        style="position: fixed"
        ref="subDrawer"
        :title="subTitle"
        :append-to-body="true"
        direction="rtl"
        v-model="subDrawerVisi"
        :size="380"
        :modal="false"
        modal-class="drawer"
        @close="drawerClose"
        @opened="() => $refs.subForm.clearValidate()">
      <el-form :model="subData" :rules="subRule" ref="subForm" size="default" label-width="80px">

        <el-form-item label="名称" prop="name">
          <el-input v-model="subData.name" style="width: 210px;"></el-input>
        </el-form-item>
        <el-form-item label="区域" v-show="subData.viewType != '2'">
          <el-button size="default" :type="subAreaAddButton"  :disabled="this.subData.area.length > 0" round icon="Plus" @click="toSubquestionAreaAdd"
                     v-if="subData.type === '2'">区域
          </el-button>
          <span class="el-upload__tip" style="margin-left: 10px;"
                v-if="optMode === 'sub-area-add'">请在题组区域内框选新区域</span>
          <el-tree
              :data="subData.area"
              default-expand-all
              empty-text=""
              draggable
              :allowDrop="areaAllowDrop"
              @node-drop="areaDrop"
              ref="subquesAreaTree">
            <template v-slot="{ node, data }">
              <div class="paper-row"> <span :class="{'area-select': activeArea===data}"
                                            @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + "):(" + data.w + "," + data.h + ")" }}
                  </span>
                <span style="padding-left:5px">
                    <el-tooltip content="删除子题区域" placement="top" effect="light" transition="" :enterable="false"
                                v-if="subData.type === '2'">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeSubquestionArea(data)"></el-button>
                    </el-tooltip>
                  </span></div>

            </template>
          </el-tree>
        </el-form-item>
        <el-form-item label="满分" prop="content.full_score">
          <el-input-number :min="0"
                           v-model="subData.content.full_score"
                           style="width: 100px;"
                           :disabled="subData.type == 1"
          ></el-input-number>
          <el-text v-if="subData.type == 1" type="info">请在客观题答案配置页面修改</el-text>
        </el-form-item>

        <el-form-item label="项" v-show="subData.viewType == '2'" prop="subData.content.list">
          <el-select v-model="subQuestionContentListSelect" style="width: 100px;margin-right: 5px">
            <el-option v-for="(item,index) in subData.content.list" :key="item.index" :label="item.name"
                       :value="index"/>
          </el-select>
          <el-button :type="pointAddButton" icon="Plus" @click="addNewPoint">
            新增项
          </el-button>
        </el-form-item>

        <el-form-item label="项名称" prop="content.start_score"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input v-model="subData.content.list[subQuestionContentListSelect].name"></el-input>
        </el-form-item>
        <el-form-item label="起始分数" prop="content.start_score"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input-number :min="0" v-model="subData.content.list[subQuestionContentListSelect].min"></el-input-number>
        </el-form-item>
        <el-form-item label="结束分数" prop="content.end_score"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input-number :min="0" v-model="subData.content.list[subQuestionContentListSelect].max"></el-input-number>
        </el-form-item>
        <el-form-item label="分数间隔" prop="content.interval"
                      v-if="subQuestionContentListSelect !== null && subData.viewType == '2'">
          <el-input-number :min="0" v-model="subData.content.list[subQuestionContentListSelect].interval"
                           style="width: 100px;"></el-input-number>
        </el-form-item>

        <el-form-item label="打分点" v-show="subData.type === '2' && subData.viewType != '2'">
          <el-button size="default" :type="pointAddButton"
                     round
                     icon="Plus"
                     @click="toSubquestionPointAdd">
            打分点
          </el-button>
          <span class="el-upload__tip" style="margin-left: 10px;"
                v-if="optMode === 'sub-point-add'">请在子题区域内点击添加打分点</span>
          <el-tree
              :data="subData.content.points"
              default-expand-all
              empty-text=""
              draggable
              :allowDrop="areaAllowDrop"
              @node-drop="areaDrop"
              ref="pointTree">
            <template v-slot="{ node, data }">
              <div class="paper-row"><span
                  :class="{'area-select': activePointIdx===subData.content.points.indexOf(data)}"
                  @click="() => selectArea(data)">
                    {{ data.p + "-(" + data.x + "," + data.y + ")" }}
                  </span>
                <span style="padding-left:10px">
                    <el-tooltip content="删除打分点" placement="top" effect="light" transition="" :enterable="false">
                      <el-button type="danger" class="tree-button" icon="delete"
                                 @click="() => removeSubquestionPoint(data)"></el-button>
                    </el-tooltip>
                  </span></div>

            </template>
          </el-tree>
        </el-form-item>
        <el-form-item label="多选题?" v-if="subData.type === '1'">
          <el-radio-group v-model="subData.content.is_multi">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="row-button-center">
        <el-button @click="$refs['subDrawer'].handleClose()">取 消</el-button>
        <el-button type="primary" @click="toSubquestionSave">保 存</el-button>
      </div>
    </el-drawer>
    <el-dialog :title="subDialogTitle" v-model="subDialogVisi" width="90%" class="v-center_dialog"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <div style="display: flex;flex-direction: row;flex-wrap: nowrap;max-height: 600px; min-height: 300px;">
        <div style="width: calc(100% - 400px);min-height: 300px;overflow: auto">
          <canvas ref="optcanvas" style="width: 100%;height: 100%;"></canvas>
        </div>
        <div style="margin: 0; width: 400px; height: 100%;display: flex;flex-direction: row;flex-wrap: wrap">
          <div style="padding-left: 20px;">
            <el-button-group>
              <el-button size="default" @click="optcanvasZoom('zoomin')">
                <el-icon>
                  <zoom-in/>
                </el-icon>
                放大
              </el-button>
              <el-button size="default" @click="optcanvasZoom('zoomout')">
                <el-icon>
                  <zoom-out/>
                </el-icon>
                缩小
              </el-button>
            </el-button-group>
          </div>
          <div style="margin-top: 10px;padding-left: 20px;">
            <el-button-group>
              <el-button size="default" :type="setModelButton" icon="refresh" @click="refindOptions">重新识别选项
              </el-button>
              <el-button size="default" :type="setPersonModelButton" icon="refresh" @click="setModel('person')">人工设置选项
              </el-button>
            </el-button-group>
          </div>
          <div style="margin-top: 10px;padding-left: 20px;" v-show="this.optRecognizeMode==='auto'">
            <el-button-group>
              <el-button size="default" :type="optColDelButton" icon="Close" @click="toOptionColRemove">移除列
              </el-button>
              <el-button size="default" :type="optRowDelButton" icon="Close" @click="toOptionRowRemove">移除行
              </el-button>
            </el-button-group>
          </div>
          <div style="margin-top: 10px;padding-left: 20px;" v-show="this.optRecognizeMode!=='auto'">
            <el-button-group>
              <el-button size="default" :type="optColSetButton" icon="Close" @click="toOptionColSet">人工设定首列
              </el-button>
              <el-button size="default" :type="optRowSetButton" icon="Close" @click="toOptionRowSet">人工设定首行
              </el-button>

            </el-button-group>
            <el-row style="margin-top: 10px;padding-left: 20px;">
              <el-col :span="12">
                选项高度：
                <el-input-number :min="1" :max="50" v-model="rectHeight" style="width: 100px;"></el-input-number>
              </el-col>
              <el-col :span="12">
                选项宽度：
                <el-input-number :min="1" :max="50" v-model="rectWidth" style="width: 100px;"></el-input-number>
              </el-col>
            </el-row>
          </div>

          <div style="margin-top: 10px;padding-left: 20px;" v-show="this.optRecognizeMode!=='auto'">
            <el-button-group>
              <el-button size="default" icon="refresh" @click="toResetColAndRow">重置行列数据
              </el-button>
              <el-button size="default" icon="Expand" @click="toGenerateOptionArea">生成选项
              </el-button>
            </el-button-group>

          </div>
          <div style="margin-top: 10px;padding-left: 20px;" v-show="subDialogMode!=='orm'">
            <el-button-group>
              <el-button size="default" :type="firstOptSwitchButton" icon="collection-tag"
                         @click="toFirstOptSwitch">设置首选项
              </el-button>
              <el-button size="default" :type="setMultiButton" icon="notebook-2" @click="toSetMultiOpt">设置多选题
              </el-button>
            </el-button-group>
          </div>

          <el-form :model="subDialogData" :rules="objRule" ref="objectiveForm" size="default" label-width="180px">
            <el-form-item label="排序类型" prop="direction" v-show="subDialogMode!=='orm'">
              <el-radio-group v-model="subDialogData.direction">
                <el-radio label="1">横向</el-radio>
                <el-radio label="2">纵向</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="开始序号" prop="startnum" v-show="subDialogMode!=='orm'">
              <el-input-number v-model="subDialogData.startnum" :min="1" :step="2"></el-input-number>
            </el-form-item>
            <el-form-item label="识别包容宽度" prop="startnum" v-show="this.optRecognizeMode==='auto'">
              <el-input-number v-model="subDialogData.optMinBlank" :min="1" :step="1"></el-input-number>
            </el-form-item>
            <el-form-item label="识别色深" v-show="this.optRecognizeMode==='auto'">
              <el-input-number v-model="colorDepth" :min="1" :max="100" :step="5"></el-input-number>
            </el-form-item>
          </el-form>

          <div style="margin-top: 10px;padding-left: 20px;" v-show="subDialogMode!=='orm'">
            <el-button-group>
              <el-button size="default" type="primary" icon="notebook-2" @click="generateSubNum">
                <el-icon>
                  <notebook/>
                </el-icon>
                生成子题号
              </el-button>
            </el-button-group>
          </div>
          <div class="row row-button-center">
            <el-button type="primary" @click="batchAddSubquestions">保 存</el-button>
            <el-button @click="closeBatchOptionDialog">关 闭</el-button>
          </div>
        </div>

      </div>
    </el-dialog>

  </el-container>
</template>

<script>
import "@/assets/css/common.css"
import {fabric} from "fabric"
import {useAppStoreWithOut} from "@/store/modules/app";

let cvs = null
let optcvs = null
export default {
  name: "NewManageExamSubjectPaper",
  computed: {
    quesAreaAddButton() {
      return this.optMode === "ques-area-add" ? "success" : "primary"
    },
    subAreaAddButton() {
      return this.optMode === "sub-area-add" ? "success" : "primary"
    },
    pointAddButton() {
      return this.optMode === "sub-point-add" ? "success" : "primary"
    },
    maskAreaAddButton() {
      return this.optMode === "mask-area-add" ? "success" : "primary"
    },
    absentAreaAddButton() {
      return this.optMode === "absent-area-add" ? "success" : "primary"
    },
    codeAreaAddButton() {
      return this.optMode === "code-area-add" ? "success" : "primary"
    },
    optColDelButton() {
      return this.optObjectiveMode === "col-remove" ? "success" : "primary"
    },
    optRowDelButton() {
      return this.optObjectiveMode === "row-remove" ? "success" : "primary"
    },
    firstOptSwitchButton() {
      return this.optObjectiveMode === "first-opt-switch" ? "success" : "primary"
    },
    setMultiButton() {
      return this.optObjectiveMode === "multi-opt-switch" ? "success" : "primary"
    },
    setModelButton() {
      return this.optRecognizeMode === "auto" ? "success" : "primary"
    },
    setPersonModelButton() {
      return this.optRecognizeMode !== "auto" ? "success" : "primary"
    },
    optColSetButton() {
      return this.optRecognizeMode === "col" ? "success" : "primary"
    },
    optRowSetButton() {
      return this.optRecognizeMode === "row" ? "success" : "primary"
    },
  },
  watch: {

    "quesData.validScoreStr"(val) {
      this.validScoreStr = val
      this.quesData.content.valid_scores = this.validScoreRegex.test(val) ?
          this.quesData.content.valid_scores = JSON.parse("[" + val + "]") : []
    },
    "propData.ormarea"(val) {
      let areas = val
      if (areas instanceof Array)
        areas.forEach(area => {
          this.drawOptFabricGroup(area, "orm_subquestion_area", "orm_subquestion_area_mark", this.optColor, "", -1)
        })
      this.setRectAttributes()
    },
    "quesData.content.score_interval":{
      handler(interval){
        this.generateValidScore()
      },
      deep: true
    }
  },

  data() {
    return {
      examID: null,
      cvs: cvs,
      optcvs: null,
      downPoint: null,
      upPoint: null,
      downPos: null,
      downMousePos: null,
      lastMousePos: {x: 0, y: 0},
      maskColor: "#000000",          // 遮盖区域框线颜色：纯黑色，用于遮盖或突显某些区域
      codeColor: "#000000",          // 条码区域框线颜色：纯白色，在黑白背景上都能清晰可见
      quesColor: "#FF0000",            // 题组框线颜色：红色，非常醒目，确保在黑白背景上明显
      subquesColor: "#a0d911",       // 子题框线颜色：绿色，与题组颜色形成对比，易于区分
      optColor: "#a0d911",           // 选项框线颜色：蓝色，在黑白背景上有良好对比度
      optTxtColor: "#ff0000",        // 单选题题号颜色：黄色，突出显示单选题
      optMultiTxtColor: "#1890ff",   // 多选题题号颜色：青色，与单选题号颜色区分开
      firstOptColor: "#c41d7f",      // 首选项框线颜色：紫色，确保与其他框线颜色不同
      multiOptColor: "#2f54eb",      // 多选项框线颜色：橙色，提供与选项框线颜色的区分
      textColor: "#000000",            // 文本颜色：白色，保证在黑色或深色背景上可读
      fontSize: 16,                  // 字体大小：保持为16
      fillColor: "#000000",          // 填充颜色：黑色，在白色背景上填充区域
      selectedFillColor: "#02b6ed",
      //zzq
      areaImageList: [],
      radius: 30,
      circleText: 20,
      zoom: -1,
      optzoom: -1,
      totalWidth: 0,
      totalWidth2: 0,


      polygonStrokeWidth: 2,
      markStrokeWidth: 16,
      selectedTargerOriginalData: {x: 0, y: 0, uid: -1},
      temporaryObjectRecordList: [],
      recordOriginalList: [],
      transparent: "transparent",
      subjectName: "",
      exam: {
        exam_id: null,
        exam_name: "",
        unit_id: null,
        unit_name: "",
        exam_state: "",
        subjects: []
      },
      search: {
        examid: null,
        subjectid: null
      },
      subjectPapers: [],
      paperTreeProps: {
        children: 'subpaper',
        label: 'file_name'
      },
      fitPaper: true, //为true是渲染试卷后自动缩放适应宽度
      paperLoaded: false,
      propLoaded: false,
      questionLoaded: false,
      questionList: [],
      questionTreeProps: {
        children: 'sub_question',
        label: 'question_name'
      },
      questionTreeHeight: 400,
      validAreas: [],
      optMode: "",  //操作模式
      optObjectiveMode: "", //客观题操作模式
      activeMaskIdx: null,  //当前选中遮盖区域索引
      activeCodeIdx: null,  //当前选中条码区域索引
      activeQuesIdx: null,  //当前选中题组索引
      activeSubquesIdx: null, //当前选中子题索引
      activePointIdx: null, //当前选中打分点索引
      activeArea: null,
      paperVisi: false,
      uploadFileList: [],
      activeTab: "paperTab",
      subDialogTitle: "批量添加客观子题",
      propData: {
        examid: null,
        subjectid: null,
        name: "",
        maskarea: [],
        codearea: [],
        strict_mode: '0',
        absentarea: [],
        //1 条形码 2 ORM码
        codeareatype: "1",
        //横向1 纵向2
        codeareadirection: "1",
        markingType: "1", // 阅卷模式 1-混合阅卷，2-独立阅卷
        ormarea: [],
        fillarea: [],
        coverage: 50,
        uncoverage: 9,// 缺考未覆盖率
        absent_omrfigure: "2", //1-圆 2-方，3-开方
        orm_omrfigure: "2", // 1-圆 2-方，3-开方
        orm_coverage: 25,
        orm_uncoverage: 9,
        total_score:0,
        depth: 50,
        scan_prop: {
          scan_type: "1", // 扫描类型： 1-按考场扫描 2-按学校扫描，3-全局扫描
          scan_dpi: "150", // 分辨率：150dpi,100dpi,200dpi
          scan_way: "1", // 扫描方式：1-单面 2-双面
          scan_color: "1", //扫描颜色：1-黑白 2-灰度 3-真彩
          scan_paper_size: "1", // 扫描纸张大小：1-A4 2-A3,3-其他
          student_id_length: 0,
          cut_start: 0,
          cut_end: 0,
          recognize_type: "1", // 识别方式：1-服务端识别 2-扫描端识别
          template_type: "1", // 模板类型：1-标准 2-非标准
        },
        has_sub: "0", // 是否有子科目
        sub_prop: [], // 子科目配置
        sub_prop_select: null, // 选中的子科目
      },
      quesTitle: "题组配置",
      quesDrawerVisi: false,
      subTitle: "子题配置",
      subDrawerVisi: false,
      drawerCloseClear: true,  //关闭抽屉时是否清除选中数据
      subDialogVisi: false,   //批量添加子题弹窗是否显示
      validScoreRegex: /^\d+(.\d+)?(\s*,\s*\d+(.\d+)?)*$/,
      subDialogMode: "",
      colorDepth: 70,
      quesData: {
        quesid: null,
        examid: null,
        subjectid: null,
        name: "",
        type: "",
        area: [],
        validScoreStr: "",
        content: {
          viewType: '1', //1. 普通视图 2.作文视图
          full_score: null,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: "",
          limit_time: 0,
          score_diff: null,
          remark_flag: false,
          remark_interval: null,
          choose_flag: false,
          coverage: 25, // 单选覆盖率
          coverage2: 55, // 扫描仪需要此参数：多选覆盖率
          depth: 70,
          self_score_diff: 1, // 自评分差，用于统计个人一致性
          standard_score_diff: 1, // 标准卷分差，用于统计专家一致性
          marktype: "1", // 扫描仪端需要此参数： 0-真假 1-ABCDEFG 2-0123456
          uncoverage: 9, // 扫描仪端需要此参数:单选未覆盖率
          uncoverage2: 15, // 扫描仪端需要此参数:多选未覆盖率
          omrfigure: "2" // 扫描仪端需要此参数： 1-圆 2-方，3-开方
        }
      },
      subData: {
        viewType: "1",
        subid: null,
        quesid: null,
        examid: null,
        subjectid: null,
        type: "",
        num: null,
        name: "",
        area: [],
        content: {
          full_score: null,
          points: [],
          is_multi: false,
          list: [] // 作文的项
        }
      },
      subQuestionContentListSelect: null, // 选择作文的项
      subDialogData: {
        direction: "2",  //子题方向，1-横向，2-纵向
        startnum: 1,//子题开始序号,
        //选项最小空宽度
        optMinBlank: 1,
      },
      objectiveLayers: [],
      objectiveOptionList: [],
      objectiveAreaList: [],  //弹窗中各区域的范围列表
      markModeList: [
        {
          value: '1',
          label: '1评'
        }, {
          value: '2',
          label: '2评'
        }, {
          value: '3',
          label: '2+1'
        }, {
          value: '4',
          label: '1+1+1'
        }, {
          value: '5',
          label: '3+1'
        }
      ],
      shortCutsPopVisi: false,
      rowPointList: [],
      colPointList: [],
      optRecognizeMode: "auto",
      rectHeight: 10,
      rectWidth: 10,
      isOptionGenerate:false,
      propRule: {
        name: [{required: true, message: "请填写试卷名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }]
      },
      quesRule: {
        name: [{required: true, message: "请填写题组名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }],
        type: [{required: true, message: "请选择题组类型", trigger: "change"}],
        validScoreStr: [{
          pattern: /^\d+(.\d+)?(\s*,\s*\d+(\.\d+)?)*$/,
          message: "有效分数格式必须是：1,2.5,3",
          trigger: "blur"
        }]
      },
      subRule: {
        name: [{required: true, message: "请填写子题名称", trigger: "blur"}, {
          max: 50,
          message: "不能超过50个字",
          trigger: "blur"
        }],
        "content.full_score": [{required: true, message: "请填写满分", trigger: "blur"}]
      },
      objRule: {
        direction: [{required: true, message: "请选择方向类型", trigger: "change"}],
        startnum: [{required: true, message: "请选输入开始序号", trigger: "blur"}]
      },
      lastQuestionParams:{ // 记住上一次添加题组的参数，自动填入
          quesid: null,
          examid: null,
          subjectid: null,
          name: "",
          type: "",
          area: [],
          validScoreStr: "",
          content: {
            viewType: '1', //1. 普通视图 2.作文视图
            full_score: null,
            score_interval: 1,
            valid_scores: [],
            shortcuts: [],
            mark_mode: "",
            limit_time: 0,
            score_diff: null,
            remark_flag: false,
            remark_interval: null,
            choose_flag: false,
            coverage: 25, // 单选覆盖率
            coverage2: 55, // 扫描仪需要此参数：多选覆盖率
            depth: 70,
            self_score_diff: 1, // 自评分差，用于统计个人一致性
            standard_score_diff: 1, // 标准卷分差，用于统计专家一致性
            marktype: "1", // 扫描仪端需要此参数： 0-真假 1-ABCDEFG 2-0123456
            uncoverage: 9, // 扫描仪端需要此参数:单选未覆盖率
            uncoverage2: 15, // 扫描仪端需要此参数:多选未覆盖率
            omrfigure: "2" // 扫描仪端需要此参数： 1-圆 2-方，3-开方
      }
    }
    }
  },
  setup() {
    const appStore = useAppStoreWithOut()
    return {appStore}
  },
  mounted() {
    this.lastQuestionParams.content.mark_mode = this.markModeList[0]
    this.examID = this.appStore.exam_id
    this.search.subjectid = this.appStore.subject_id
    if (this.examID != null) {
      this.examID = Number(this.examID)
      this.search.examid = this.examID
    }
    this.$nextTick(() => {
      window.removeEventListener("resize", this.calcZoom)
      window.addEventListener("resize", this.calcZoom)
      window.removeEventListener("resize", this.calcZoomOpt)
      window.addEventListener("resize", this.calcZoomOpt)
    })
    this.init()
    this.loadExamInfo()

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcZoom)
  },
  //计算相关高度
  methods: {
    generateValidScore(){
      const fullScore = this.quesData.content.full_score
      const interval = this.quesData.content.score_interval
      if (fullScore) {
        // 生成分数范围进quesData.validScoreStr
        const arr = []
        for (let i = 0; i <= parseFloat(fullScore); i++) {
          arr.push(i)
          i += parseFloat(interval) - 1
        }
        this.quesData.validScoreStr = arr
        // 根据arr自动生成常用快捷键
        const ct = this.quesData.content
        ct.shortcuts = []
        // 常用字母数组
        const al = ['Q', 'W', 'E', 'R', 'A', 'S', 'D', 'F']
        for (let i = 0; i < arr.length; i++) {
          if (i < al.length ) ct.shortcuts.push({key: al[i], score: arr[i]})
        }
      }
    },


    addNewPoint() {
      const size = this.subData.content.list.length
      this.subData.content.list.push({name: '项' + size, min: null, max: null, interval: 1, level3Flag: true})
      this.subQuestionContentListSelect = size
    },
    init() {
      if (cvs) {
        cvs.clear(); // 清空画布
        cvs.dispose(); // 释放与画布关联的所有资源
      }
      cvs = new fabric.Canvas(this.$refs.canvas)
      cvs.skipTargetFind = false
      cvs.selectionColor = 'rgba(0,0,0,0.2)'
      cvs.selectionBorderColor = this.quesColor
      cvs.selection = 'selection';
      this.cvs = cvs
      cvs.on('mouse:move', options => {
        this.mouseMove(options)
      })
      cvs.on('mouse:down', options => {
        this.mouseDown(options)
      })
      cvs.on('mouse:up', options => {
        this.mouseUp(options)
      })
    },
    //客观子题画布对象初始化
    optInit() {
      if (optcvs) {
        optcvs.clear(); // 清空画布
        optcvs.dispose(); // 释放与画布关联的所有资源
      }

      optcvs = new fabric.Canvas(this.$refs.optcanvas)
      optcvs.skipTargetFind = false
      optcvs.selectionColor = 'rgba(0,0,0,0.2)'
      optcvs.selectionBorderColor = this.quesColor
      optcvs.selection = 'selection';
      optcvs.on('mouse:move', options => {
        this.mouseMove(options)
      })
      optcvs.on('mouse:down', options => {
        if (this.optRecognizeMode !== 'auto') {
          const pointer = optcvs.getPointer(event.e);
          const x = pointer.x - 10 / 2.0;
          const y = pointer.y - 10 / 2.0;
          console.log({x: x, y: y});

          if (this.optRecognizeMode === 'col') {
            // 列模式：保持 x 坐标一致，更新 y 坐标
            if (this.colPointList.length > 0) {
              let firstX = this.colPointList[0].x;
              this.colPointList.push({x: firstX, y: y, w: 10, h: 10, uid: this.uuid()});
            } else {
              this.colPointList.push({x: x, y: y, w: 10, h: 10, uid: this.uuid()});
            }
          } else if (this.optRecognizeMode === 'row') {
            // 行模式：保持 y 坐标一致，更新 x 坐标
            let firstY = this.colPointList[0].y;
            this.rowPointList.push({x: x, y: firstY, w: 10, h: 10, uid: this.uuid()});

          }
      if (this.optRecognizeMode === 'col'|| this.optRecognizeMode === 'row'){
        // 将列和行的点合并到 objectiveOptionList 中
        this.colPointList.forEach(area => {
          if (!this.objectiveOptionList.find(it => it.uid === area.uid)) {
            this.objectiveOptionList.push(area);
          }
        });
        this.rowPointList.forEach(area => {
          if (!this.objectiveOptionList.find(it => it.uid === area.uid)) {
            this.objectiveOptionList.push(area);
          }
        });
        // 重新渲染画布上的选项
        this.renderPersonObjectiveOption();
      }
        }
      })
      optcvs.on('mouse:up', options => {
        this.objectiveMouseUp(options)
      })

    },
    //更新当前操作对象信息,遍历所有数据找到匹配项进行内容修改。
    updateAreaData(o) {
      let type = o.object_type
      if (type === "question") {
        for (let key in this.quesData) {
          if (this.quesData[key] instanceof Array)
            this.quesData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "subquestion_area") {
        for (let key in this.subData) {
          if (this.subData[key] instanceof Array)
            this.subData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "subquestion_area_point") {
        for (let key in this.subData) {
          if (this.subData[key]?.points instanceof Array)
            this.subData[key].points.filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
            })
        }
      }
      if (type === "opt_subquestion_area") {
        for (let key in this.quesData) {
          if (this.quesData[key] instanceof Array)
            this.quesData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (type === "mask" || type === "code" || type === "absent") {
        for (let key in this.propData) {
          if (this.propData[key] instanceof Array)
            this.propData[key].filter((area) => {
              return area.uid === o.uid
            }).forEach(area => {
              const range = this.getRangeByPaperID(area.p)
              area.x = parseInt(o.left)
              area.y = parseInt(o.top - range.thrange1)
              area.w = parseInt(o.width)
              area.h = parseInt(o.height)
            })
        }
      }
      if (o.selectable === true)
        this.selectArea(o)

    },

    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";

      this.uuidA = s.join("");
      return this.uuidA;
    },
    calcZoom() {
      //获取父元素高宽
      let canvasContainer = cvs.wrapperEl.parentNode;
      let parentWidth = canvasContainer.clientWidth
      //计算缩放比
      let originZoom = 1
      if (cvs.getWidth() > parentWidth) {
        originZoom = 1 / (cvs.getWidth() / parentWidth)
        cvs.setZoom(originZoom)
      }
      this.zoom = cvs.getZoom().toFixed(2)
    },


    calcZoomOpt() {

      if (this.subDialogVisi === true) {
        //获取父元素高宽
        let canvasContainer = optcvs.wrapperEl.parentNode;
        let parentWidth = canvasContainer.clientWidth
        //计算缩放比
        let originZoom = 1
        if (optcvs.getWidth() > parentWidth) {
          originZoom = 1 / (optcvs.getWidth() / parentWidth)
          optcvs.setZoom(originZoom)
        } else {
          optcvs.setWidth(parentWidth)
        }
        if (optcvs.getHeight() < canvasContainer.clientHeight)
          optcvs.setHeight(canvasContainer.clientHeight)
        this.optzoom = optcvs.getZoom().toFixed(2)
        optcvs.setHeight(500)
      }
    },


    //载入考试科目信息
    loadExamInfo() {
      this.axios.post("/api/common/loadexaminfo", {examid: this.examID})
          .then((response) => {
            if (response.data.success) {
              this.exam = response.data.result
              this.subjectName = this.exam.subjects.filter(sub => {
                return sub.subject_id + "" === this.search.subjectid + ""
              })[0]["subject_name"]
              if (this.exam.subjects.length > 0) {
                this.search.examid = this.examID
                this.loadSubjectPaper()
              }
            }
          })
    },
    //载入模板图片
    loadSubjectPaper() {
      this.paperLoaded = false
      this.axios.post("/api/exam/mgeexamsubjectpapers/loadexamsubjectpaper", this.search)
          .then((response) => {
            if (response.data.success) {
              let papers = response.data.result
              papers.forEach(paper => {
                let img = new Image()
                img.src = paper.paper_image
                paper.paper_image = img
              })
              cvs.clear()
              this.subjectPapers = papers
              this.paperLoaded = true
              this.loadSubjectProp()
              this.loadSubjectQuestion()
              this.waitImageLoaded()
            }
          })
    },
    //载入模板图片
    loadSubjectProp() {
      this.propLoaded = false
      this.axios.post("/api/exam/mgeexamsubjectpapers/loadexamsubjectprop", this.search)
          .then((response) => {
            if (response.data.success) {
              let prop = response.data.result
              this.propData.examid = this.search.examid
              this.propData.subjectid = this.search.subjectid
              if (prop != null) {
                this.propData.markingType =prop.marking_type
                this.propData.name = prop.paper_name
                this.propData.ormarea = prop.orm_area
                this.propData.codeareatype = prop.code_type
                this.propData.codeareadirection = prop.code_direction
                this.propData.maskarea = prop.mask_area == null ? [] : prop.mask_area
                this.propData.codearea = prop.code_area == null ? [] : prop.code_area
                this.propData.fillarea = prop.fill_area == null ? [] : prop.fill_area
                this.propData.absentarea = prop.absent_area == null ? [] : prop.absent_area
                this.propData.depth = parseInt(prop.absent_area_depth)
                this.propData.coverage = parseInt(prop.absent_area_rate)
                this.propData.scan_prop = prop.scan_prop
                this.propData.has_sub = prop.has_sub
                this.propData.uncoverage = prop.uncoverage ? prop.uncoverage : 9
                this.propData.sub_prop = prop.sub_prop === null ? [] : prop.sub_prop
                this.propData.absent_omrfigure = prop.absent_omrfigure ? prop.absent_omrfigure : "2"
                this.propData.orm_omrfigure = prop.orm_omrfigure ? prop.orm_omrfigure : "2"
                this.propData.orm_coverage = prop.orm_coverage ? prop.orm_coverage : 25
                this.propData.orm_uncoverage = prop.orm_uncoverage ? prop.orm_uncoverage : 9
                this.propData.total_score = prop.total_score ? prop.total_score : 0
                this.propData.strict_mode = prop.strict_mode

                if (this.propData.has_sub === "1" && prop.sub_prop.length !== 0) {
                  this.propData.sub_prop_select = 0
                }
                this.addQuestionUUID(this.propData.maskarea)
                this.addQuestionUUID(this.propData.codearea)
                this.addQuestionUUID(this.propData.fillarea)
                this.addQuestionUUID(this.propData.absentarea)

              } else {
                this.propData.name = ""
                this.propData.maskarea = []
                this.propData.codearea = []
                this.propData.fillarea = []
                this.propData.absentarea = []

              }
              this.propLoaded = true
            }
          })
    },
    //载入题组配置
    loadSubjectQuestion(redraw) {
      this.questionLoaded = false
      this.optMode = ""
      this.activeArea = null
      this.activeQuesIdx = null
      this.activeSubquesIdx = null
      this.activePointIdx = null
      this.axios.post("/api/exam/mgeexamsubjectpapers/loadexamsubjectquestion", this.search)
          .then((response) => {
            if (response.data.success) {
              let qlist = response.data.result
              this.addQuestionUniID(qlist)
              this.questionList = qlist

              // 给每个子题都添加父题的viewType
              this.questionList.forEach((ques) => {
                const type = ques.content.viewType
                ques.sub_question.forEach((subQues) => {
                  subQues["viewType"] = type
                  if (subQues["viewType"] == "2") {
                    subQues["sub_question"] = subQues.content.list
                    subQues.content.list ? subQues.content.list.forEach((item) => {
                      item["level3Flag"] = true
                    }) : ''
                  }
                })
              })
              this.questionLoaded = true
              if (redraw === true)
                this.removeFabricObjectByType(['question_area', 'question', 'subquestion_area', 'subquestion_area_point', 'opt_subquestion_area'])
              this.drawQuestion()
            }
            if (this.subjectPapers.length > 0 && this.questionList.length > 0)
              this.activeTab = "questionTab"
            else if (this.activeTab === "questionTab")
              this.activeTab = "paperTab"

            this.setRectAttributes()
          })
    },
    //对遮盖填涂区域生成唯一ID
    addQuestionUUID(list) {
      list.forEach(data => {
        data.uid = this.uuid()
      })
    },
    //对题组和子题生成唯一ID
    addQuestionUniID(list) {
      list.forEach(ques => {
        ques.uid = this.uuid()
        if (typeof ques.sub_question !== "undefined") {
          ques.question_area.forEach(area => {
            area.uid = this.uuid()
          })
          ques.sub_question.forEach(sub => {
            sub.uid = this.uuid()
            sub.subquestion_area.forEach(sub2 => {
              sub2.uid = this.uuid()
            })
            if (sub.content.points instanceof Array) {
              sub.content.points.forEach((point) => {
                point.uid = this.uuid()
              })
            }
          })
        }
      })
    },

    //选择科目重新加载
    selectSubject() {
      if (this.search.subjectid != null) {
        cvs.clear()
        this.subjectPapers = []
        this.questionList = []
        this.propData = {
          examid: null,
          subjectid: null,
          name: "",
          maskarea: [],
          codearea: [],
          fillarea: []
        }
        this.optMode = ""
        this.activeArea = null
        this.validAreas = []
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
        this.loadSubjectPaper()
      }
    },
    //模板图片上传成功以后执行方法
    uploadResult(response) {
      if (response.success) {
        this.loadSubjectPaper()
      } else
        this.$message.error(response.result)
      this.$refs.paperUpload.clearFiles()
    },
    //模板图片上传出错以后执行方法
    uploadError(error) {
      this.$message.error(error.message)
    },
    //等待全部试卷图片加载完成
    waitImageLoaded() {
      let allLoaded = true
      this.subjectPapers.forEach(paper => {
        if (!paper.paper_image.complete)
          allLoaded = false
      })
      if (!allLoaded || !this.paperLoaded || !this.propLoaded || !this.questionLoaded)
        setTimeout(this.waitImageLoaded, 33)
      else {
        this.renderSubject()
        if (this.subjectPapers.length > 0 && this.questionList.length > 0)
          this.activeTab = "questionTab"
        else if (this.activeTab === "questionTab")
          this.activeTab = "paperTab"
      }
    },
    //保存试卷配置
    toPropSave() {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          if (this.propData.codeareatype === "1") {
            this.propData.ormarea.forEach(area => {
              this.removeFabricObjectByUid(area)
            })
            this.propData.ormarea = []
          }
          if (this.propData.sub_prop.length > 0) {
            this.propData.sub_prop.forEach((item, idx) => {
              item['index'] = idx
            })
          }
          this.axios.post("/api/exam/mgeexamsubjectpapers/savesubjectprop", this.propData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "试卷配置保存成功", type: "success"})
                }
              })

        } else
          return valid
      })
    },
    canvasZoom(model) {
      // 获取画布当前缩放值
      let zoom = cvs.getZoom()
      if (0.1 <= zoom <= 2.0)
        if (model === "zoomout") {
          zoom -= 0.1
        } else {
          zoom += 0.1
        }
      // 控制缩放范围在 0.01~20 的区间内
      if (zoom > 2) zoom = 2
      if (zoom < 0.1) zoom = 0.1
      // 设置画布缩放比例
      cvs.setZoom(zoom)
      this.zoom = zoom.toFixed(2)
    },
    optcanvasZoom(model) {
      // 获取画布当前缩放值
      let zoom = optcvs.getZoom()
      if (0.1 <= zoom <= 2.0)
        if (model === "zoomout") {
          zoom -= 0.1
        } else {
          zoom += 0.1
        }
      // 控制缩放范围在 0.01~20 的区间内
      if (zoom > 2) zoom = 2
      if (zoom < 0.1) zoom = 0.1
      // 设置画布缩放比例
      optcvs.setZoom(zoom)
    },

    getPaperRange() {
      //tw = 0, th = 0, 为总体有效范围，thrange1 = 0,thrange2 = 0为当前点击页有效范围
      let tw = 0, th = 0, paperIndex = -1, thrange1 = 0, thrange2 = 0
      this.subjectPapers.forEach((paper, idx) => {
        if (paper.paper_image.width > tw)
          tw = paper.paper_image.width
        th += paper.paper_image.height
        if (paperIndex === -1) {
          if ((th - paper.paper_image.height) <= this.downPoint.y && this.downPoint.y < th) {
            thrange1 = th - paper.paper_image.height
            thrange2 = th
            paperIndex = idx
          }
        }

      })
      return {tw: tw, th: th, p: paperIndex, thrange1: thrange1, thrange2: thrange2}
    },
    getRangeByPaperID(pid) {
      let th = 0, thrange1 = 0, thrange2 = 0, width = 0, height = 0
      this.subjectPapers.forEach((paper, idx) => {
        th += paper.paper_image.height
        if (idx === pid) {
          width = paper.paper_image.width
          height = paper.paper_image.height
          thrange1 = th - paper.paper_image.height
          thrange2 = th
        }
      })
      return {thrange1: thrange1, thrange2: thrange2, width: width, height: height}
    },
    renderSubject() {
      //cvs.clear()
      if (this.subjectPapers.length > 0) {
        let tw = 0, th = 0;
        this.subjectPapers.forEach(paper => {
          if (paper.paper_image.width > tw)
            tw = paper.paper_image.width
          th += paper.paper_image.height
        })
        cvs.setWidth(tw)
        cvs.setHeight(th)


        let ch = 0
        this.subjectPapers.forEach(paper => {
          let imgInstance = new fabric.Image(paper.paper_image, {
            top: ch,
            left: 0,
            width: paper.paper_image.width,
            height: paper.paper_image.height,
            stroke: this.multiOptColor,
            strokeWidth: 2,
            hoverCursor: "auto",
            selectable: false,
            evented: false,
          })
          ch += paper.paper_image.height
          cvs.add(imgInstance)
          imgInstance.sendToBack()
        })
        this.calcZoom()
      }
      this.renderPaperConfigure()
    },

    mouseMove(options) {
      let positon = options.pointer
      //    console.log(`x轴坐标: ${positon.x}; y轴坐标: ${positon.y}`)
    },

    //对试卷配置区域进行绘画
    renderPaperConfigure() {
      if (this.propData.maskarea.length > 0) {
        const areaList = this.propData.maskarea
        areaList.forEach((area, idx) => {
          let title = "遮盖区域" + (idx + 1)
          this.drawFabricGroup(area, "mask", this.maskColor, title)
        })
      }
      if (this.propData.codearea.length > 0) {
        const areaList = this.propData.codearea
        areaList.forEach((area, idx) => {
          let title = "条码区域" + (idx + 1)
          this.drawFabricGroup(area, "code", this.codeColor, title)
        })
      }

      if (this.propData.absentarea.length > 0) {
        const areaList = this.propData.absentarea
        areaList.forEach((area, idx) => {
          let title = "缺考区域" + (idx + 1)
          this.drawFabricGroup(area, "absent", this.codeColor, title)
        })
      }


      this.setRectAttributes()
    },
    renderPaperConfigureUnadd() {
      this.optMode = ""
      if (this.propData.maskarea.length > 0) {
        const areaList = this.propData.maskarea
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            let title = "遮盖区域" + (idx + 1)
            this.drawFabricGroup(area, "mask", this.maskColor, title)
          }
        })
      }
      if (this.propData.codearea.length > 0) {
        const areaList = this.propData.codearea
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            let title = "条码区域" + (idx + 1)
            this.drawFabricGroup(area, "code", this.codeColor, title)
          }
        })
      }
      if (this.propData.absentarea.length > 0) {
        const areaList = this.propData.absentarea
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            let title = "缺考区域" + (idx + 1)
            this.drawFabricGroup(area, "absent", this.maskColor, title)
          }
        })
      }
      if (this.quesData.area.length > 0) {
        const areaList = this.quesData.area
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            if (this.quesData.name === undefined)
              this.quesData.name = ""
            let title = this.quesData.name + "-" + (idx + 1)
            this.drawFabricGroup(area, "question", this.quesColor, title)
          }
        })
      }
      if (this.subData.area.length > 0) {
        const areaList = this.subData.area
        areaList.forEach((area, idx) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            if (this.subData.name === undefined)
              this.subData.name = ""
            let title = this.subData.name + "-" + (idx + 1)
            this.drawFabricGroup(area, "subquestion_area", this.subquesColor, title)
          }
        })
      }
      if (this.subData.content.points.length > 0) {
        const areaList = this.subData.content.points
        areaList.forEach((area) => {
          if (!('uid' in area)) {
            area.uid = this.uuid()
            this.drawPointFabricGroup(area, "subquestion_area_point")
          }
        })
      }
     this.setRectAttributes()
    },
    optcvsRemoveObjectByType(type) {
      optcvs.getObjects().filter((it) => {
        return (it instanceof Object && it.object_type === type)
      }).forEach(object => {
        optcvs.remove(object)
      })
      optcvs.renderAll()
    },
    drawOptFabricGroup(area, type, mark, stroke, title, idx) {
      const maskarea = new fabric.Rect({
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        width: area.w,
        height: area.h,
        fill: this.transparent,
        object_type: type,
        lockRotation: true,
        uid: area.uid,
        stroke: stroke,
        strokeWidth: this.polygonStrokeWidth
      })
      if (idx === 0) {
        const text = new fabric.Text(title, {
          top: area.y + this.getRangeByPaperID(area.p).thrange1 - this.fontSize / 2,
          left: area.x - area.w / 2 - this.fontSize / 2,
          uid: area.uid,
          fill: this.optTxtColor,
          object_type: mark,
          lockRotation: true,
          fontSize: this.fontSize + 8,
        })
        cvs.add(text)
      }
      cvs.add(maskarea)

      cvs.renderAll()

    },
    // eslint-disable-next-line no-unused-vars
    drawOptFabricGroup2(area, type, mark, stroke, title, idx) {
      const maskarea = new fabric.Rect({
        top: area.y,
        left: area.x,
        width: area.w,
        height: area.h,
        fill: this.transparent,
        object_type: type,
        lockRotation: true,
        uid: area.uid,
        stroke: stroke,
        strokeWidth: this.polygonStrokeWidth
      })
      optcvs.add(maskarea)
      optcvs.renderAll()
    },
    drawSubNum(area, type) {
      const text = new fabric.Text(area.sq.toString(), {
        top: area.y,
        left: area.x - area.w / 2 - this.fontSize / 2,
        uid: area.uid,
        fill: this.optTxtColor,
        object_type: type,
        lockRotation: true,
        fontSize: this.fontSize + 8,
      })
      optcvs.add(text)
      optcvs.renderAll()
    },
    drawFabricGroup(area, type, stroke, title) {
      const maskarea = new fabric.Rect({
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        width: area.w,
        height: area.h,
        fill: this.transparent,
        object_type: type,
        lockRotation: true,
        uid: area.uid,
        stroke: stroke,
        strokeWidth: this.polygonStrokeWidth,

      })
      const text = new fabric.Text(title, {
        top: area.y + this.getRangeByPaperID(area.p).thrange1-this.fontSize,
        left: area.x,
        uid: area.uid,
        fill: this.textColor,
        object_type: type,
        lockRotation: true,
        fontSize: this.fontSize,


      })
      const group = new fabric.Group([maskarea], {
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        uid: area.uid,
        object_type: type,
        lockRotation: true,
        hasBorders: true,
        cornerSize: 5,

      })
      cvs.add(group)
      cvs.add(text)
      cvs.renderAll()

    },
    drawPointFabricGroup(area, type) {
      // 创建黄色的圆
      var circle = new fabric.Circle({
        radius: this.radius,
        fill: 'yellow',
        object_type: type,
        originX: 'center',
        originY: 'center',
        uid: area.uid,
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        scalable: false,
        hasControls: false,  // 禁用控制点
        lockScalingX: true,  // 禁用 X 方向的缩放
        lockScalingY: true   // 禁用 Y 方向的缩放
      });

      // 创建灰色的M字母
      var textM = new fabric.Text('M', {
        fontSize: this.circleText,
        fill: 'gray',
        object_type: type,
        originX: 'center',
        originY: 'center',
        uid: area.uid,
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        scalable: false,
        hasControls: false,  // 禁用控制点
        lockScalingX: true,  // 禁用 X 方向的缩放
        lockScalingY: true   // 禁用 Y 方向的缩放

      });
      const group = new fabric.Group([circle, textM], {
        top: area.y + this.getRangeByPaperID(area.p).thrange1,
        left: area.x,
        uid: area.uid,
        object_type: type,
        lockRotation: true,
        hasBorders: true,
        scalable: false,
        hasControls: false,  // 禁用控制点
        lockScalingX: true,  // 禁用 X 方向的缩放
        lockScalingY: true   // 禁用 Y 方向的缩放

      })
      cvs.add(group)
      cvs.renderAll()
    },
    drawQuestion() {
      this.questionList.forEach((ques) => {
        ques.question_area.forEach((area, idx2) => {
          let title = ques.question_name + "-" + "区域" + (idx2 + 1)
          this.drawFabricGroup(area, "question", this.quesColor, title)
        })
        ques.sub_question.forEach((sub) => {
          sub.subquestion_area.forEach((area, idx) => {
            if (ques.question_type === "1")
              this.drawOptFabricGroup(area, "opt_subquestion_area", "subquestion_area_mark", this.optColor, sub.subquestion_name, idx)
            else {
              this.drawFabricGroup(area, "subquestion_area", this.subquesColor, sub.subquestion_name, idx)
            }
          })
          if (sub.content.points instanceof Array) {
            sub.content.points.forEach((point) => {
              this.drawPointFabricGroup(point, "subquestion_area_point")
            })
          }

        })
      })
      this.setRectAttributes()
    },

    //设置画布内对象属性
    setRectAttributes() {
      cvs.getObjects().forEach(object => {
        if (!(object instanceof fabric.Image)) {
          if (object instanceof fabric.Group || object instanceof fabric.Rect){
            object.selectable = false
            object.hoverCursor = "default"
            object.hasBorders = false
            object.hasControls = false
          }else if (object instanceof fabric.Text){
            object.fill = this.fillColor
            object.selectable = false
            object.hoverCursor = "default"
            object.hasBorders = false
            object.hasControls = false
          }

        }
      })
      cvs.requestRenderAll()
      cvs.setZoom(cvs.getZoom() - 0.000000001)
      cvs.setZoom(cvs.getZoom() - 0.000000001)

      this.makeRectInValidArea()
    },
    //让绘画矩形在合理范围之内
    makeRectInValidArea() {
      // 作用在矩形的事件：松开鼠标
      cvs.forEachObject(function (obj) {
        obj.off('mouseup');
      });
      cvs.getObjects().forEach(object => {
        if (object instanceof fabric.Group || object instanceof fabric.Rect) {
          object.on('mouseup', ev => {
            this.calclateValidRect(ev)
          })
        }
      })
    },
    calclateValidRect(ev, rect) {
      let objBoundingRect = {
        left: 0,
        top: 0,
        height: 0,
        width: 0,
        valid: false
      }
      // 获取画布视口边界
      let canvasBoundaries = {tl: {x: 0, y: 0}, tr: {x: 0, y: 0}, bl: {x: 0, y: 0}, br: {x: 0, y: 0}}
      //获取有效范围，将画布边界替换为图片边界
      let range = this.getPaperRange()
      //  tl,tr,bl,br.
      canvasBoundaries.tl.y = range.thrange1
      canvasBoundaries.tr.y = range.thrange1
      canvasBoundaries.tr.x = range.tw
      canvasBoundaries.bl.y = range.thrange2
      canvasBoundaries.br.y = range.thrange2
      canvasBoundaries.br.x = range.tw
      if (ev) {
        const target = ev.target
        if ("object_type" in target && (target.object_type === "subquestion_area" || target.object_type === "subquestion_area_point") && target.selectable === true) {
          //找出其所属的父级区域,对子题区域进行处理
          let parent = this.getValidAreasByObjectType(this.selectedTargerOriginalData, target.object_type)
          if (parent !== null) {
            canvasBoundaries.tl.y = parent.top
            canvasBoundaries.tl.x = parent.left
            canvasBoundaries.tr.y = parent.top
            canvasBoundaries.tr.x = parent.left + parent.width
            canvasBoundaries.bl.x = parent.left
            canvasBoundaries.bl.y = parent.top + parent.height
            canvasBoundaries.br.x = parent.left + parent.width
            canvasBoundaries.br.y = parent.top + parent.height
          }
        }

        if (target.scaleY !== 1 || target.scaleX !== 1) {
          if (target.left < 0)
            target.left = 0
          if (target.top < 0)
            target.top = 0
          const range2 = this.getRangeByPaperID(range.p)
          if (target.left + target.width * target.scaleX > range2.width) {
            target.width = range2.width - target.left
          } else {
            target.width = target.width * target.scaleX
          }
          if (target.top + target.height * target.scaleY > range2.height) {
            target.height = range2.height - target.top
          } else {
            target.height = target.height * target.scaleY
          }
          objBoundingRect = {
            left: target.left,
            top: target.top,
            height: target.height,
            width: target.width
          }
          let title = cvs.getObjects().filter((it) => {
            return it instanceof fabric.Text && it.uid === target.uid
          })[0].text
          let stroke = ""
          if (target.getObjects().filter((it) => {
            return it instanceof fabric.Rect;
          }).length > 0) {
            stroke = target.getObjects().filter((it) => {
              return it instanceof fabric.Rect;
            })[0].stroke
          }

          const type = target.object_type
          const area = {
            y: target.top,
            x: target.left,
            w: target.width,
            h: target.height,
            uid: target.uid,
            p: this.getPaperRange().p
          }
          cvs.remove(target)
          cvs.renderAll()
          if (type === "opt_subquestion_area") {
            this.drawOptFabricGroup(area, type, stroke, title)
          } else if (type === "subquestion_area_point") {
            this.drawPointFabricGroup(area, type)
          } else {
            this.drawFabricGroup(area, type, stroke, title)
          }
          this.selectArea(area)
          this.makeRectInValidArea()
          cvs.renderAll()
        } else {
          objBoundingRect = {
            left: target.left,
            top: target.top,
            height: target.height,
            width: target.width
          }
        }
      } else {
        objBoundingRect = {left: rect.left, top: rect.top, height: rect.height, width: rect.width}
      }
      // 矩形的边界
      if (objBoundingRect.left < canvasBoundaries.tl.x) {
        objBoundingRect.left = canvasBoundaries.tl.x
      }
      if (objBoundingRect.left + objBoundingRect.width > canvasBoundaries.br.x) {
        objBoundingRect.left = canvasBoundaries.br.x - objBoundingRect.width
      }
      if (objBoundingRect.top < canvasBoundaries.tl.y) {
        objBoundingRect.top = canvasBoundaries.tl.y
      }
      if (objBoundingRect.top + objBoundingRect.height > canvasBoundaries.br.y) {
        objBoundingRect.top = canvasBoundaries.br.y - objBoundingRect.height
      }
      if (ev) {
        const target = ev.target
        target.top = objBoundingRect.top
        target.left = objBoundingRect.left
        target.width = objBoundingRect.width
        target.height = objBoundingRect.height
        this.updateAreaData(ev.target)
        // 刷新画布
        cvs.renderAll()
      }
      return objBoundingRect

    },

    selectArea(data) {
      this.activeArea = data
      const uid = data.uid
      let scroll = document.getElementById("mainscroll")
      cvs.getObjects().forEach(object => {
        if (!(object instanceof fabric.Image)) {
          //这里专门针对选中单选框做处理
          if (object instanceof fabric.Rect && 'object_type' in object) {
            if (object.object_type === "opt_subquestion_area" || object.object_type === "orm_subquestion_area") {
              if (object.uid === uid) {
                object.fill = this.subquesColor
              } else {
                object.fill = this.transparent
              }
            }
          }
          if (object instanceof fabric.Group) {
            if (object.uid === uid) {
              if (object.top * cvs.getZoom() > scroll.clientHeight || scroll.scrollTop > object.top * cvs.getZoom())
                scroll.scrollTop = object.top * cvs.getZoom()
              if (object.top * cvs.getZoom() > scroll.clientWidth || scroll.scrollLeft > object.left * cvs.getZoom())
                scroll.scrollLeft = object.left * cvs.getZoom() / 2

              object.selectable = true
              object.hoverCursor = "move"
              object.hasBorders = true
              object.hasControls = true
              object.transparentCorners = false
              object.cornerColor = this.multiOptColor
              //如果该子题已经存在定位点，则selectable=false
              if (object.object_type === "subquestion_area" && this.subData.content.points.length !== 0) {
                object.selectable = false
                object.hoverCursor = "default"
              }

              cvs.getObjects().filter((it) => {
                return it instanceof fabric.Text && it.uid === object.uid
              }).forEach(textobject => {
                if (textobject.uid === uid) {
                  textobject.left = object.left
                  textobject.top = object.top-this.fontSize
                  textobject.fill = this.selectedFillColor
                }
              })
            } else {

              object.selectable = false
              object.hoverCursor = "default"
              object.hasBorders = false
              object.hasControls = false

              cvs.getObjects().filter((it) => {
                return it instanceof fabric.Text && it.uid === object.uid
              }).forEach(object => {
                object.selectable = false
                object.fill = this.fillColor
              })
            }
          }

        }
      })
      cvs.renderAll()
      cvs.setZoom(cvs.getZoom() - 0.000000001)
    },
    removeMaskArea(data) {
      const idx = this.propData.maskarea.indexOf(data)
      this.propData.maskarea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeAbsentArea(data) {
      const idx = this.propData.absentarea.indexOf(data)
      this.propData.absentarea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeCodeArea(data) {
      const idx = this.propData.codearea.indexOf(data)
      this.propData.codearea.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeQuestionArea(data) {
      const idx = this.quesData.area.indexOf(data)
      this.quesData.area.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    removeFabricObjectByUid(data) {
      let objects = cvs.getObjects()
      objects.forEach(obj => {
        if ("uid" in obj && obj.uid === data.uid) {
          cvs.remove(obj)
        }
      })
    },
    removeFabricObjectByType(typelist) {
      const objects = cvs.getObjects()
      if (typelist instanceof Array) {
        typelist.forEach(type => {
          objects.forEach(obj => {
            if ('object_type' in obj && obj.object_type === type) {
              cvs.remove(obj)
            }
          })
        })
      }
    },
    mouseDown(options) {
      this.downPoint = cvs.getPointer(options.e)
      if (options.target) {
        if ("uid" in options.target
            && "selectable" in options.target
            && options.target.selectable ===true
            && options.target.uid !== this.selectedTargerOriginalData.uid) {
          let target = null
          //通过键值对questionList进行遍历找到其中uid与options.target.uid相同的对象
          this.questionList.forEach((item) => {
            if (target === null)
              target = this.findObjectByUID(item, options.target.uid)
          })
          //如果没找到，则其数据在新增状态，还未在questionList中
          if (target === null) {
            target = options.target
            this.selectedTargerOriginalData.x = target.left
            this.selectedTargerOriginalData.y = target.top
            this.selectedTargerOriginalData.uid = target.uid
          } else {
            this.selectedTargerOriginalData.x = target.x
            this.selectedTargerOriginalData.y = target.y+this.getRangeByPaperID(target.p).thrange1
            this.selectedTargerOriginalData.uid = target.uid
          }
          console.log(JSON.stringify(this.selectedTargerOriginalData))
        }

      }
    },
    mouseUp(options) {
      this.upPoint = cvs.getPointer(options.e)
      // 调用 创建矩形 的方法
      if (this.optMode === "ques-area-add"
          || this.optMode === "sub-area-add"
          || this.optMode === "sub-point-add"
          || this.optMode === "mask-area-add"
          || this.optMode === "absent-area-add"
          || this.optMode === "code-area-add") {
        if (this.optMode==="ques-area-add"&&this.quesData.type==="1"&&this.quesData.area.length===1)
        {
          this.$message.warning("客观题只能有一个区域")
          return
        }
        if (this.optMode === "sub-area-add" || this.optMode === "sub-point-add") {

          let valid = this.calculateSubquestionOrPointValidArea()
          if (valid)
            this.drawerRect()
        } else {
          this.drawerRect()
        }
      }
    },
    findObjectByUID(jsonObject, targetUID) {

      let foundObject = null

      function recursiveTraversal(obj) {
        if (obj instanceof Object) {
          if ("uid" in obj && obj.uid === targetUID) {
            foundObject = obj;
          }
          for (let key in obj) {
            if (obj[key] instanceof Array) {
              obj[key].forEach((item) => {
                recursiveTraversal(item)
              })
            } else {
              recursiveTraversal(obj[key])
            }
          }
        }
      }

      recursiveTraversal(jsonObject)
      return foundObject
    },

    drawerRect() {
// 如果点击和松开鼠标，都是在同一个坐标点，不会生成矩形
      if (JSON.stringify(this.downPoint) === JSON.stringify(this.upPoint) && this.optMode !== "sub-point-add") {
        return
      }
      // 创建矩形
      // 矩形参数计算（前面总结的4条公式）
      let top = Math.min(this.downPoint.y, this.upPoint.y)
      let left = Math.min(this.downPoint.x, this.upPoint.x)
      let width = Math.abs(this.downPoint.x - this.upPoint.x)
      let height = Math.abs(this.downPoint.y - this.upPoint.y)
      //需要对矩形范围进行判断，确保其在有效区域内
      let valid = true
      //获取有效范围，将画布边界替换为图片边界
      let range = this.getPaperRange()
      if (top > range.th || left > range.tw || top + height > range.th || left + width > range.tw) {
        valid = false
        this.$messageBox.alert("框选超界，请在有效区域内进行框选！")
      }
      //当前矩形范围只能在当前点击页面，根据mouseDown来获取范围
      let rect = {top: top, left: left, width: width, height: height}
      const targetRect = {
        x: parseInt(rect.left),
        y: parseInt(rect.top - this.getRangeByPaperID(range.p).thrange1),
        p: range.p,
        w: parseInt(rect.width),
        h: parseInt(rect.height)
      }
      if (valid) {
        switch (this.optMode) {
          case "mask-area-add": {
            this.propData.maskarea.push(targetRect)
          }
            break
          case "absent-area-add": {
            this.propData.absentarea.push(targetRect)
          }
            break
          case "code-area-add": {
            this.propData.codearea.push(targetRect)
          }
            break
          case "ques-area-add": {
            this.quesData.area.push(targetRect)
          }
            break
          case "sub-area-add": {
            this.subData.area.push(targetRect)
            //绘制子题区域时，自动绘制一个打分点
            let point = {
              x: targetRect.x+15,
              y: targetRect.y+15,
              p: targetRect.p,
              w: 0,
              h: 0
            }
            this.subData.content.points.push(point)
          }
            break
          case "sub-point-add": {
            this.subData.content.points.push(targetRect)
          }
            break
          default:
            "";
        }
        this.renderPaperConfigureUnadd()
        this.optMode = ""
      }

    },
    //新增信息遮盖区域
    toAreaAdd(type) {
      this.selectArea({"uid":-9999})
      this.optMode = type
      if (this.quesDrawerVisi || this.subDrawerVisi) {
        this.quesDrawerVisi = false
        this.subDrawerVisi = false
      }
    }
    ,
    //关闭右边抽屉
    drawerClose() {
      this.loadSubjectQuestion(true)
      this.quesTitle = "题组配置"
      this.subTitle = "子题配置"
      if (this.drawerCloseClear) {
        this.optMode = ""
        this.activeArea = null
        this.activeQuesIdx = null
        this.activeSubquesIdx = null
        this.activePointIdx = null
        this.validAreas = []
      }
    },
    //新增题组
    toQuestionAdd() {
      this.quesData = {
        quesid: null,
        examid: this.search.examid,
        subjectid: this.search.subjectid,
        name: "",
        type: "2", // 默认主观题
        area: [],
        validScoreStr: "",
        content: {
          viewType: this.lastQuestionParams.content.viewType,
          full_score: 0,
          score_interval: 1,
          valid_scores: [],
          shortcuts: [],
          mark_mode: this.lastQuestionParams.content.mark_mode,
          limit_time: this.lastQuestionParams.content.limit_time,
          score_diff: this.lastQuestionParams.content.score_diff,
          remark_flag: this.lastQuestionParams.content.remark_flag,
          remark_interval: this.lastQuestionParams.content.remark_interval,
          choose_flag: this.lastQuestionParams.content.choose_flag,
          coverage: this.lastQuestionParams.content.coverage,
          coverage2: this.lastQuestionParams.content.coverage2,
          depth: this.lastQuestionParams.content.depth,
          self_score_diff: this.lastQuestionParams.content.self_score_diff,
          marktype: this.lastQuestionParams.content.marktype,
          uncoverage: this.lastQuestionParams.content.uncoverage,
          uncoverage2: this.lastQuestionParams.content.uncoverage2,
          omrfigure: this.lastQuestionParams.content.omrfigure
        }
      }
      this.quesTitle = "新增题组"
      this.quesDrawerVisi = true
    },
    //编辑题组
    toQuestionEdit(data) {
      if (typeof data.subquestion_name === "undefined") { //编辑题组
        const quesData = this.quesData
        quesData.content.viewType = data.content.viewType
        quesData.quesid = data.question_id
        quesData.examid = data.exam_id
        quesData.subjectid = data.subject_id
        quesData.name = data.question_name
        quesData.type = data.question_type
        const areastr = JSON.stringify(data.question_area)
        quesData.area = JSON.parse(areastr)
        quesData.content.mark_mode = data.content.mark_mode
        quesData.content.full_score = data.content.full_score
        quesData.content.score_interval = data.content.score_interval
        quesData.content.limit_time = data.content.limit_time
        quesData.content.marktype = data.content.marktype
        quesData.content.omrfigure = data.content.omrfigure
        quesData.content.uncoverage = data.content.uncoverage
        quesData.content.uncoverage2 = data.content.uncoverage2
        quesData.content.coverage2 = data.content.coverage2
        if (typeof data.content.valid_scores !== "undefined") {
          this.quesData.validScoreStr = data.content.valid_scores
        }
        if (typeof data.content.shortcuts !== "undefined")
          quesData.content.shortcuts = data.content.shortcuts
        if (typeof data.content.score_diff !== "undefined")
          quesData.content.score_diff = data.content.score_diff
        if (typeof data.content.remark_flag !== "undefined")
          quesData.content.remark_flag = data.content.remark_flag
        if (typeof data.content.remark_interval !== "undefined")
          quesData.content.remark_interval = data.content.remark_interval
        if (typeof data.content.choose_flag !== "undefined")
          quesData.content.choose_flag = data.content.choose_flag
        if (typeof data.content.coverage !== "undefined")
          quesData.content.coverage = data.content.coverage
        if (typeof data.content.depth !== "undefined")
          quesData.content.depth = data.content.depth
        if (this.$refs["quesForm"])
          this.$refs["quesForm"].clearValidate()
        this.activeQuesIdx = this.questionList.indexOf(data)
        this.subDrawerVisi = false
        this.quesDrawerVisi = true
      } else {  //编辑子题
        this.subDrawerVisi = true
        const subData = this.subData
        subData.viewType = data.viewType
        subData.subid = data.subquestion_id
        subData.quesid = data.question_id
        subData.examid = data.exam_id
        subData.subjectid = data.subject_id
        subData.num = data.subquestion_num
        subData.name = data.subquestion_name
        const areastr = JSON.stringify(data.subquestion_area)
        subData.area = JSON.parse(areastr)
        if (data.content != null) {
          subData.content.full_score = data.content.full_score
          subData.content.list = data.content.list
          if (subData.content.list && subData.content.list.length != 0) {
            // 默认选中第一个
            this.subQuestionContentListSelect = 0
          }

          if (typeof data.content.points !== "undefined")
            subData.content.points = data.content.points
          if (typeof data.content.is_multi !== "undefined")
            subData.content.is_multi = data.content.is_multi
        } else {
          subData.content.full_score = null
          subData.content.points = []
          subData.content.is_multi = false
          subData.content.list = []
        }
        if (this.$refs["subForm"])
          this.$refs["subForm"].clearValidate()
        const parent = this.$refs["quesTree"].getNode(data).parent.data
        subData.type = parent.question_type
        this.activeQuesIdx = this.questionList.indexOf(parent)
        this.activeSubquesIdx = parent.sub_question.indexOf(data)
        this.quesDrawerVisi = false
      }
    },
    getValidAreas(areas) {
      const varea = []
      areas.forEach(a => {
        const range = this.getRangeByPaperID(a.p)
        varea.push({x: a.x, y: a.y + range.thrange1, w: a.w, h: a.h, p: a.p})
      })
      return varea
    },
    //新增题组区域
    toQuestionAreaAdd() {
      this.optMode = "ques-area-add"
    },
    //保存题组
    toQuestionSave() {
      this.$refs.quesForm.validate(valid => {
        if (valid) {
          this.axios.post("/api/exam/mgeexamsubjectpapers/savesubjectquestion", this.quesData)
              .then((response) => {
                if (response.data.success) {
                  this.lastQuestionParams = this.quesData
                  this.$refs.quesDrawer.handleClose()
                  this.loadSubjectQuestion(true)
                  this.loadSubjectProp()
                }
              })
        } else
          return valid
      })
    },
    //新增子题
    toSubquestionAdd(parent) {
      this.subData = {
        subid: null,
        viewType: parent.content.viewType,
        quesid: parent.question_id,
        examid: parent.exam_id,
        subjectid: parent.subject_id,
        type: parent.question_type,
        num: null,
        name: "",
        area: [],
        content: {
          full_score: null,
          points: [],
          is_multi: false,
          list: []
        }
      }
      this.subQuestionContentListSelect = null
      this.activeQuesIdx = this.questionList.indexOf(parent)
      this.subTitle = "新增子题"
      this.subDrawerVisi = true
    },
    //保存子题
    toSubquestionSave() {
      this.$refs.subForm.validate((valid, invalidFields) => {
        if (valid) {
          this.axios.post("/api/exam/mgeexamsubjectpapers/savesubjectsubquestion", this.subData)
              .then((response) => {
                if (response.data.success) {
                  this.$refs['subDrawer'].handleClose()
                  this.loadSubjectQuestion(true)
                }
              })

        } else {
          console.log(invalidFields)
          return valid
        }
      })
    },
    //新增子题区域
    toSubquestionAreaAdd() {
      if (this.optMode === "sub-area-add") {
        this.validAreas = []
        this.optMode = ""
      } else {
        this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        this.optMode = "sub-area-add"
      }
    },
    getValidAreasByObjectType(downpoint, type) {
      if (downpoint !== undefined) {
        if (type === "subquestion_area")
          this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        else
          this.validAreas = this.getValidAreas(this.subData.area)
        let parentArea = null
        this.validAreas.forEach(area => {
          if (parentArea === null)
            if (area.x < downpoint.x && downpoint.x < area.x + area.w && area.y < downpoint.y && downpoint.y < area.y + area.h) {
              parentArea = {
                left: area.x,
                top: area.y,
                width: area.w,
                height: area.h,
                p: area.p
              }
            }
        })
        return parentArea
      } else {
        if (this.optMode === "sub-area-add") {
          this.validAreas = this.getValidAreas(this.questionList[this.activeQuesIdx].question_area)
        }
        if (this.optMode === "sub-point-add") {
          this.validAreas = this.getValidAreas(this.subData.area)

        }
      }
    },
    // 计算子问题有效区域
    calculateSubquestionOrPointValidArea() {
      this.getValidAreasByObjectType()
      let valid = false
      let top = Math.min(this.downPoint.y, this.upPoint.y)
      let left = Math.min(this.downPoint.x, this.upPoint.x)
      let width = Math.abs(this.downPoint.x - this.upPoint.x)
      let height = Math.abs(this.downPoint.y - this.upPoint.y)
      let rect = {top: top, left: left, width: width, height: height}
      this.validAreas.forEach(area => {
        if (!valid)
          if (area.x < rect.left && rect.left < area.x + area.w && area.x < rect.left + rect.width && rect.left + rect.width < area.x + area.w
              && area.y < rect.top && rect.top < area.y + area.h && area.y < rect.top + rect.height && rect.top + rect.height < area.y + area.h
          ) {
            valid = true
          }
      })
      return valid
    },
    //移除子题指定区域
    removeSubquestionArea(data) {
      const idx = this.subData.area.indexOf(data)
      this.subData.area.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    //新增子题打分点
    toSubquestionPointAdd() {
      if (this.optMode === "sub-point-add") {
        this.validAreas = []
        this.optMode = ""
      } else {
        this.validAreas = this.getValidAreas(this.subData.area)
        this.optMode = "sub-point-add"
      }
    },
    //移除子图指定打分点
    removeSubquestionPoint(data) {
      const idx = this.subData.content.points.indexOf(data)
      this.subData.content.points.splice(idx, 1)
      this.removeFabricObjectByUid(data)
    },
    //模板图片删除
    toPaperDel(data) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/mgeexamsubjectpapers/removesubjectpaper", {
          examid: data.exam_id,
          paperid: data.paper_id
        })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "模板图片删除成功", type: "success"})
                this.loadSubjectPaper()
              }
            })

      })
    },
    questionDrop(dragging, drop, type) {
      if (typeof dragging.data.subquestion_name === "undefined") {
        this.axios.post("/api/exam/mgeexamsubjectpapers/changesubjectquestion", {
          examid: this.search.examid,
          srcid: dragging.data.question_id,
          tgtid: drop.data.question_id,
          type: type
        }).then((response) => {
          if (!response.data.success) {
            this.loadSubjectQuestion(true)
          }
        }).catch(() => {
          this.loadSubjectQuestion(true)
        })
      } else {
        this.axios.post("/api/exam/mgeexamsubjectpapers/changesubjectsubquestion", {
          examid: this.search.examid,
          srcid: dragging.data.subquestion_id,
          tgtid: drop.data.subquestion_id,
          type: type
        }).then((response) => {
          if (!response.data.success) {
            this.loadSubjectQuestion(true)
          }
        }).catch(() => {
          this.loadSubjectQuestion(true)
        })
      }
    },
    //是否允许题组拖拽
    questionAllowDrop(dragging, drop, type) {
      if (type === "inner")
        return false
      else
        return (typeof dragging.data.subquestion_id === "undefined" && typeof drop.data.subquestion_id === "undefined")
            || (typeof dragging.data.subquestion_id !== "undefined" && typeof drop.data.subquestion_id !== "undefined" && dragging.data.question_id === drop.data.question_id);
    },
    //是否允许区域拖拽
    areaAllowDrop(dragging, drop, type) {
      return type !== "inner"
    },
    areaDrop(dragging, drop, type) {
      if (type === "inner")
        return false
      else {
      }
      // this.drawEditQuestion()
    },
    //题组删除
    toQuestionDel(data) {
      if (typeof data.subquestion_name === "undefined") { //删除题组
        this.$confirm("确认删除题组?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/exam/mgeexamsubjectpapers/delsubjectquestion", {
            examid: data.exam_id,
            quesid: data.question_id
          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "题组删除成功", type: "success"})
                  this.loadSubjectQuestion(true)
                  this.loadSubjectProp()
                }
              })

        })
      } else {  //删除子题
        this.$confirm("确认删除子题?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.axios.post("/api/exam/mgeexamsubjectpapers/delsubjectsubquestion", {
            examid: data.exam_id,
            subid: data.subquestion_id
          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "子题删除成功", type: "success"})
                  this.loadSubjectQuestion(true)
                }
              })

        })
      }
    },
    //添加新快捷键
    addShortcut() {
      const ct = this.quesData.content
      if (typeof ct.shortcuts === "undefined")
        ct.shortcuts = []
      ct.shortcuts.push({key: "", score: ""})
    },
    //移除快捷键
    removeShortcut(idx) {
      const sc = this.quesData.content.shortcuts
      sc.splice(idx, 1)
    },
    //拖拽模板图片
    paperDrop(dragging, drop, type) {
      this.$confirm("确认调换?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/exam/mgeexamsubjectpapers/changesubjectpaper", {
          srcid: dragging.data.paper_id,
          tgtid: drop.data.paper_id,
          type: type,
          examid: this.search.examid
        }).then((response) => {
          if (response.data.success) {
            this.loadSubjectPaper()
          }

        }).catch(() => {
          this.loadSubjectPaper()
        })
      })
    },
    //激活批量添加答题卡客观子题
    toOrmAreaBatchAdd() {
      this.subDialogMode = "orm"
      this.subDialogTitle = "识别ORM填涂区域"
      if (this.propData.codearea.length === 0) {
        this.$message.warning("请先添加ORM区域")
      } else {
        this.subDialogVisi = true
        this.getOptionImages()
      }
    },
    //激活批量添加答题卡客观子题
    toSubquestionBatchAdd() {
      this.subDialogMode = "opt"
      this.subDialogTitle = "批量添加客观子题"
      if (this.quesData.name===""){
        this.$message.warning("题组名称不能为空！")
      }
      if (this.quesData.area.length === 0) {
        this.$message.warning("请先添加题组区域")
      } else {
        this.subDialogVisi = true
        this.getOptionImages()
      }
    }
    ,
    getOptionImages() {
      let areas = []
      let examid = "", subjectid = ""
      if (this.subDialogMode === "orm") {
        areas = this.propData.codearea
        examid = this.propData.examid
        subjectid = this.propData.subjectid
      } else {
        areas = this.quesData.area
        examid = this.quesData.examid
        subjectid = this.quesData.subjectid
      }
      this.colorDepth = this.quesData.content.depth
      this.axios.post("/api/exam/mgeexamsubjectpapers/getoptionimages", {
        examid: examid,
        subjectid: subjectid,
        areas: areas
      }).then((response) => {
        if (response.data.success) {
          let papers = response.data.result
          papers.forEach(paper => {
            let img = new Image()
            img.src = paper.img
            paper.paper_image = img
          })
          this.areaImageList = papers

          this.$nextTick(() => {
            this.optInit()
            this.subDialogData = {
              direction: "2",
              startnum: 1,
              optMinBlank: 1
            }
            //拼接题组区域图片
            let tw = 0, th = 0;
            areas.forEach(area => {
              if (area.w > tw)
                tw = area.w
              th += area.h
            })
            optcvs.setWidth(tw)
            optcvs.setHeight(th)
            let ch = 0
            const arealist = []
            this.areaImageList.forEach(area => {
              let imgInstance = new fabric.Image(area.paper_image, {
                top: ch,
                left: 0,
                width: parseInt(area.w),
                height: parseInt(area.h),
                stroke: this.multiOptColor,
                strokeWidth: 2,
                hoverCursor: "auto",
                selectable: false,
                evented: false
              })
              optcvs.add(imgInstance)
              imgInstance.sendToBack()
              arealist.push({x: 0, y: ch, w: area.w, h: area.h})
              ch += parseInt(area.h)
            })
            optcvs.renderAll()
            this.calcZoomOpt()
            this.refindOptions()
          })

        }
      })
    },
    //获取题组区域内选项列表
    refindOptions() {
      this.setModel("auto")
      let areas = []
      let examid = "", subjectid = ""
      if (this.subDialogMode === "orm") {
        areas = this.propData.codearea
        examid = this.propData.examid
        subjectid = this.propData.subjectid
      } else {
        areas = this.quesData.area
        examid = this.quesData.examid
        subjectid = this.quesData.subjectid
      }

      this.objectiveOptionList = []
      this.axios.post("/api/exam/mgeexamsubjectpapers/getoptionpos", {
        examid: examid,
        subjectid: subjectid,
        areas: areas,
        minblank: this.subDialogData.optMinBlank,
        depth: this.colorDepth
      })
          .then((response) => {
            if (response.data.success) {
              const poslist = response.data.result
              const optlist = []
              let curheight = 0
              areas.forEach((area, aidx) => {
                const areapos = poslist[aidx]
                if (areapos != null) {
                  areapos.forEach(pos => {
                    const opt = {
                      x: pos.x,
                      y: pos.y + curheight,
                      w: pos.w,
                      h: pos.h,
                      p: area.p,
                      ox: pos.x + area.x,
                      oy: pos.y + area.y,
                    }
                    optlist.push(opt)
                  })
                }
                curheight += area.h
              })
              this.objectiveOptionList = optlist
              this.renderObjectiveOption()
            }
          })
    }
    ,
    renderObjectiveOption() {

      this.optcvsRemoveObjectByType("opt_subquestion_area")
      this.optcvsRemoveObjectByType("subquestion_area_mark")
      // if (this.subDialogMode!=="orm")
      this.objectiveOptionList.forEach(area => {
        let color = this.optColor
        if (area.f === true)
          color = this.firstOptColor
        if (area.m === true)
          color = this.multiOptColor
        this.drawOptFabricGroup2(area, "opt_subquestion_area", "subquestion_area_mark", color, "", -1)
        if (area.sq !== undefined)
          this.drawSubNum(area, "subquestion_area_mark")


      })
      optcvs.getObjects().forEach(object => {
        object.selectable = false
        object.hoverCursor = "default"
      })
    },

    renderPersonObjectiveOption() {
        this.optcvsRemoveObjectByType("opt_subquestion_area")
        this.optcvsRemoveObjectByType("subquestion_area_mark")
        this.objectiveOptionList.forEach(area => {
          let color = this.optColor
          if (area.f === true)
            color = this.firstOptColor
          if (area.m === true)
            color = this.multiOptColor
          this.drawOptFabricGroup2(area, "opt_subquestion_area", "subquestion_area_mark", color, "", -1)
          if (area.sq !== undefined)
            this.drawSubNum(area, "subquestion_area_mark")
        })


      optcvs.getObjects().forEach(object => {
        object.selectable = false
        object.hoverCursor = "default"
      })
    },


    toOptionColSet() {
      this.isOptionGenerate = false
      if (this.optRecognizeMode === "col")
        this.optRecognizeMode = "person"
      else
        this.optRecognizeMode = "col"
    },

    toOptionRowSet() {
      if (this.colPointList.length === 0) {
        this.$message.warning("请先设置列选项")
      } else {
        this.isOptionGenerate = false

        if (this.optRecognizeMode === "row")
          this.optRecognizeMode = "person"
        else
          this.optRecognizeMode = "row"

      }

    },
    //切换到移除选项列模式
    toOptionColRemove() {
      if (this.optObjectiveMode !== "col-remove")
        this.optObjectiveMode = "col-remove"
      else
        this.optObjectiveMode = ""
    },
    //切换到移除选项行模式
    toOptionRowRemove() {
      if (this.optObjectiveMode !== "row-remove")
        this.optObjectiveMode = "row-remove"
      else
        this.optObjectiveMode = ""
    },
    //切换到设置首选项模式
    toFirstOptSwitch() {

      if (this.optRecognizeMode !== "auto") {
        if (!this.isOptionGenerate) {
          this.$message.warning("人工设置模式下请先生成选项后再进行该操作")
          return
        }

      }

      if (this.optObjectiveMode !== "first-opt-switch")
        this.optObjectiveMode = "first-opt-switch"
      else
        this.optObjectiveMode = ""
    },

    toResetColAndRow() {
      this.isOptionGenerate = false
      this.optRecognizeMode = "person"
      this.rowPointList = []
      this.colPointList = []
      this.objectiveOptionList = []
      this.renderPersonObjectiveOption()
    },
    toGenerateOptionArea() {
      this.optRecognizeMode = "person"
      this.isOptionGenerate = true
      this.objectiveOptionList = this.generateGridFromRowCol(this.rowPointList, this.colPointList)
      this.renderPersonObjectiveOption()
    },
    generateGridFromRowCol(rowPointList, colPointList) {
      const grid = [];
      const area = (this.subDialogMode === "orm") ? this.propData.codearea[0] : this.quesData.area[0]
      rowPointList.forEach((rowPoint) => {
        colPointList.forEach((colPoint) => {
          // 计算每个矩形的中心点，并生成矩形
          const rect = {
            x: Math.round(rowPoint.x + rowPoint.w / 2 - this.rectWidth / 2),
            y: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2),
            w: Math.round(this.rectWidth),
            h: Math.round(this.rectHeight),
            uid: `grid-${rowPoint.uid}-${colPoint.uid}`,
            p: area.p,
            ox: Math.round(rowPoint.x + rowPoint.w / 2 - this.rectWidth / 2 + area.x),
            oy: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2 + area.y)
          };



          // 将生成的矩形添加到网格中
          grid.push(rect);
        });
      });
      //列的第一个点也是行的第一个点，需要再这里加上
      colPointList.forEach((colPoint) => {
        // 计算每个矩形的中心点，并生成矩形
        const rect = {
          x: Math.round(colPoint.x + colPoint.w / 2 - this.rectWidth / 2),
          y: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2),
          w: Math.round(this.rectWidth),
          h: Math.round(this.rectHeight),
          uid: `grid-${colPoint.uid}-${colPoint.uid}`,
          p: area.p,
          ox: Math.round(colPoint.x + colPoint.w / 2 - this.rectWidth / 2 + area.x),
          oy: Math.round(colPoint.y + colPoint.h / 2 - this.rectHeight / 2 + area.y)
        };

        // 将生成的矩形添加到网格中
        grid.push(rect);
      });
      return grid;
    }
    ,
    //切换到设置多选题模式
    toSetMultiOpt() {

      if (this.optRecognizeMode !== "auto") {
        if (!this.isOptionGenerate) {
          this.$message.warning("人工设置模式下请先生成选项后再进行该操作")
          return
        }

      }

      if (this.optObjectiveMode !== "multi-opt-switch")
        this.optObjectiveMode = "multi-opt-switch"
      else
        this.optObjectiveMode = ""
    },
    //生成子题号
    generateSubNum() {
      let isFirstSet = false
      const firstList = []
      const epsilonH =  this.objectiveOptionList[0].h/2; // 定义一个容差值，例如 5 个像素
      const epsilonW =  this.objectiveOptionList[0].w/2; // 定义一个容差值，例如 5 个像素

      this.objectiveOptionList.sort((a, b) => {
        if (Math.abs(a.y - b.y) <= epsilonH) {
          return a.x - b.x; // 如果 y 坐标差异在容差范围内，则按 x 排序
        }
        return a.y - b.y; // 否则按 y 排序
      });


      this.objectiveOptionList.forEach(option => {
        if (typeof option.f !== "undefined") {
          isFirstSet = true
          firstList.push(option)
        }
        if (typeof option.sq !== "undefined") {
          delete option.sq
        }
      })
      if (isFirstSet) {
        //首选项排序
        if (this.subDialogData.direction === "1") { //横向
          firstList.sort((a, b) => {
              if ( Math.abs(a.y - b.y)<epsilonH )
                return a.x - b.x
              else
                return a.y - b.y

          })
        } else {
          firstList.sort((a, b) => {  //纵向
              if (Math.abs(a.x - b.x)<epsilonW)
                return a.y - b.y
              else
                return a.x - b.x

          })
        }
        let n = this.subDialogData.startnum
        firstList.forEach(opt => {
          opt.sq = n
          n++
        })
        this.renderObjectiveOption()
      } else
        this.$message.warning("请先设置首选项")
    },
    //关闭添加子题对话框
    closeBatchOptionDialog() {
      this.subDialogVisi = false
    },
    //客观题画布鼠标抬起事件
    objectiveMouseUp(ev) {
      const mpos = optcvs.getPointer(ev.e)
      const aidx = this.findPosAreaIndex(mpos)
      if (this.optObjectiveMode === "col-remove") {
        this.objectiveOptionList = this.objectiveOptionList.filter(opt => {
          const idx = this.findPosAreaIndex(opt)
          return opt.x > mpos.x || opt.x + opt.w < mpos.x || aidx !== idx
        })
        this.renderObjectiveOption()
      } else if (this.optObjectiveMode === "row-remove") {
        this.objectiveOptionList = this.objectiveOptionList.filter(opt => {
          const idx = this.findPosAreaIndex(opt)
          return opt.y > mpos.y || opt.y + opt.h < mpos.y || aidx !== idx
        })
        this.renderObjectiveOption()
      } else if (this.optObjectiveMode === "first-opt-switch") {
        const opt = this.findPosOption(mpos)
        if (opt != null) {
          const first = opt.f
          if (typeof first === "undefined")
            opt["f"] = true
          else
            delete opt.f
          this.objectiveOptionList.forEach(option => {
            const idx = this.findPosAreaIndex(option)
            if (mpos.x >= option.x && mpos.x <= option.x + option.w && opt.y + opt.h < option.y && aidx === idx) {
              if (typeof option.f === "undefined") {
                if (typeof first === "undefined")
                  option["f"] = true
              } else if (typeof first !== "undefined") {
                delete option.f
              }
            }
          })
          this.renderObjectiveOption()
        }
      } else if (this.optObjectiveMode === "multi-opt-switch") {
        const opt = this.findPosOption(mpos)
        if (opt != null) {
          if (typeof opt.f !== "undefined") {
            const ismulti = opt.m
            if (typeof ismulti === "undefined")
              opt["m"] = true
            else
              delete opt.m
            this.renderObjectiveOption()
          } else {
            this.$message.warning("请点击首选项")
          }
        }
      }
    }
    ,
    //查找指定位置在客观题区域列表中的索引
    findPosAreaIndex(mpos) {
      let areaIdx = -1
      this.objectiveAreaList.forEach((area, idx) => {
        if (mpos.y >= area.y && mpos.y < area.y + area.h)
          areaIdx = idx
      })
      return areaIdx
    },
    //查找选中客观题选项
    findPosOption(mpos) {
      let find = null
      this.objectiveOptionList.forEach(option => {
        if (mpos.x >= option.x && mpos.x <= option.x + option.w && mpos.y >= option.y && mpos.y <= option.y + option.h)
          find = option
      })
      return find
    },   //批量保存客观子题
    batchAddSubquestions() {
      const params = this.quesData
      const epsilonH =  this.objectiveOptionList[0].h/2; // 定义一个容差值，例如 5 个像素
      const epsilonW =  this.objectiveOptionList[0].w/2; // 定义一个容差值，例如 5 个像素
      this.objectiveOptionList.sort((a, b) => {
        if (Math.abs(a.y - b.y) <= epsilonH) {
          return a.x - b.x; // 如果 y 坐标差异在容差范围内，则按 x 排序
        }
        return a.y - b.y; // 否则按 y 排序
      });

      let options
      const subquesList = []
      this.objectiveOptionList.forEach(opt => {
        if (typeof opt.f !== "undefined") {
          const subques = {sq: opt.sq, areas: []}
          options = subques.areas
          subques.content = {full_score: 0, is_multi: typeof opt.m !== "undefined"}
          subquesList.push(subques)
        }
        if (options instanceof Array)
          options.push({x: opt.ox, y: opt.oy, w: opt.w, h: opt.h, p: opt.p})
      })
      params.subques = subquesList
      if (this.subDialogMode === "orm") {
        const ormAreaList = []
        this.objectiveOptionList.forEach(opt => {
          ormAreaList.push({x: opt.ox, y: opt.oy, w: opt.w, h: opt.h, p: opt.p, uid: this.uuid(), object_type: "orm"})
        })
        this.removeFabricObjectByType(["orm_subquestion_area","orm_subquestion_area_mark"])
        this.propData.ormarea = ormAreaList
        this.subDialogVisi = false

      } else {
        this.axios.post("/api/exam/mgeexamsubjectpapers/batchaddsubquestion", params)
            .then((response) => {
              if (response.data.success) {
                this.subDialogVisi = false
                if (this.quesDrawerVisi === true)
                  this.quesDrawerVisi = false
                this.loadSubjectQuestion(true)
              }
            })
      }

    },
    deleteOrmArea() {
      this.propData.ormarea.forEach(area => {
        this.removeFabricObjectByUid(area)
      })
      this.propData.ormarea = []
    },
    setModel(model) {
      this.optRecognizeMode = model
      this.objectiveOptionList = []
      this.rowPointList = []
      this.colPointList = []
      this.renderPersonObjectiveOption()
    }
  },

}
</script>

<style scoped>
.v-center_dialog .el-dialog {
  margin-top: 15vh;
  margin-bottom: 15vh;
  height: calc(100% - 30vh);
}

.v-center_dialog .el-dialog .el-dialog__body {
  height: calc(100% - 54px);
}


.zoom-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: fixed;
  width: 20px;
  z-index: 1;
}

.zoom-class {
  width: 20px;
  height: 2em;
}

.exam-title {
  font-size: 20px;
  color: #284f95;
}

.exam-subject {
  width: 300px;
  text-align: right;
}

.paper-row {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.area-select {
  color: #2e62cd;
}


</style>
