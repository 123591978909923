

<template>
  <div class="container">
    <img src="../../assets/img-01.png" class="image" alt="" />

    <el-form :model="loginData" :rules="rules" ref="loginForm" label-width="100px" @submit.prevent>
          <h4 class="title">你好，欢迎使用沐昀网上阅卷平台</h4>
          <div class="input-field">
            <i class="fas fa-user"></i>
            <input v-model="loginData.ua" type="text" placeholder="手机号或账号" />
          </div>
          <div class="input-field">
            <i class="fas fa-lock"></i>
            <input v-model="loginData.up" type="password" placeholder="密码" />
          </div>
          <el-button class="btn solid" @click="doLogin" value="登录" >登录</el-button>
          <el-link>忘记密码？</el-link>
        </el-form>
  </div>
</template>
<script>
export default {
  name: "M_LOGIN",
  data: function() {
    return {
      isvalid: false,
      loginData: {ua: "", up: ""},
      rules: {
        ua: [{required: true, message: "请输入用户名", trigger: "blur"}],
        up: [{required: true, message: "请输入密码", trigger: "blur"}]
      }
    }
  },
  mounted() {

  },
  methods: {
    doLogin () {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.axios.post("/api/login/dologin", this.loginData)
              .then((response) => {
                if (response.data.success) {
                  sessionStorage.setItem("is-login", "1")
                  sessionStorage.removeItem("redirect")
                  this.$router.replace({name:"cardMenu"})
                }
              })
        }
        else
          return false
      })
    }
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image {
  transition: 1.1s 0.4s ease-in-out;
  width: 25%;
  position: absolute;
  top:0;
  left: 0;
}
.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
}
.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

form {
  display: flex;
  /*Distributes Flex-items along the Cross Axis */
  align-items: center;
  /*Distributes Flex-items along the Main Axis */
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: 0.2s 0.7s ease-in-out;
}
.title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;

  height: 55px;
  background-color: #f0f0f0;
  margin: 10px 0;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.btn {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 48px;
  cursor: pointer;
  background-color: var(--el-menu-hover-text-color);
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  font-size: 20px;
  transition: all 0.5s;
}



</style>